import { FormatAlignCenter, FormatAlignLeft } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import FillOptions from 'components/forms/fillOptions';
import ImageUploader from 'components/imageUploader/imageUploader';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import TabPanel from 'components/tabPanel/tabPanel';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

export const DisplayTabPanel = ({
                                  backgroundColor,
                                  cardGroupType,
                                  sx,
                                  index,
                                  form,
                                  onBackgroundImageFileUploaded,
                                  onChange: onFormChange,
                                  onIconUploaded,
                                  setUseColorFillAsBackground,
                                  size,
                                  tabValue,
                                  textColor,
                                  useColorFillAsBackground,
                                }) => (
  <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
    {(cardGroupType === 'one' || cardGroupType === 'temporary') && (
      <>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Typography sx={sx.displayHeading} variant="subtitle1">
              Template Options
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography sx={sx.displaySubheading} variant="body1">
              Text Alignment
            </Typography>
          </Grid>
          <Grid item xs sx={sx.field}>
            <Field name="textAlignment">
              {({ input: { onChange, value } }) => (
                <>
                  <FormatAlignLeft
                    name="textAlignment"
                    onClick={() => {
                      onChange('left');
                      onFormChange();
                    }}
                    sx={{
                      ...sx.alignmentIcon,
                      ...value === 'left' && sx.selectedAlignmentIcon,
                      borderBottomLeftRadius: '4px',
                      borderTopLeftRadius: '4px',
                      borderRight: 'none',
                    }}
                  />
                  <FormatAlignCenter
                    name="textAlignment"
                    onClick={() => {
                      onChange('center');
                      onFormChange();
                    }}
                    sx={{
                      ...sx.alignmentIcon,
                      ...value === 'center' && sx.selectedAlignmentIcon,
                      borderBottomRightRadius: '4px',
                      borderTopRightRadius: '4px',
                      borderLeft: 'none',
                    }}
                  />
                </>
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Typography sx={sx.displaySubheading} variant="body1">
              Minimum Card Height
            </Typography>
          </Grid>
          <Grid item xs sx={sx.radioContainer}>
            <Field name="measurementHeight">
              {({ input: { value, onChange } }) => (
                <GozioRadioGroup
                  groupName="measurementHeight"
                  onChange={onChange}
                  options={[
                    { label: 'Minimum', value: 'minimum' },
                    { label: 'Short', value: 'short' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Tall', value: 'tall' },
                  ]}
                  row={true}
                  value={value}
                />
              )}
            </Field>
          </Grid>
        </Box>
      </>
    )}
    <Box sx={sx.fieldSection}>
      <Grid item xs>
        <FillOptions
          textColor={textColor}
          backgroundColor={backgroundColor}
          onBackgroundColorUpdated={() => {
            form.change('backgroundImageUrl', null);
            onFormChange();
            setUseColorFillAsBackground(true);
          }}
          onImageUpdated={(imageData) => {
            setUseColorFillAsBackground(false);
            onBackgroundImageFileUploaded(imageData);
            onFormChange();
          }}
          onRadioChange={(newValue) => {
            if (newValue === 'color') {
              setUseColorFillAsBackground(true);
              form.change('backgroundImageUrl', null);
              onFormChange();
            } else {
              setUseColorFillAsBackground(false);
              onFormChange();
            }
          }}
          initialOption={!useColorFillAsBackground ? 'image' : 'color'}
        />
      </Grid>
    </Box>
    {size !== 'extraSmall' && (
      <Box sx={sx.fieldSection}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            Card Icon
          </Typography>
        </Grid>
        <Grid item xs>
          <Field name="iconUrl">
            {({ input: { onChange, value } }) => (
              <ImageUploader
                title={
                  <span>
                    <Asterisk /> Supporting Icon
                  </span>
                }
                display="inline"
                image={value ? { url: value } : null}
                isIcon={true}
                fileInfo="Supported files includes jpeg and png. File size should be no larger than 50KB. Image dimensions must be at least 120px by 120px."
                onImageUpdated={({ file, url }) => {
                  onIconUploaded({ file, iconUrl: url });
                  onChange(url);
                }}
                maxFileSize={50000}
                minHeight={120}
                minWidth={120}
                previewWidth={64}
                previewHeight={64}
                allowDelete={false}
                showImage={true}
              />
            )}
          </Field>
        </Grid>
      </Box>
    )}
  </TabPanel>
);

DisplayTabPanel.propTypes = {
  sx: PropTypes.object,
  backgroundColor: PropTypes.string,
  cardGroupType: PropTypes.string.isRequired,
  index: PropTypes.number,
  form: PropTypes.object.isRequired,
  onBackgroundImageFileUploaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onIconUploaded: PropTypes.func.isRequired,
  setUseColorFillAsBackground: PropTypes.func.isRequired,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  tabValue: PropTypes.number,
  textColor: PropTypes.string,
  useColorFillAsBackground: PropTypes.bool,
};

DisplayTabPanel.defaultProps = {
  sx: {},
  index: 0,
  tabValue: 0,
  useColorFillAsBackground: false,
};
