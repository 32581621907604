import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OpenPlatformCard from 'components/dashboard/cardComponent/openPlatformCard';
import {
  dndHover,
  isCardIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dragging: {
    paddingBottom: '0 !important',
  },
});

const OpenPlatformCardLayout = ({
  id,
  index,
  card1,
  preview,
  hovered,
  moveCard,
  onCardClick,
  onDrag,
  onHover,
  canDragAndDrop,
}) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        card1,
      },
      cardType: OpenPlatformCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'Open Platform' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...preview && styles.preview,
          ...isDragging && styles.dragging,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          <OpenPlatformCard
            cardName={card1.cardName}
            incompleteForPackaging={isCardIncompleteForPackaging(card1)}
            clickHandler={() => onCardClick({ cardGroupId: id, key: 'card1', ...card1 })
            }
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

OpenPlatformCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  card1: PropTypes.shape({
    cardName: PropTypes.string,
    clickHandler: PropTypes.func,
    incompleteForPackaging: PropTypes.bool,
  }),
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

OpenPlatformCardLayout.defaultProps = {
  card1: {
    cardName: 'Card Title',
    clickHandler: () => {},
    incompleteForPackaging: false,
  },
  preview: false,
  hovered: false,
  moveCard: () => {},
  onCardClick: () => {},
  onDrag: () => {},
  canDragAndDrop: true,
};

export default OpenPlatformCardLayout;
