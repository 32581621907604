import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, dndHover } from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Card from './cardComponent/card';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dragging: {
    paddingBottom: '0 !important',
  },
});

const HeaderUnderlayLayout = ({
                                id,
                                index,
                                underlayHeaderColor,
                                underlayTextColor,
                                numberOfCards,
                                preview,
                                disabled,
                                selected,
                                hovered,
                                moveCard,
                                onBackgroundOptionClick,
                                onDrag,
                                onHover,
                                canDragAndDrop,
                              }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_OPTION,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        underlayHeaderColor,
        underlayTextColor,
        numberOfCards,
      },
      cardType: HeaderUnderlayLayout,
      index,
      onDrag,
      preview,
      type: ItemTypes.DASHBOARD_OPTION,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'Header Underlay' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...preview && styles.preview,
          ...isDragging && styles.dragging,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          <Card
            size="extraSmall"
            title={'Header Underlay'}
            backgroundColor={underlayHeaderColor || DEFAULT_BACKGROUND_COLOR}
            textColor={underlayTextColor || DEFAULT_TEXT_COLOR}
            clickHandler={onBackgroundOptionClick}
            incompleteForPackaging={false}
            disabled={disabled}
            selected={selected}
            standalone={true}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

HeaderUnderlayLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  underlayHeaderColor: PropTypes.string,
  underlayTextColor: PropTypes.string,
  numberOfCards: PropTypes.number,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onBackgroundOptionClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

HeaderUnderlayLayout.defaultProps = {
  underlayHeaderColor: DEFAULT_BACKGROUND_COLOR,
  underlayTextColor: DEFAULT_TEXT_COLOR,
  numberOfCards: 0,
  preview: false,
  disabled: false,
  selected: false,
  hovered: false,
  moveCard: () => {
  },
  onDrag: () => {
  },
  onHover: () => {
  },
  onBackgroundOptionClick: () => {
  },
  canDragAndDrop: true,
};

export default HeaderUnderlayLayout;
