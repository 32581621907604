import { PLACEHOLDER_TITLE_MAP } from 'components/dashboard/cardLayouts/cardhelper';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { isValidJSON } from 'helpers/string-util';
import * as Yup from 'yup';

export const buildOpenPlatformSchema = (languages) => Yup.object().shape({
    cardGroupType: Yup.string(),
    cardName: Yup.string()
      .max(40, 'Title must be 40 characters or less')
      .required('Title is required'),
    moduleId: Yup.string().nullable().matches(/^[A-Za-z0-9\-_]+$/, {
      message: 'Use alphanumeric characters only.',
    }),
    moduleData: Yup.string().nullable(),
    visibilityRuleCustom: Yup.string().nullable().when('visibilityRuleSelect', {
      is: 'custom',
      then: (schema) => schema
        .test('isJSON', 'Invalid JSON input', isValidJSON)
        .required('Visibility Rule is required'),
    }),
  });

export const buildOpenPlatformInitialValues = (values) => {
  const map = structuredClone(values);
  if (values.visibilityRuleCustom !== null) {
    map.visibilityRuleSelect = 'custom';
    map.visibilityRuleCustom = values.visibilityRuleCustom;
  } else if (values.visibilityRule !== null) {
    map.visibilityRuleSelect = values.visibilityRule.id;
    map.visibilityRuleCustom = null;
  } else {
    map.visibilityRuleSelect = 'allUsers';
    map.visibilityRuleCustom = null;
  }

  const {
    cardGroupId,
    cardGroupType,
    cardName,
    module,
    visibilityRule,
    visibilityRuleCustom,
    visibilityRuleSelect,
  } = map;

  return {
    cardGroupId,
    cardGroupType,
    cardName: cardName ?? PLACEHOLDER_TITLE_MAP[LANGUAGE_CODES.ENGLISH].label,
    moduleId: module?.moduleId ?? null,
    moduleData: module?.data ?? null,
    visibilityRule: visibilityRule ?? null,
    visibilityRuleCustom: visibilityRuleCustom ?? null,
    visibilityRuleSelect: visibilityRuleSelect ?? null,
  };
};

export const mapOpenPlatformEditCardToPreview = ({ selectedCard, values }) => ({
  cardGroupId: selectedCard.cardGroupId,
  cardGroupType: selectedCard.cardGroupType,
  cardId: selectedCard.cardId,
  validation: selectedCard.validation,
  card1: {
    cardName: values.cardName ?? null,
  },
});

export const submitOpenPlatformCard = async ({
  cardId,
  updateCard,
  values,
}) => {
  const variables = {
    id: cardId,
    input: {
      cardName: values.cardName,
      module: {
        moduleId: values.moduleId ?? null,
        data: values.moduleData ?? null,
      },
    },
  };

  if (values.visibilityRuleSelect === 'custom') {
    variables.input.visibilityRule = null;
    variables.input.visibilityRuleCustom = values.visibilityRuleCustom;
  } else if (values.visibilityRuleSelect === 'allUsers') {
    variables.input.visibilityRuleCustom = null;
    variables.input.visibilityRule = null;
  } else {
    variables.input.visibilityRuleCustom = null;
    variables.input.visibilityRule = values.visibilityRuleSelect;
  }

  await updateCard({ variables });
};
