import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { A2C_DISPLAY_TYPES, CARD_GROUP_TYPES } from 'components/dashboard/cardLayouts/cardhelper';
import EmptySection from 'components/emptySection/emptySection';
import LinkDestination from 'components/forms/linkDestination';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import GozioListItem from 'components/listItem/gozioListItem';
import TabPanel from 'components/tabPanel/tabPanel';
import { getFormLabel } from 'helpers/form-util';
import { buildLabelByLang, getLabelByLang, LANGUAGE_CODES } from 'helpers/lang-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import ColorPalette from 'pages/gozio_colors';
import { renderFields, SUPPORTED_LINK_TYPES } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import { QuicklinkModal } from 'pages/mobileSettings/dashboard/containers/quicklinkModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, useField } from 'react-final-form';

export const AccessToCareImageDetailsTabPanel = ({
                                                   sx,
                                                   cardGroupType,
                                                   formValues,
                                                   form,
                                                   index,
                                                   languages = [],
                                                   onChange: onFormChange,
                                                   onIconUploaded,
                                                   tabValue,
                                                 }) => {
  useField('qlQuickLinks');
  const quickLinksLength = formValues?.qlQuickLinks?.length || 0;
  const quickLinksAddPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(useCheckRole());
  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const [uploadedIconData, setUploadedIconData] = useState(null);
  const [selectedQuickLink, setSelectedQuickLink] = useState(null);
  const handleShowQuickLinkModal = (link = null) => {
    setUploadedIconData(null);
    setSelectedQuickLink(link);
    setShowQuickLinkModal(true);
  };
  const handleEditQuickLinkClicked = (id) => {
    handleShowQuickLinkModal({
      ...formValues.qlQuickLinks.find((link) => link.id === id),
    });
  };
  const handleDeleteQuickLinkClicked = (id) => {
    let updatedQuickLinksData = [...formValues.qlQuickLinks];
    updatedQuickLinksData = updatedQuickLinksData
      .filter((link) => link.id !== id)
      .map(({ index, ...rest }, idx) => ({
        index: idx,
        ...rest,
      }));
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
  };

  const handleQuicklinkAddedOrEdited = (data) => {
    const updatedQuickLinksData = formValues?.qlQuickLinks
      ? [...formValues.qlQuickLinks]
      : [];
    const updatedData = {
      categories: data.categories,
      listDest: data.listDest,
      name: buildLabelByLang(data, 'nameEn', 'nameEs'),
      iconFile: uploadedIconData?.file,
      iconUrl: uploadedIconData?.iconUrl || data.iconUrl,
      type: data.linkType,
      data: {
        id:
          data.linkType === 'category'
            ? data.categories?.map((c) => c.id)
            : data.listDest,
      },
      validation: data.validation,
    };
    if (selectedQuickLink) {
      updatedData.id = selectedQuickLink.id;
      if (selectedQuickLink.iconFile) {
        updatedData.iconFile = selectedQuickLink.iconFile;
      }
      const index = updatedQuickLinksData.findIndex(
        (link) => link.id === selectedQuickLink.id,
      );
      updatedData.index = index;
      updatedQuickLinksData[index] = updatedData;
    } else {
      updatedData.index = updatedQuickLinksData.length;
      updatedQuickLinksData.push(updatedData);
    }
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
    setShowQuickLinkModal(false);
  };

  const handleUpdateOptions = (updatedListOptions) => {
    const sortedQuickLinks = [];
    updatedListOptions.forEach((opt, index) => {
      const quickLink = formValues.qlQuickLinks.find(
        (link) => link.id === opt.id,
      );
      sortedQuickLinks.push({
        ...quickLink,
        order: index,
      });
    });
    form.change('qlQuickLinks', sortedQuickLinks);
    onFormChange();
  };

  const buildListOptions = () => {
    let options = [];
    if (quickLinksLength > 0) {
      const actions = [
        {
          Icon: Edit,
          hover: 'Edit',
          onClick: (id) => handleEditQuickLinkClicked(id),
        },
        {
          Icon: Delete,
          hover: 'Delete',
          onClick: (id) => handleDeleteQuickLinkClicked(id),
        },
      ];
      options = formValues.qlQuickLinks.map((link, index) => ({
        id: link.id,
        order: index,
        iconUrl: link.iconUrl,
        label: getLabelByLang(link.name),
        labelSx: sx.quickLinkLabel,
        actions,
        validation: link.validation,
      }));
    }
    return options;
  };

  const imageWidth
    = formValues.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE ? 780 : 1755;
  const imageHeight
    = formValues.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE ? 600 : 820;
  const iconUrlFieldName
    = formValues.displayType === A2C_DISPLAY_TYPES.IMAGE ? 'iconUrl' : 'iconUrl2';
  const iconUrl = formValues[iconUrlFieldName];

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography variant="subtitle1">Image General</Typography>
        </Grid>
      </Box>
      {formValues.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE && (
        <Grid item xs>
          {renderFields({
            sx,
            fields: [
              {
                key: 'subtitle2',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'subtitle2En',
                label: 'Subtitle',
                required: true,
                charLimit: 30,
              },
              {
                key: 'subtitle2',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'subtitle2Es',
                label: 'Subtitle (Spanish)',
                charLimit: 30,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
      )}
      <Box sx={sx.fieldSection}>
        <Grid item xs>
          <Field name={iconUrlFieldName}>
            {({ input: { onChange } }) => (
              <ImageUpLoader
                title={getFormLabel('Supporting Image', true)}
                display="stacked"
                fileInfo={
                  <>
                    Supported files includes jpeg and png. File size should be
                    no larger than 3MBs.
                    <br />
                    Image dimensions must be {imageWidth}px by {imageHeight}px.
                  </>
                }
                image={iconUrl ? { url: iconUrl } : null}
                onImageUpdated={({ file, url }) => {
                  onIconUploaded(
                    {
                      file,
                      iconUrl: url,
                    },
                    iconUrlFieldName,
                  );
                  onChange(url);
                }}
                onImageDeleted={() => {
                  onIconUploaded(
                    { file: null, iconUrl: null },
                    iconUrlFieldName,
                  );
                  onChange(null);
                }}
                allowDelete={false}
                showImage={true}
                showImageName={false}
                maxFileSize={3000000}
                minWidth={imageWidth}
                minHeight={imageHeight}
                maxWidth={imageWidth}
                maxHeight={imageHeight}
                previewWidth={
                  formValues.displayType === A2C_DISPLAY_TYPES.IMAGE ? 233 : 142
                }
                previewHeight={109}
              />
            )}
          </Field>
        </Grid>
      </Box>
      <Box sx={sx.fieldArea}>
        {formValues.displayType === A2C_DISPLAY_TYPES.MAP_AND_IMAGE && (
          <Grid item xs sx={{ marginTop: '-20px' }}>
            <LinkDestination
              form={form}
              hasCustomLink={!!formValues.custom}
              supportedLinkTypes={SUPPORTED_LINK_TYPES}
            />
          </Grid>
        )}
        {formValues.displayType !== A2C_DISPLAY_TYPES.MAP_AND_IMAGE && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '14px',
              }}
            >
              <Typography variant="subtitle1" className="required">
                {quickLinksLength} Quicklinks
              </Typography>
              {quickLinksLength > 0 && (
                <IconButton
                  onClick={() => handleShowQuickLinkModal()}
                  aria-label="add-tile"
                  sx={{ padding: 0 }}
                  size="large"
                >
                  <AddCircle
                    sx={{
                      color: ColorPalette.primary.main,
                      fontSize: '40px',
                    }}
                  />
                </IconButton>
              )}
            </Box>
            {quickLinksLength === 0 ? (
              <EmptySection
                title="There Are No Quicklinks Set Up"
                buttonLabel="Add a Quicklink"
                hasPermission={quickLinksAddPermission}
                ButtonIcon={Add}
                buttonClicked={() => handleShowQuickLinkModal()}
              />
            ) : (
              <GozioListItem
                listHeaderName="QuickLink Name"
                progressHeaderName="Progress"
                enableReorder={true}
                options={buildListOptions()}
                updateOptions={handleUpdateOptions}
              />
            )}
            {showQuickLinkModal && (
              <QuicklinkModal
                sx={sx}
                onSubmit={handleQuicklinkAddedOrEdited}
                languages={languages}
                onClose={() => setShowQuickLinkModal(false)}
                onIconUploaded={setUploadedIconData}
                iconWidthAndHeight={
                  cardGroupType === CARD_GROUP_TYPES.FINDING_CARE ? 140 : 56
                }
                selectedQuickLink={selectedQuickLink}
                supportedLinkTypes={['category', 'list']}
              />
            )}
          </>
        )}
      </Box>
    </TabPanel>
  );
};

AccessToCareImageDetailsTabPanel.propTypes = {
  cardGroupType: PropTypes.string.isRequired,
  sx: PropTypes.object,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  index: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onIconUploaded: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
};

AccessToCareImageDetailsTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
