import makeStyles from '@mui/styles/makeStyles';
import { colorWithAlpha } from 'helpers/color-util';
import { TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.pac-container': {
      minWidth: 'auto !important',
      marginTop: '8px',
      borderRadius: 0,
      boxShadow: `box-shadow: 0px 5px 5px -3px ${colorWithAlpha(
        theme.palette.black,
        0.2,
      )},0px 8px 10px 1px ${colorWithAlpha(
        theme.palette.black,
        0.14,
      )},0px 3px 14px 2px ${colorWithAlpha(theme.palette.black, 0.12)}`,
    },
    '.pac-item': {
      height: '48px',
      marginBottom: '5px',
      padding: '9px 16px',
      borderTop: 0,
      '& span': {
        fontFamily: 'Roboto, Helvetica, sans-serif',
        fontWeight: 300,
        color: theme.palette.grey[500],
        fontSize: '1rem',
        letterSpacing: '0.29px',
      },
    },
    '.pac-item:hover': {
      background: `linear-gradient(${theme.palette.gradient.table.start}, ${theme.palette.gradient.table.end})`,
      backgroundColor: '#fff',
      '& span': {
        fontWeight: 'bold',
      },
    },
    '.pac-icon': {
      display: 'none',
    },
    '.pac-item-query': {
      paddingRight: 0,
      '&::after': {
        content: '","',
        paddingRight: '6px',
      },
    },
    '.pac-matched': {
      color: theme.palette.grey[600],
      fontWeight: 'bold',
    },
  },
}));

const parseAddress = (field, addressObject) => addressObject.find((addressLine) => addressLine.types.includes(field))
    ?.short_name;

const parsePlace = (addressObject) => {
  const { geometry, address_components } = addressObject;
  let streetAddress = parseAddress('street_address', address_components);
  if (!streetAddress) {
    const streetNumber = parseAddress('street_number', address_components);
    streetAddress = `${streetNumber ? `${streetNumber} ` : ''}${parseAddress(
      'route',
      address_components,
    )}`;
  }
  return {
    streetAddress,
    city: parseAddress('locality', address_components),
    state: parseAddress('administrative_area_level_1', address_components),
    zip: parseAddress('postal_code', address_components),
    suite: parseAddress('suite', address_components),
    building: parseAddress('building', address_components),
    floor: parseAddress('floor', address_components),
    latitude: geometry?.location?.lat(),
    longitude: geometry?.location?.lng(),
  };
};

function LocationInput(props) {
  const classes = useStyles();
  const { name, variant, fullWidth, autoCompleteCallback, ...rest } = props;
  const autoCompleteFieldRef = useRef(null);
  const autoCompleteObjRef = useRef(null);

  useEffect(() => {
    const loadPlaces = async () => {
      if (!window.google?.maps?.places) await window.google.maps.importLibrary('places');
      autoCompleteObjRef.current = new window.google.maps.places.Autocomplete(
        autoCompleteFieldRef.current,
        { types: ['address'], componentRestrictions: { country: 'us' } },
      );
      autoCompleteObjRef.current.setFields([
        'address_components',
        'formatted_address',
        'geometry',
      ]);
      autoCompleteObjRef.current.addListener('place_changed', () => {
        const addressObject = autoCompleteObjRef.current.getPlace();
        autoCompleteCallback(parsePlace(addressObject));
      });
    };
    loadPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FinalTextField
      name={name}
      variant={variant}
      fullWidth={fullWidth}
      className={classes.locationInputWrap}
      inputProps={{
        ref: autoCompleteFieldRef,
        onFocus: (e) => e.currentTarget.setAttribute('autocomplete', 'new-password'), // turn off chrome autofill
      }}
      {...rest}
    />
  );
}

LocationInput.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  autoCompleteCallback: () => {},
};

LocationInput.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.node,
  fullWidth: PropTypes.bool,
  autoCompleteCallback: PropTypes.func,
};

export default LocationInput;
