import { Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.grey[500],

		'&.Mui-checked': {
			color: theme.palette.blue[400],
		},

		'&.Mui-disabled': {
			'&.Mui-checked': {
				color: theme.palette.grey[300],
			},

			'& .MuiIconButton-label': {
				color: theme.palette.grey[300],
			},
		},

		'&:hover': {
			backgroundColor: 'transparent',
		},

		'& .MuiSvgIcon-root': {
			fontSize: '20px',
		},
	},
}));

const GozioRadio = ({ checked, disabled, onClick, value }) => {
	const classes = useStyles();
	return (
		<Radio
			className={classes.root}
			checked={checked}
			disabled={disabled}
			disableRipple
			color="default"
			size="small"
			value={value}
			data-test={`gozioRadio-${value}`}
		/>
	);
};

GozioRadio.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

GozioRadio.defaultProps = {
	checked: false,
	disabled: false,
};

export default GozioRadio;
