import { parseByLang } from 'helpers/form-util';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

export const kioskSchema = Yup.object().shape({
	name: Yup.string().required('Kiosk name is required'),
	x: Yup.number().integer('X coordinate must be an integer').nullable().typeError('X coordinate must be an integer'),
	y: Yup.number().integer('Y coordinate must be an integer').nullable().typeError('Y coordinate must be an integer'),
});

export const initialParser = (data) => ({
	name: data?.name,
	assignedId: data?.assignedId,
	siteName: parseByLang(data?.site?.name),
	buildingName: parseByLang(data?.building?.name),
	floorId: data?.floor?.id,
	x: data?.x,
	y: data?.y,
});

export const validate = makeValidate(kioskSchema);
