import { Box, Grid, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import {
  MY_FAVORITES_BUTTON_TEXT,
  MY_FAVORITES_DISPLAY_TYPES,
} from 'components/dashboard/myFavoritesHelper';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import TabPanel from 'components/tabPanel/tabPanel';
import VisibilitySelector from 'components/selector/VisibilitySelector';
import { getFormLabel } from 'helpers/form-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

export const MyFavoritesEmptyStateTabPanel = ({
  backgroundColor,
  sx,
  form,
  formValues,
  index,
  languages = [],
  onIconUploaded,
  tabValue,
  textColor,
}) => (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            Template Options
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body1" sx={{ color: '#454e58' }}>
            Card Display
          </Typography>
          <Field name="empty_displayType">
            {({ input: { value, onChange } }) => (
              <GozioRadioGroup
                groupName="displayType"
                onChange={(value) => {
                  onChange(value);
                  if (value === MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS) {
                    form.change(
                      'empty_buttonTextEn',
                      MY_FAVORITES_BUTTON_TEXT.LOCATIONS,
                    );
                  } else {
                    form.change(
                      'empty_buttonTextEn',
                      MY_FAVORITES_BUTTON_TEXT.PROVIDERS,
                    );
                  }
                  form.change('empty_buttonTextEs', '');
                }}
                options={[
                  {
                    label: 'Favorite Locations',
                    value: MY_FAVORITES_DISPLAY_TYPES.FAVORITE_LOCATIONS,
                  },
                  {
                    label: 'Favorite Providers',
                    value: MY_FAVORITES_DISPLAY_TYPES.FAVORITE_PROVIDERS,
                  },
                ]}
                row={false}
                value={value}
              />
            )}
          </Field>
        </Grid>
      </Box>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            Empty State
          </Typography>
        </Grid>
        <Grid item xs sx={sx.formFields}>
          {renderFields({
            sx,
            fields: [
              {
                key: 'empty_title',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'empty_titleEn',
                label: 'Title',
                required: true,
                charLimit: 24,
              },
              {
                key: 'empty_title',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'empty_titleEs',
                label: 'Title (Spanish)',
                charLimit: 24,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
        <Grid item xs sx={sx.formFields}>
          {renderFields({
            sx,
            fields: [
              {
                key: 'empty_subtitle',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'empty_subtitleEn',
                label: 'Subtitle',
                required: true,
                charLimit: 80,
              },
              {
                key: 'empty_subtitle',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'empty_subtitleEs',
                label: 'Subtitle (Spanish)',
                charLimit: 80,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
        <Grid item xs sx={sx.fieldSection}>
          {renderFields({
            sx,
            fields: [
              {
                key: 'empty_buttonText',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'empty_buttonTextEn',
                label: 'Button',
                required: true,
                charLimit: 14,
              },
              {
                key: 'empty_buttonText',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'empty_buttonTextEs',
                label: 'Button (Spanish)',
                charLimit: 14,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="empty_iconUrl">
              {({ input: { onChange, value } }) => (
                <ImageUpLoader
                  title={getFormLabel('Supporting Image', true)}
                  display="stacked"
                  fileInfo="Supported files include JPEG and PNG. File size should be no smaller than 1MB and no larger than 3MBs. Image dimensions must be at least 320px by 320px."
                  image={value ? { url: value } : null}
                  onImageUpdated={({ file, url }) => {
                    onIconUploaded(
                      {
                        file,
                        iconUrl: url,
                      },
                      'empty_iconUrl',
                    );
                    onChange(url);
                  }}
                  onImageDeleted={() => {
                    onIconUploaded({ file: null, iconUrl: null }, value);
                    onChange(null);
                  }}
                  allowDelete={false}
                  showImage={true}
                  showImageName={false}
                  maxFileSize={3000000}
                  minWidth={320}
                  minHeight={320}
                  previewWidth={64}
                  previewHeight={64}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Grid item xs>
            <Typography variant="subtitle1">Color Options</Typography>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="empty_backgroundColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={backgroundColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Background Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardBackgroundColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="empty_textColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={textColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Text Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <VisibilitySelector
          visibilityRuleFieldName="empty_visibilityRule"
          customFieldName="empty_visibilityRuleCustom"
          selectorFieldName="empty_visibilityRuleSelect"
        />
      </Box>
    </TabPanel>
  );

MyFavoritesEmptyStateTabPanel.propTypes = {
  sx: PropTypes.object,
  backgroundColor: PropTypes.string,
  form: PropTypes.object,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  onIconUploaded: PropTypes.func.isRequired,
  tabValue: PropTypes.number,
  textColor: PropTypes.string,
};

MyFavoritesEmptyStateTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
