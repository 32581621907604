import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  buildBackgroundOptionSchema,
  buildDashboardBackgroundOptionInitializeValues,
  renderDashboardBackgroundOptionTabPanels,
} from 'components/dashboard/dashboardHelper';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import useToast from 'hooks/useToast';
import { makeValidate } from 'mui-rff';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import React, { forwardRef, Suspense, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useUpdateNetworkDashboard } from '../../../../../hooks/dataHooks/useNetworkDashboard';

const EditBackgroundOptionPanel = forwardRef((props, ref) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const { toastNotificationErrorHook } = useToast();

  const {
    onEditBackgroundOptionCancelled,
    onEditBackgroundOptionChanged,
    onEditBackgroundOptionSaved,
    networkId,
    dashboardId,
  } = props;

  const [updateDashboard] = useUpdateNetworkDashboard(networkId);

  const validate = makeValidate(buildBackgroundOptionSchema());

  const backgroundOptionErrorFormFieldMap = () => new Map([
    [
      'underlayHeaderColor',
      { title: 'Underlay Header Color', section: 'Underlay Header' },
    ],
    [
      'underlayTextColor',
      { title: 'Underlay Text Color', section: 'Underlay Text Color' },
    ],
    [
      'numberOfCards',
      { title: 'Number of Cards', section: 'Number of Cards' },
    ],
  ]);

  const onSubmitBackgroundOption = async (values = {}) => {
    try {
      await updateDashboard({
        variables: {
          input: {
            dashboardId,
            headerUnderlay: values,
          },
        },
      }).catch((err) => {
        toastNotificationErrorHook(err.message);
      });
      onEditBackgroundOptionSaved(values);
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  };

  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (!initialValues) {
      setInitialValues(buildDashboardBackgroundOptionInitializeValues(props));
    }
  }, [initialValues, props]);

  const handleFormChange = (form) => {
    onEditBackgroundOptionChanged(form.getState().values);
  };

  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDrawerClose = (form) => {
    const { dirty, errors } = form.getState();
    if (!dirty && Object.keys(errors).length === 0) {
      onEditBackgroundOptionCancelled();
    } else {
      setIsClosing(true);
    }
  };

  return (
    <Box ref={ref}>
      <Form
        initialValues={initialValues}
        mutators={{
          setCategoryType: ([value], state, utils) => {
            utils.changeValue(state, 'categoryType', () => value);
          },
        }}
        onSubmit={onSubmitBackgroundOption}
        validate={validate}
        sx={styles.content}
      >
        {({ handleSubmit, form, values, submitting, errors }) => (
          <form
            onChange={() => handleFormChange(form)}
            onSubmit={async (event) => {
              if (Object.keys(errors).length) {
                const errorMessage = Object.keys(errors)
                  .map((fieldKey) => {
                    if (fieldKey === 'expires') {
                      return 'The Expires on date is required';
                    }
                    return errors[
                      typeof fieldKey === 'object' ? fieldKey?.[0] : fieldKey
                      ];
                  })
                  .join('; ');
                toastNotificationErrorHook(errorMessage);
              }

              await handleSubmit(event);
            }}
          >
            <GozioDrawer
              open={true}
              disableCancel={true}
              greyOutConfirm={submitting}
              onClose={() => handleDrawerClose(form)}
              onConfirm={async () => {
                setIsSubmitting(true);
                await form.submit();
                setIsSubmitting(false);
              }}
              title={`Edit Background Header Underlay`}
              top={70}
              width={581}
            >
              <Suspense fallback={<Loading/>}>
                {renderDashboardBackgroundOptionTabPanels(props)}
              </Suspense>
            </GozioDrawer>
            <FormWatcher
              formRenderProps={{ form }}
              errorFormFieldMap={backgroundOptionErrorFormFieldMap()}
              isClosing={isClosing}
              isSubmitting={isSubmitting}
              onClose={() => {
                setIsClosing(false);
                setIsSubmitting(false);
              }}
              onContinue={() => {
                onEditBackgroundOptionCancelled();
                return true;
              }}
            />
          </form>
        )}
      </Form>
    </Box>
  );
});

export default EditBackgroundOptionPanel;
