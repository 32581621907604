import { ArrowBack } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AutoComplete from 'components/autocomplete/autoComplete';
import FormWatcher from 'components/forms/formWatcher';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getFormLabel, isDirtyOrHasErrors, parseByLang } from 'helpers/form-util';
import { tabA11yProps } from 'helpers/page-util';
import { TextField as FinalTextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import KioskTileListing from 'pages/kiosks/containers/kioskTileListing';
import React, { useContext, useMemo, useState } from 'react';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    width: '517px',
    height: '100%',
    borderRight: `2px solid ${colorWithAlpha(ColorPalette.grey[350], 0.3)}`,
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    marginBottom: '1px',
    fontSize: '1.75rem',
    letterSpacing: 0,
    lineHeight: '2.4375rem',
    marginTop: '32px',
    marginLeft: '24px',
  },
  headerActions: {
    color: theme.palette.grey[400],
  },
  backArrow: {
    marginRight: '6px',
    marginLeft: '24px',
    fontSize: '20px',
    color: ColorPalette.grey[400],
  },
  tabs: {
    paddingLeft: '32px',
    marginTop: '8px',
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    boxShadow: `0px 4px 4px -3px ${colorWithAlpha(
      ColorPalette.grey[350],
      0.3,
    )}`,
  },
  tabPanelDirectory: {
    overflowX: 'hidden',
    height: 'calc(100vh - 240px)',
  },
  tabPanelDetails: {
    padding: '24px',
    overflowX: 'auto',
    height: 'calc(100vh - 310px)',
  },
  footer: {
    backgroundColor: theme.palette.grey[50],
    height: '70px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: '16px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  saveBtn: {
    width: '132px',
  },
  progress: {
    color: theme.palette.white,
  },
}));

const formFieldMap = new Map([
  ['name', { label: 'Kiosk Name', section: 'Details' }],
]);

const nameSort = (a, b) => a.label.toLowerCase() > b.label.toLowerCase()
    ? 1
    : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;

const KioskDetails = React.memo(
  ({ kiosk, networkId, form, onEditTile, onDeleteTile, onReorderTiles }) => {
    const classes = useStyles();
    const { handleNavigate } = useContext(FlamingoContext);
    const backToUrl = `/network/${networkId}/kiosks`;
    const [currentTab, setCurrentTab] = useState(0);
    const [nextTab, setNextTab] = useState(null);

    const handleTabChange = (event, newValue) => {
      event.preventDefault();
      if (isDirtyOrHasErrors(form)) {
        setIsClosing(true);
        setNextTab(newValue);
      } else setCurrentTab(newValue);
    };

    const handleBackTo = () => {
      const { dirty, errors } = form.getState();
      if (!dirty && !Object.keys(errors).length) {
        setIsClosing(false);
        handleNavigate(backToUrl);
      } else setIsClosing(true);
    };

    const onSubmit = async () => {
      await form.submit();
    };

    const [isClosing, setIsClosing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigationHandler = (path) => {
      setIsClosing(false);
      setIsSubmitting(false);
      if (nextTab !== null) {
        setNextTab(null);
        setCurrentTab(nextTab);
      } else if (path && path !== backToUrl) {
        handleNavigate(path);
      } else {
        handleNavigate(backToUrl);
      }
    };

    const floorOptions = useMemo(
      () => kiosk?.building?.children?.edges
          ?.map((c) => ({ id: c.node?.id, label: parseByLang(c.node?.name) }))
          .sort(nameSort),
      [kiosk],
    );

    return (
      <>
        <FormWatcher
          formRenderProps={{ form }}
          errorFormFieldMap={formFieldMap}
          isClosing={isClosing}
          isSubmitting={isSubmitting}
          onClose={() => {
            setIsClosing(false);
            setIsSubmitting(false);
          }}
          onContinue={navigationHandler}
          onSave={navigationHandler}
        />
        <Box className={classes.root}>
          <Typography variant="h1" className={classes.title}>
            {kiosk.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
            <ArrowBack className={classes.backArrow} onClick={handleBackTo} />
            <Link
              onClick={handleBackTo}
              variant="subtitle2"
              data-test="LocationDetailBackLink"
              className={classes.headerActions}
              sx={{ color: 'inherit' }}
            >
              Back to Kiosk Settings
            </Link>
          </Box>

          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className={classes.tabs}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="kiosk tabs"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Kiosk Directory" {...tabA11yProps('kiosk', 0)} />
              <Tab label="General" {...tabA11yProps('kiosk', 1)} />
            </Tabs>
          </Grid>
          <TabPanel
            value={currentTab}
            index={0}
            className={classes.tabPanelDirectory}
          >
            <KioskTileListing
              kiosk={kiosk}
              onEditTile={onEditTile}
              onDeleteTile={onDeleteTile}
              onReorderTiles={onReorderTiles}
            />
          </TabPanel>
          <TabPanel
            value={currentTab}
            index={1}
            className={classes.tabPanelDetails}
          >
            <Typography variant="subtitle1" sx={{ marginBottom: '18px' }}>
              Kiosk Details
            </Typography>
            <FinalTextField
              label={getFormLabel('Name', true)}
              name="name"
              variant="outlined"
              sx={{ marginBottom: '26px' }}
            />
            <FinalTextField
              label={
                <span style={{ color: ColorPalette.grey[600] }}>
                  * Assigned ID
                </span>
              }
              name="assignedId"
              variant="outlined"
              disabled
            />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '40px', marginBottom: '18px' }}
            >
              Kiosk Location
            </Typography>
            <FinalTextField
              label={
                <span style={{ color: ColorPalette.grey[600] }}>* Site</span>
              }
              name="siteName"
              variant="outlined"
              sx={{ marginBottom: '26px' }}
              disabled
            />
            <FinalTextField
              label={
                <span style={{ color: ColorPalette.grey[600] }}>
                  * Building
                </span>
              }
              name="buildingName"
              variant="outlined"
              sx={{ marginBottom: '26px' }}
              disabled
            />
            <Field name="floorId">
              {({ input, meta }) => (
                <AutoComplete
                  input={input}
                  meta={meta}
                  options={floorOptions}
                  label="Floor"
                  required={true}
                  fullWidth
                  disableClearable={true}
                  sx={{ marginBottom: '26px' }}
                />
              )}
            </Field>
            <FinalTextField
              label={'X coordinate'}
              name="x"
              variant="outlined"
              sx={{ marginBottom: '26px' }}
            />
            <FinalTextField
              label={'Y coordinate'}
              name="y"
              variant="outlined"
              sx={{ marginBottom: '26px' }}
            />
            <Box className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                onClick={onSubmit}
                disabled={
                  form.getState().submitting
                  || form.getState().invalid
                  || form.getState().pristine
                }
                startIcon={
                  form.getState().submitting ? (
                    <CircularProgress
                      size={16}
                      thickness={4}
                      className={classes.progress}
                    />
                  ) : null
                }
              >
                Save
              </Button>
            </Box>
          </TabPanel>
        </Box>
      </>
    );
  },
);

export default KioskDetails;
