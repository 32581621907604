import { Box, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CARD_GROUP_TYPES } from 'components/dashboard/cardLayouts/cardhelper';
import {
  buildDashboardCardInitializeValues,
  buildDashboardCardSchema,
  renderTabHeaders,
  renderDashboardCardTabPanels,
  submitDashboardCard,
} from 'components/dashboard/dashboardHelper';
import { submitMyFavoritesCard } from 'components/dashboard/myFavoritesHelper';
import { submitOpenPlatformCard } from 'components/dashboard/openPlatformHelper';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import {
  useUpdateDashboardCard,
  useUpdateDashboardMyChartCard,
  useUpdateDashboardMyFavoritesCard,
} from 'hooks/dataHooks/useNetworkDashboard';
import useToast from 'hooks/useToast';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import {
  getErrorFormFieldMap,
  mapCustomLocations,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React, { forwardRef, Suspense, useEffect, useState } from 'react';
import { Form } from 'react-final-form';

const EditCardPanel = forwardRef((props, ref) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const { toastNotificationErrorHook } = useToast();

  const {
    languages,
    cardGroupId,
    cardGroupType,
    cardId,
    backgroundImageUrl,
    dismissable,
    expires,
    locations = {},
    quickLinks,
    size,
    waitTimes,
    onEditCardCancelled,
    onEditCardChanged,
    onEditCardSaved,
    onTabValueChanged,
  } = props;

  const [tabValue, setTabValue] = useState(0);
  const [uploadedIcon, setUploadedIcon] = useState(null);

  const initialCustomLocations = mapCustomLocations(locations?.custom || {});
  const [customLocations, setCustomLocations] = useState(
    initialCustomLocations,
  );

  const [useColorFillAsBackground, setUseColorFillAsBackground] = useState(
    cardGroupType === CARD_GROUP_TYPES.EXPANDABLE || !backgroundImageUrl,
  );
  const [uploadedBackgroundImageFile, setUploadedBackgroundImageFile]
    = useState(null);

  const [updateCard] = useUpdateDashboardCard();
  const [updateMyChartCard] = useUpdateDashboardMyChartCard();
  const [updateMyFavoritesCard] = useUpdateDashboardMyFavoritesCard();

  const hasWaitTimes = waitTimes?.included;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onTabValueChanged(newValue);
  };

  const validate = makeValidate(buildDashboardCardSchema(cardGroupType, languages));

  const onSubmit = async (values = {}) => {
    try {
      let iconImage;
      switch (cardGroupType) {
        case CARD_GROUP_TYPES.MY_FAVORITES:
          if (uploadedIcon?.file) {
            iconImage = await uploadImage(uploadedIcon.file);
            setUploadedIcon(null);
          }

          await submitMyFavoritesCard({
            iconImage,
            updateCard: updateMyFavoritesCard,
            values,
          });
          await onEditCardSaved();
          break;
        case CARD_GROUP_TYPES.OPEN_PLATFORM:
          await submitOpenPlatformCard({ cardId, updateCard, values });
          await onEditCardSaved();
          setInitialValues(null);
          break;
        default:
          await submitDashboardCard({
            cardGroupType,
            cardGroupId,
            cardId,
            customLocations,
            dismissable,
            expires,
            hasWaitTimes,
            initialValues,
            languages,
            onEditCardSaved,
            quickLinks,
            setInitialValues,
            setUploadedIcon,
            setUploadedBackgroundImageFile,
            size,
            toastNotificationErrorHook,
            updateCard,
            updateMyChartCard,
            uploadedBackgroundImageFile,
            uploadedIcon,
            useColorFillAsBackground,
            values,
            waitTimes,
          });
          break;
      }
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  };

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (!initialValues) {
      setInitialValues(buildDashboardCardInitializeValues(props));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleFormChange = (form, overrideValues = {}) => {
    const additionalValues = { ...overrideValues };
    if (
      !additionalValues.iconUrl
      && uploadedIcon?.iconUrl
      && !form.getState().values.iconUrl2
    ) {
      additionalValues.iconUrl = uploadedIcon?.iconUrl;
    }
    if (
      !additionalValues.iconUrl2
      && uploadedIcon?.iconUrl2
      && !form.getState().values.iconUrl
    ) {
      additionalValues.iconUrl2 = uploadedIcon?.iconUrl2;
    }
    Object.keys(overrideValues).forEach(
      (key) => (additionalValues[key] = overrideValues[key]),
    );
    onEditCardChanged({
      cardGroupType,
      cardGroupId,
      cardId,
      customLocations,
      hasWaitTimes,
      languages,
      quickLinks,
      size,
      values: { ...form.getState().values, ...additionalValues },
    });
  };

  const handleIconUploaded = (form, icon, fieldName = 'iconUrl') => {
    setUploadedIcon(icon);
    handleFormChange(form, { [fieldName]: icon?.iconUrl || null });
  };

  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDrawerClose = (form) => {
    const { dirty, errors } = form.getState();
    if (!dirty && Object.keys(errors).length === 0) {
      onEditCardCancelled();
    } else {
      setIsClosing(true);
    }
  };

  return (
    <Box ref={ref}>
      <Form
        initialValues={initialValues}
        mutators={{
          setCategoryType: ([value], state, utils) => {
            utils.changeValue(state, 'categoryType', () => value);
          },
        }}
        onSubmit={onSubmit}
        validate={validate}
        sx={styles.content}
      >
        {({ handleSubmit, form, values, submitting, errors }) => (
          <form
            onChange={() => handleFormChange(form)}
            onSubmit={async (event) => {
              if (Object.keys(errors).length) {
                const errorMessage = Object.keys(errors)
                  .map((fieldKey) => {
                    if (fieldKey === 'expires') {
                      return 'The Expires on date is required';
                    }
                    return errors[
                      typeof fieldKey === 'object' ? fieldKey?.[0] : fieldKey
                      ];
                  })
                  .join('; ');
                toastNotificationErrorHook(errorMessage);
              }

              await handleSubmit(event);
            }}
          >
            <GozioDrawer
              open={true}
              disableCancel={true}
              greyOutConfirm={submitting}
              onClose={() => handleDrawerClose(form)}
              onConfirm={async () => {
                setIsSubmitting(true);
                await form.submit();
                ['iconUrl', 'iconUrl2', 'backgroundImageUrl'].forEach(
                  (field) => {
                    if (
                      CARD_GROUP_TYPES.CARE_NEAR_ME === cardGroupType
                      && values[field] !== initialValues[field]
                    ) {
                      form.change(field, initialValues[field]);
                    }
                  },
                );
                setIsSubmitting(false);
              }}
              title="Edit Card"
              top={70}
              width={581}
            >
              <Box sx={styles.root}>
                <Box sx={styles.tabs}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    {renderTabHeaders({ cardGroupType, values })}
                  </Tabs>
                </Box>
                <Suspense fallback={<Loading />}>
                  {renderDashboardCardTabPanels({
                    sx: styles,
                    customLocations,
                    form,
                    handleIconUploaded,
                    handleFormChange,
                    props,
                    setCustomLocations,
                    setUploadedBackgroundImageFile,
                    setUseColorFillAsBackground,
                    tabValue,
                    useColorFillAsBackground,
                    values,
                  })}
                </Suspense>
              </Box>
            </GozioDrawer>
            <FormWatcher
              allowContinueOnError
              formRenderProps={{ form }}
              errorFormFieldMap={getErrorFormFieldMap(
                cardGroupType === 'myChart',
              )}
              isClosing={isClosing}
              isSubmitting={isSubmitting}
              onClose={() => {
                setIsClosing(false);
                setIsSubmitting(false);
              }}
              onContinue={() => {
                setIsClosing(false);
                onEditCardCancelled();
                return true;
              }}
              onCorrect={() => setIsClosing(false)}
            />
          </form>
        )}
      </Form>
    </Box>
  );
});

EditCardPanel.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  ]),
  cardGroupId: PropTypes.string.isRequired,
  cardGroupType: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  expires: PropTypes.any,
  iconUrl: PropTypes.string,
  iconUrl2: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkBackgroundColor: PropTypes.string,
  linkData: PropTypes.object,
  locations: PropTypes.shape({
    allUrgentCare: PropTypes.bool,
    allEmergencyDepartments: PropTypes.bool,
    custom: PropTypes.object,
  }),
  measurementHeight: PropTypes.oneOf(['minimum', 'short', 'medium', 'tall']),
  onEditCardCancelled: PropTypes.func.isRequired,
  onEditCardChanged: PropTypes.func.isRequired,
  onEditCardSaved: PropTypes.func.isRequired,
  onTabValueChanged: PropTypes.func.isRequired,
  overline: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  quickLinks: PropTypes.array,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  ]),
  subtitle2: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  textAlignment: PropTypes.oneOf(['left', 'center']),
  textColor: PropTypes.string,
  title: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  validation: PropTypes.shape({
    status: PropTypes.string,
    errors: PropTypes.array,
  }),
  waitTimes: PropTypes.object,
};

EditCardPanel.defaultProps = {
  buttonText: [],
  dismissable: false,
  validation: {},
  linkData: null,
  locations: {},
  measurementHeight: 'short',
  overline: [],
  subtitle: [],
  subtitle2: [],
  textAlignment: 'left',
  title: [],
};

export default EditCardPanel;
