import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PhoneFrame from 'assets/phone-frame-nav.svg';
import { SCOPES } from 'components/authorization/authorize';
import BannerCard from 'components/banner/bannerCard';
import Condition from 'components/forms/condition';
import FillOptions from 'components/forms/fillOptions';
import FormWatcher from 'components/forms/formWatcher';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import LinkDestination from 'components/forms/linkDestination';
import SplitButton from 'components/splitButton/splitButton';
import { LargeToggleButton, LargeToggleButtonGroup } from 'components/toggleButtonGroup/toggleButtonGroup';
import WarningPanel from 'components/warningPanel/warningPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { buildLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { useCreateNetworkBanner, useNetworkBanners, useUpdateNetworkBanner } from 'hooks/dataHooks/useNetworkBanner';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useToast from 'hooks/useToast';
import { findLast, get, sortBy } from 'lodash';
import LogRocket from 'logrocket';
import { uploadImage } from 'modules/media';
import { TextField } from 'mui-rff';
import {
  BANNER_ERROR_FORM_FIELD_MAP,
  EMPTY_BANNER,
  getBannerDisabledStateForDeletion,
  getBannerDisabledStateForPublishing,
  getBannerValidator,
} from 'pages/messaging/containers/bannerMessage/bannerHelper';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { maskPhone } from 'helpers/string-util';

const BACKGROUND_TYPE_OPTIONS = [
  {
    label: 'Use Image',
    value: 'image',
    description: '',
  },
  {
    label: 'Use Color',
    value: 'color',
    description: '',
  },
];

const SAVE_TYPE_LABELS = {
  saveDraft: 'Save as Draft',
  savePublish: 'Save and Publish',
  recall: 'Recall Last Publish',
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'scroll',
  },
  extraContent: {
    alignItems: 'center	',
    backgroundColor: theme.palette.grey[300],
    border: `10px solid ${theme.palette.white}`,
    bottom: '125px',
    display: 'flex',
    height: '460px',
    justifyContent: 'center',
    left: '8px',
    position: 'absolute',
    width: '375px',
  },
  formButtons: {
    position: 'absolute',
    right: 0,
    top: '-134px',
    zIndex: 100,
  },
  formContainer: {
    borderColor: theme.palette.grey[300],
    borderRight: '1px solid',
    paddingRight: '16px',
  },
  previewContainer: {
    backgroundImage: `url(${PhoneFrame})`,
    height: '838px',
    position: 'relative',
    width: '388px',
  },
  splitButtonMenu: {
    marginTop: '-100px',
  },
}));

/* eslint-disable-next-line max-lines-per-function */
const Banner = ({ media, networkId }) => {
  const { toastNotificationErrorHook } = useToast();

  const { authorize } = useContext(FlamingoContext);
  const { data, refetch } = useNetworkBanners(networkId);
  const [createBanner] = useCreateNetworkBanner();
  const [updateBanner] = useUpdateNetworkBanner();

  const classes = useStyles();
  const [saveType, setSaveType] = useState('saveDraft');
  const [initialBanner, setInitialBanner] = useState({});
  const [activeBanner, setActiveBanner] = useState({});
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const languages = useActiveNetworkLanguages();
  const esEnabled = hasSpanish(languages);

  useEffect(() => {
    let tempBanner = {
      ...EMPTY_BANNER,
      langES: esEnabled,
      categories: [],
      externalURL: '',
      externalType: '',
      externaliOSURL: '',
      externalAndroidURL: '',
      fallbackiOSURL: '',
      fallbackAndroidURL: '',
      hasPlatformSpecificFallbacks: false,
      uploadedIconUrl: null,
      uploadedIconUrlES: null,
      webUrlEn: '',
      webUrlEs: '',
    };
    if (data?.getNetwork?.bannerMessages) {
      const sorted = sortBy(data.getNetwork.bannerMessages, ['createdAt']);
      const draft = findLast(sorted, { status: 'draft' })
        ? findLast(sorted, { status: 'draft' })
        : sorted[sorted.length - 1];
      setActiveBanner(findLast(sorted, { status: 'active' }));
      if (draft) {
        const dataId = draft.actionLink?.data?.id;
        if (draft.backgroundImage) {
          tempBanner.backgroundType = 'image';
        }

        tempBanner = {
          ...tempBanner,
          ...draft.status && { status: draft.status },
          ...draft.id && { id: draft.id },
          ...draft.theme && { theme: draft.theme },
          ...draft.backgroundColor && {
            backgroundType: 'color',
            backgroundColor: draft.backgroundColor,
          },
          ...draft.backgroundImage && {
            backgroundType: 'image',
            uploadedIconUrl: {
              icon: draft.backgroundImage.id,
              url: draft.backgroundImage.url,
            },
          },
          ...draft.backgroundImageES && {
            backgroundType: 'image',
            uploadedIconUrlES: {
              icon: draft.backgroundImageES.id,
              url: draft.backgroundImageES.url,
            },
          },
          ...!!findLast(draft.backgroundImageAccessibilityText, { lang: LANGUAGE_CODES.ENGLISH }) && {
            accessibilityTextEN: findLast(draft.backgroundImageAccessibilityText, { lang: LANGUAGE_CODES.ENGLISH })
            .label,
          },
          ...!!findLast(draft.backgroundImageAccessibilityText, { lang: LANGUAGE_CODES.SPANISH }) && {
            accessibilityTextES: findLast(draft.backgroundImageAccessibilityText, { lang: LANGUAGE_CODES.SPANISH })
            .label,
          },
          ...!!findLast(draft.title, { lang: LANGUAGE_CODES.ENGLISH }) && {
            titleEN: findLast(draft.title, { lang: LANGUAGE_CODES.ENGLISH })
              .label,
          },
          ...!!findLast(draft.title, { lang: LANGUAGE_CODES.SPANISH }) && {
            titleES: findLast(draft.title, { lang: LANGUAGE_CODES.SPANISH })
              .label,
          },
          ...!!findLast(draft.subtitle, { lang: LANGUAGE_CODES.ENGLISH }) && {
            subtitleEN: findLast(draft.subtitle, {
              lang: LANGUAGE_CODES.ENGLISH,
            }).label,
          },
          ...!!findLast(draft.subtitle, { lang: LANGUAGE_CODES.SPANISH }) && {
            subtitleES: findLast(draft.subtitle, {
              lang: LANGUAGE_CODES.SPANISH,
            }).label,
          },
          ...!!findLast(draft.buttonLabel, {
            lang: LANGUAGE_CODES.ENGLISH,
          }) && {
            buttonEN: findLast(draft.buttonLabel, {
              lang: LANGUAGE_CODES.ENGLISH,
            }).label,
          },
          ...!!findLast(draft.buttonLabel, {
            lang: LANGUAGE_CODES.SPANISH,
          }) && {
            buttonES: findLast(draft.buttonLabel, {
              lang: LANGUAGE_CODES.SPANISH,
            }).label,
          },
          ...draft.actionLink && { linkType: draft.actionLink.type },
          ...draft.actionLink
            && draft.actionLink.type === 'category' && {
              categories: draft.actionLink.categories,
            },
          ...draft.actionLink
            && draft.actionLink.type === 'in_app' && {
              inAppDest: dataId,
            },
          ...draft.actionLink
            && draft.actionLink.type === 'list' && {
              listDest: dataId,
            },
          ...draft.actionLink
            && draft.actionLink.type === 'external_app' && {
              externalURL: draft.actionLink.data.url,
              externalAndroidURL: draft.actionLink.data.androidUrl,
              externaliOSURL: draft.actionLink.data.iosUrl,
              externalType: draft.actionLink.data.client,
              fallbackiOSURL: draft.actionLink.data.iosFallbackUrl,
              fallbackAndroidURL: draft.actionLink.data.androidFallbackUrl,
              hasPlatformSpecificFallbacks: !!(
                draft.actionLink.data.androidFallbackUrl
                || draft.actionLink.data.iosFallbackUrl
              ),
            },
          ...draft.actionLink
            && draft.actionLink.type === 'phone' && {
              phone: maskPhone(draft.actionLink.data.phone),
            },
          ...draft.actionLink
            && draft.actionLink.type === 'website' && {
              webUrlEn: draft.actionLink.data.url?.en,
              ...esEnabled && {
                webUrlEs: draft.actionLink.data.url?.es || null,
              },
              webType: draft.actionLink.data.client,
            },
          ...draft.actionLink
            && draft.actionLink.type === 'custom' && {
              custom: draft.actionLink.data.url,
            },
        };
        LogRocket.log('Banner initial values:', tempBanner);
      }
      setInitialBanner(tempBanner);
    }
  }, [data, esEnabled]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    if (saveType === 'recall' && !!activeBanner) {
      await updateBanner({
        variables: {
          id: activeBanner.id,
          input: {},
          meta: { action: 'revert' },
        },
      });
      await refetch();
    }

    try {
      let image = null,
        imageES = null;
      if (
        values.backgroundType === 'image'
        && (!!values.uploadedIconUrl?.file || !!values.uploadedIconUrlES?.file)
      ) {
        image = values.uploadedIconUrl?.file
          ? await uploadImage(values.uploadedIconUrl.file)
          : null;
        imageES = values.uploadedIconUrlES?.file
          ? await uploadImage(values.uploadedIconUrlES.file)
          : null;
      }

      const error = get(media, 'flash.error');
      if (error) {
        toastNotificationErrorHook(media.flash);
        return;
      }

      let imageId, imageIdES, tempLink;
      if (image?.id) {
        imageId = image.id;
      } else if (
        values.backgroundType === 'image'
        && !!values.uploadedIconUrl?.icon
      ) {
        imageId = values.uploadedIconUrl.icon;
      }

      if (imageES?.id) {
        imageIdES = imageES.id;
      } else if (
        values.backgroundType === 'image'
        && !!values.uploadedIconUrlES?.icon
      ) {
        imageIdES = values.uploadedIconUrlES.icon;
      }

      if (values.linkType) {
        tempLink = {
          name: [
            { lang: LANGUAGE_CODES.ENGLISH, label: 'action' },
            values.langES
              ? { lang: LANGUAGE_CODES.SPANISH, label: 'acion' }
              : null,
          ].filter((notFalse) => notFalse),
        };
        switch (values.linkType) {
          case 'category':
            tempLink = {
              ...tempLink,
              type: 'category',
              data: {
                id: values.categories ? values.categories.map((c) => c.id) : [],
              },
            };
            break;
          case 'in_app':
            tempLink = {
              ...tempLink,
              type: 'in_app',
              data: {
                id: values.inAppDest ? values.inAppDest : '',
              },
            };
            break;
          case 'list':
            tempLink = {
              ...tempLink,
              type: 'list',
              data: {
                id: values.listDest ? values.listDest : '',
              },
            };
            break;
          case 'external_app':
            tempLink = {
              ...tempLink,
              type: 'external_app',
              data: {
                client: values.externalType ? values.externalType : '',
                url: values.externalURL ? values.externalURL : '',
                androidUrl: values.externalAndroidURL
                  ? values.externalAndroidURL
                  : '',
                iosUrl: values.externaliOSURL ? values.externaliOSURL : '',
              },
            };

            if (values.hasPlatformSpecificFallbacks) {
              tempLink.data.androidFallbackUrl
                = values.fallbackAndroidURL ?? null;
              tempLink.data.iosFallbackUrl = values.fallbackiOSURL ?? null;
            } else {
              tempLink.data.androidFallbackUrl = null;
              tempLink.data.iosFallbackUrl = null;
            }
            break;
          case 'phone': {
            tempLink = {
              ...tempLink,
              type: 'phone',
              data: {
                phone: values.phone?.replace(/[-)(\s]/g, ''),
              },
            };
            break;
          }
          case 'website':
            tempLink = {
              ...tempLink,
              type: 'website',
              data: {
                client: values.webType ?? '',
                url: {
                  en: values.webUrlEn ?? '',
                  ...esEnabled && {
                    es: values.webUrlEs ?? '',
                  },
                },
              },
            };
            break;
          case 'custom':
            tempLink = {
              ...tempLink,
              type: 'custom',
              data: {
                url: values.custom ?? '',
              },
            };
            break;
          default:
            tempLink = null;
            break;
        }
      }

      const newBanner = {
        ...values.theme && { theme: values.theme },
        ...{
          title: [
            {
              lang: LANGUAGE_CODES.ENGLISH,
              label: values.titleEN ? values.titleEN : '',
            },
            values.langES
              ? {
                lang: LANGUAGE_CODES.SPANISH,
                label: values.titleES ? values.titleES : '',
              }
              : null,
          ].filter((notFalse) => notFalse),
        },
        ...{
          subtitle: [
            {
              lang: LANGUAGE_CODES.ENGLISH,
              label: values.subtitleEN ? values.subtitleEN : '',
            },
            values.langES
              ? {
                lang: LANGUAGE_CODES.SPANISH,
                label: values.subtitleES ? values.subtitleES : '',
              }
              : null,
          ].filter((notFalse) => notFalse),
        },
        ...{
          buttonLabel: [
            {
              lang: LANGUAGE_CODES.ENGLISH,
              label: values.buttonEN ? values.buttonEN : '',
            },
            values.langES
              ? {
                lang: LANGUAGE_CODES.SPANISH,
                label: values.buttonES ? values.buttonES : '',
              }
              : null,
          ].filter((notFalse) => notFalse),
        },
        backgroundColor:
          values.backgroundType === 'color' ? values.backgroundColor : null,
        ...values.backgroundType === 'color' && {
          backgroundImage: null,
          backgroundImageES: null,
        },
        ...!!imageId && {
          backgroundImage: values.backgroundType === 'image' ? imageId : null,
        },
        ...!!imageIdES && {
          backgroundImageES:
            values.backgroundType === 'image' ? imageIdES : null,
        },
        ...values.backgroundType === 'image' && !!imageId && {
          backgroundImageAccessibilityText: values.accessibilityTextEN?.trim() || values?.accessibilityTextES?.trim()
                ? buildLabelByLang(
                    {
                        ...values,
                        accessibilityTextEN: values.accessibilityTextEN?.trim() || undefined,
                        accessibilityTextES: values.accessibilityTextES?.trim() || undefined,
                    },
                    'accessibilityTextEN',
                    'accessibilityTextES'
                )
            : null,
        },
        actionLink: tempLink || null,
      };

      const tempVar = {
        ...values.status === 'draft' && { id: values.id },
        input: newBanner,
        ...saveType === 'savePublish' && { meta: { action: 'publish' } },
      };

      if (tempVar.id) {
        await updateBanner({ variables: tempVar });
        await refetch();
      } else {
        await createBanner({ variables: tempVar });
        await refetch();
      }
    } catch (err) {
      toastNotificationErrorHook(`Image Failed to upload: ${err}`);
    }
    setIsSubmitting(false);
  };

  const onFormResetFields = () => {
    setInitialBanner({
      ...initialBanner,
      ...EMPTY_BANNER,
      categories: [],
      externalURL: '',
      externalType: '',
      externaliOSURL: '',
      externalAndroidURL: '',
      fallbackiOSURL: '',
      fallbackAndroidURL: '',
      hasPlatformSpecificFallbacks: false,
      uploadedIconUrl: null,
      uploadedIconUrlES: null,
      webUrlEn: '',
      webUrlEs: '',
    });
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ paddingTop: '24px' }}>
        <Grid item xs={12}>
          <Form
            onSubmit={onSubmit}
            initialValues={initialBanner}
            validate={getBannerValidator(languages)}
          >
            {({ handleSubmit, form, submitting, invalid, values }) => (
              <form
                onSubmit={async (event) => {
                  await handleSubmit(event);
                }}
              >
                <FormWatcher
                  allowContinueOnError
                  formRenderProps={{ form }}
                  errorFormFieldMap={BANNER_ERROR_FORM_FIELD_MAP}
                  isClosing={isClosing}
                  isSubmitting={isSubmitting}
                  onClose={() => {
                    setIsClosing(false);
                    setIsSubmitting(false);
                  }}
                  unsavedConfirmText={SAVE_TYPE_LABELS[saveType]}
                />
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes.formButtons}
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      disabled={getBannerDisabledStateForDeletion(values)}
                      onClick={() => {
                        onFormResetFields();
                      }}
                      color="primary"
                    >
                      DELETE
                    </Button>
                  </Grid>
                  <Grid item>
                    <SplitButton
                      popperClassName={classes.splitButtonMenu}
                      options={[
                        {
                          name: 'Save as draft',
                          disabled: submitting || invalid,
                          action: 'saveDraft',
                        },
                        {
                          name: 'Save and publish',
                          disabled:
                            submitting
                            || invalid
                            || getBannerDisabledStateForPublishing(values),
                          action: 'savePublish',
                        },
                        {
                          name: 'Recall last publish',
                          disabled: !activeBanner,
                          action: 'recall',
                        },
                      ]}
                      handleChangeState={(selectedOption) => {
                        setSaveType(selectedOption);
                      }}
                      disabled={invalid}
                      type="submit"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" sx={{ width: '99%' }}>
                  <Grid
                    xs
                    item
                    container
                    direction="column"
                    spacing={2}
                    className={classes.formContainer}
                  >
                    <Condition when="status" is="draft">
                      <Grid item>
                        <WarningPanel
                          message="This content is in draft mode. Click save and publish to see this banner in the app."
                          disableClose
                        />
                      </Grid>
                    </Condition>
                    <Grid item>
                      <Typography variant="subtitle1">Color options</Typography>
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography variant="subtitle2">Theme</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="theme">
                          {({ input, meta }) => (
                            <LargeToggleButtonGroup
                              exclusive
                              value={input.value}
                              onChange={(event, newTheme) => {
                                if (newTheme != null) {
                                  input.onChange(newTheme);
                                }
                              }}
                            >
                              <LargeToggleButton value="light">
                                <Box>Light</Box>
                              </LargeToggleButton>
                              <LargeToggleButton value="dark">
                                <Box>Dark</Box>
                              </LargeToggleButton>
                            </LargeToggleButtonGroup>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        marginLeft: '16px',
                        paddingRight: '24px',
                        marginTop: '16px',
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Background Option
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FillOptions
                          disableLabel
                          disableTextColor
                          backgroundColorAlphaRequired
                          backgroundImageFieldName="uploadedIconUrl"
                          backgroundTypeOptions={BACKGROUND_TYPE_OPTIONS}
                          imageUploaderProps={{
                            acceptedFiles: {
                              'image/png': ['.png'],
                              'image/jpeg': ['.jpg', '.jpeg'],
                            },
                            fileInfo:
                              'Supported files includes jpeg and png. File size should be smaller than 1MB. Image dimensions must be at least 750px by 444px.',
                            minWidth: 750,
                            minHeight: 444,
                            minFileSize: 0,
                            maxFileSize: 1000000,
                          }}
                          backgroundColor={values.backgroundColor}
                          onImageUpdated={(imageData) => {
                            form.change('uploadedIconUrl', {
                              file: imageData.file,
                              url: imageData.url,
                            });
                          }}
                          onRadioChange={(newValue) => {
                            form.change('backgroundType', newValue);
                          }}
                          initialOption={values.backgroundType}
                        />
                      </Grid>
                    </Grid>
                    <Condition when="langES" is={true}>
                      <Grid
                        container
                        sx={{
                          marginLeft: '16px',
                          marginTop: '24px',
                          paddingRight: '24px',
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            Background Option (Spanish)
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FillOptions
                            disableLabel
                            disableTextColor
                            backgroundColorAlphaRequired
                            backgroundImageFieldName="uploadedIconUrlES"
                            backgroundTypeOptions={BACKGROUND_TYPE_OPTIONS}
                            imageUploaderProps={{
                              acceptedFiles: {
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpg', '.jpeg'],
                              },
                              fileInfo:
                                'Supported files includes jpeg and png. File size should be smaller than 1MB. Image dimensions must be at least 750px by 444px.',
                              minWidth: 750,
                              minHeight: 444,
                              minFileSize: 0,
                              maxFileSize: 1000000,
                            }}
                            backgroundColor={values.backgroundColor}
                            onImageUpdated={(imageData) => {
                              form.change('uploadedIconUrlES', {
                                file: imageData.file,
                                url: imageData.url,
                              });
                            }}
                            onRadioChange={(newValue) => {
                              form.change('backgroundType', newValue);
                            }}
                            initialOption={values.backgroundType}
                          />
                        </Grid>
                      </Grid>
                    </Condition>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        spacing={3}
                        sx={{ paddingRight: '24px' }}
                      >
                        <Condition when="backgroundType" is="image">
                          <Grid item>
                            <Typography variant="subtitle1">
                              Image Accessibility Options
                            </Typography>
                          </Grid>
                          <Grid container direction="column" xs spacing={2} item>
                            <Grid item>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                  label="Accessibility Description"
                                  name="accessibilityTextEN"
                                  variant="outlined"
                                  required={true}
                                />
                                <Box sx={{ marginLeft: 1 }}>
                                  <InfoTooltip title="Describe the image for accessibility purposes." />
                                </Box>
                              </Box>
                            </Grid>
                            <Condition when="langES" is={true}>
                              <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <TextField
                                    label="Accessibility Description (Spanish)"
                                    name="accessibilityTextES"
                                    variant="outlined"
                                    required={true}
                                  />
                                  <Box sx={{ marginLeft: 1 }}>
                                    <InfoTooltip title="Describe the image for accessibility purposes." />
                                  </Box>
                                </Box>
                              </Grid>
                            </Condition>
                          </Grid>
                        </Condition>
                        <Grid item>
                          <Typography variant="subtitle1">
                            Text options
                          </Typography>
                        </Grid>
                        <Grid container direction="column" xs spacing={2} item>
                          <Grid item>
                            <TextField
                              label="Card title"
                              name="titleEN"
                              variant="outlined"
                            />
                          </Grid>
                          <Condition when="langES" is={true}>
                            <Grid item>
                              <TextField
                                label="Card title (Spanish)"
                                name="titleES"
                                variant="outlined"
                              />
                            </Grid>
                          </Condition>
                        </Grid>
                        <Grid container direction="column" xs spacing={2} item>
                          <Grid item>
                            <TextField
                              label="Subtitle (optional)"
                              name="subtitleEN"
                              variant="outlined"
                            />
                          </Grid>
                          <Condition when="langES" is={true}>
                            <Grid item>
                              <TextField
                                label="Subtitle (Spanish)"
                                name="subtitleES"
                                variant="outlined"
                              />
                            </Grid>
                          </Condition>
                        </Grid>
                        <Grid container direction="column" xs spacing={2} item>
                          <Grid item>
                            <TextField
                              label="Button label (optional)"
                              name="buttonEN"
                              variant="outlined"
                            />
                          </Grid>
                          <Condition when="langES" is={true}>
                            <Grid item>
                              <TextField
                                label="Button label (Spanish)"
                                name="buttonES"
                                variant="outlined"
                              />
                            </Grid>
                          </Condition>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      xs
                      spacing={2}
                      item
                      sx={{
                        marginTop: '28px',
                        paddingRight: '8px',
                      }}
                    >
                      <Grid xs item>
                        <LinkDestination
                          addCustomLink={authorize(
                            SCOPES.BANNER_MESSAGE.HAS_CUSTOM_LINK,
                            {
                              data: {
                                args: {
                                  input: { actionLink: { type: 'custom' } },
                                },
                              },
                            },
                          )}
                          form={form}
                          hasCustomLink={!!initialBanner.custom}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs
                    item
                    container
                    spacing={2}
                    direction="column"
                    sx={{ position: 'relative' }}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item className={classes.previewContainer}>
                        <Box
                          sx={{
                            marginTop: '82px',
                            marginLeft: '8px',
                            position: 'relative',
                          }}
                        >
                          <FormSpy>
                            {({ values, errors }) => (
                              <BannerCard
                                darkTheme={values.theme === 'dark'}
                                backgroundImageAccessibilityText={values.accessibilityTextEN}
                                title={values.titleEN}
                                subtitle={values.subtitleEN}
                                buttonLabel={values.buttonEN}
                                backgroundColor={values.backgroundColor}
                                backgroundImage={
                                  values.backgroundType === 'image'
                                  && !!values.uploadedIconUrl
                                    ? values.uploadedIconUrl.url
                                    : null
                                }
                              />
                            )}
                          </FormSpy>
                        </Box>
                        <Box className={classes.extraContent}>
                          Dashboard content will appear below banner
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  ;
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
      </Box>
    </Box>
  );
};

Banner.propTypes = {
  media: PropTypes.object,
  networkId: PropTypes.string,
};

Banner.defaultProps = {
  networkId: '',
};

export default Banner;
