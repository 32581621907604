import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Asterisk from 'components/asterisk/asterisk';
import InfoPanel from 'components/infoPanel/infoPanel';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import GozioSelect from 'components/selector/selector';
import TakeOverModal from 'components/takeOverModal/takeOverModal';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { getFormLabel } from 'helpers/form-util';
import { capitalize } from 'helpers/lang-util';
import { DRAFT_WORKSPACE, LIVE_WORKSPACE } from 'helpers/workspace-util';
import {
  useCreateNetworkChannel,
  useUpdateNetworkChannel,
} from 'hooks/dataHooks/useNetworkChannelsAndContentProgress';
import useToast from 'hooks/useToast';
import useWindowSize from 'hooks/useWindowSize';
import { useWorkspace } from 'hooks/useWorkspace';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

const channelSchema = Yup.object().shape({
  name: Yup.string().required('Channel Name is required'),
  purpose: Yup.string().required('Channel Purpose is required'),
  channelType: Yup.string().required('Channel Type is required'),
  dataVersions: Yup.array().required('Data Version is required'),
  internal: Yup.bool().required(),
  isDefault: Yup.bool().required(),
  workspace: Yup.string().required(),
});

const archiveChannelSchema = Yup.object().shape({
  archivedReason: Yup.string().required('Archival Reason is required'),
});

const validate = makeValidate(channelSchema);
const validateArchive = makeValidate(archiveChannelSchema);

const buildStyles = ({ theme }) => ({
  modalContent: { margin: '80px' },
  modalPaper: {
    boxShadow: `0 2px 4px 0 ${colorWithAlpha(theme.palette.grey[600], 0.4)}`,
    overflowY: 'auto',
    position: 'relative',
  },
  modalAction: {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    bottom: 0,
    height: '70px',
    position: 'sticky',
    width: '686px',
    zIndex: 999,
  },
  restoreAction: {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    bottom: 0,
    height: '70px',
    position: 'sticky',
    width: '686px',
    zIndex: 999,

    '& > button:last-child': {
      position: 'absolute',
      right: '16px',
    },
  },
  heading: {
    fontSize: '28px',
    lineHeight: '39px',
    textAlign: 'center',
  },
  describe: {
    marginTop: '4px',
    textAlign: 'center',
  },
  bodyText: {
    color: theme.palette.grey[600],
    display: 'inline-block',
    fontWeight: 'normal',
  },
  fieldRow: {
    marginTop: '24px',
  },
});

const AddOrEditDataChannelModal = ({
                                     existingChannel,
                                     channels,
                                     networkDataVersions,
                                     handleClose,
                                     handleConfirm,
                                     workspaceData,
                                   }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const size = useWindowSize();
  const { activeNetwork } = useContext(FlamingoContext);
  const { toastNotificationErrorHook } = useToast();
  const { workspace } = useWorkspace();
  const [createNetworkChannel] = useCreateNetworkChannel();
  const [updateNetworkChannel] = useUpdateNetworkChannel();

  const existingDefaultChannelNameMap = {};
  const existingChannels = channels?.active?.map((channel) => {
    if (channel.isDefault) {
      existingDefaultChannelNameMap[channel.packagerDataSpec?.[0].clientType]
        = { id: channel.id, name: channel.name };
    }
    return {
      label: channel.name,
      id: channel.id,
    };
  });

  const [initialValues] = useState({
    name: existingChannel?.label ?? null,
    purpose: existingChannel?.purpose ?? null,
    internal: existingChannel?.internal ?? workspace === DRAFT_WORKSPACE,
    isDefault: existingChannel?.isDefault ?? false,
    channelType: existingChannel?.packagerDataSpec?.[0].clientType ?? null,
    dataVersions:
      existingChannel?.packagerDataSpec?.map(({ version }) => version) ?? [],
    workspace: existingChannel?.workspaces?.[0].name ?? workspace,
  });
  const [isExistingCopy, setIsExistingCopy] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const isArchived = existingChannel && !existingChannel.enabled;

  const channelDataMap = {};
  ['kiosk', 'mobile', 'sdk'].forEach((key) => {
    if (
      networkDataVersions?.[`${key}Enabled`]
      && networkDataVersions[`${key}DataVersions`]?.length > 0
    ) {
      channelDataMap[key] = [...networkDataVersions[`${key}DataVersions`]].sort(
        (a, b) => b - a,
      );
    }
  });

  const channelTypes = Object.keys(channelDataMap).map((key) => ({
    id: key,
    label: key === 'sdk' ? 'SDK' : capitalize(key),
  }));

  const onSubmit = async (values) => {
    const hasDuplicateName
      = channels?.active.find(
        (channel) => existingChannel?.id !== channel.id && channel.name === values.name,
      )
      || channels?.archived.find(
        (channel) => existingChannel?.id !== channel.id && channel.name === values.name,
      );
    if (hasDuplicateName) {
      toastNotificationErrorHook(
        'You cannot create a channel with a duplicate channel name.',
      );
      return;
    }

    const mutationList = [];
    if (existingChannel) {
      mutationList.push({
        networkChannelId: existingChannel.id,
        input: {
          internal: values.internal,
          isDefault: values.isDefault,
          label: values.name,
          packagerDataSpec: values.dataVersions.map((dataVersion) => ({
            clientType: values.channelType,
            version: dataVersion,
          })),
          purpose: values.purpose,
        },
      });
    } else {
      mutationList.push({
        input: {
          enabled: true,
          internal: values.internal,
          isDefault: values.isDefault,
          label: values.name,
          packagerDataSpec: values.dataVersions.map((dataVersion) => ({
            clientType: values.channelType,
            version: dataVersion,
          })),
          purpose: values.purpose,
          workspaces: [workspaceData[workspace]],
        },
      });
    }

    if (values.isDefault) {
      const existingDefaultChannels = channels?.active?.filter(
        (channel) => existingChannel?.id !== channel.id
          && channel.isDefault
          && channel.packagerDataSpec?.[0].clientType === values.channelType,
      );
      if (existingDefaultChannels?.length > 0) {
        mutationList.push(
          ...existingDefaultChannels.map((existingDefaultChannel) => ({
            networkChannelId: existingDefaultChannel.id,
            input: {
              isDefault: false,
            },
          })),
        );
      }
    }

    await Promise.all(
      mutationList.map((variables) => variables.networkChannelId
          ? updateNetworkChannel({ variables })
          : createNetworkChannel({ variables }),
      ),
    );

    handleConfirm();
  };

  const onArchive = async (values) => {
    await updateNetworkChannel({
      variables: {
        networkChannelId: existingChannel.id,
        input: {
          archivedReason: values.archivedReason,
          enabled: false,
        },
      },
    });
    setShowArchiveModal(false);
    handleClose();
  };

  const onRestore = async (values) => {
    await updateNetworkChannel({
      variables: {
        networkChannelId: existingChannel.id,
        input: {
          enabled: true,
        },
      },
    });
    handleClose(channels?.archived?.length <= 1);
  };

  const renderContent = (form) => (
    <Grid sx={{ padding: '80px 88px 32px 88px' }} container>
      <Grid item xs={12}>
        <Typography variant="h1" sx={styles.heading}>
          {existingChannel
            ? isArchived
              ? 'Archived Channel'
              : 'Edit Existing Channel'
            : 'Add New Channel'}
        </Typography>
      </Grid>
      {!existingChannel && (
        <>
          <Grid item xs={12} sx={styles.fieldRow}>
            <Asterisk />{' '}
            <Typography sx={styles.bodyText} variant="body1">
              Is this a copy of an existing channel?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              onChange={(e) => setIsExistingCopy(e.target.value === 'yes')}
              row={true}
              value={isExistingCopy ? 'yes' : 'no'}
            >
              <FormControlLabel
                control={<Radio value="no" color="primary" />}
                label="No"
                disabled={isArchived}
              />
              <FormControlLabel
                control={<Radio value="yes" color="primary" />}
                label="Yes"
                disabled={isArchived}
              />
            </RadioGroup>
          </Grid>
        </>
      )}
      {isExistingCopy && (
        <Grid item xs={12} sx={styles.fieldRow}>
          <Field name="existingChannel" subscription={{ value: true }}>
            {({ input: { value, onChange }, meta }) => (
              <GozioSelect
                label={getFormLabel('Existing Channel', true)}
                input={{
                  value,
                  onChange: (channelId) => {
                    const channel = channels?.active.find(
                      (channel) => channel.id === channelId,
                    );
                    if (channel) {
                      form.change(
                        'channelType',
                        channel.clientType?.toLowerCase(),
                      );
                      form.change(
                        'dataVersions',
                        channel.packagerDataSpec?.map(({ version }) => version),
                      );
                      form.change('internal', channel.internal);
                      form.change('isDefault', channel.isDefault);
                    }
                    onChange(channelId);
                  },
                }}
                options={existingChannels}
              />
            )}
          </Field>
        </Grid>
      )}
      <Grid item xs={12} sx={styles.fieldRow}>
        <Asterisk />{' '}
        <Typography sx={styles.bodyText} variant="body1">
          Is this channel internally or externally facing?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field name="internal">
          {({ input: { value, onChange } }) => (
            <RadioGroup
              onChange={(e) => {
                onChange(e.target.value === 'yes');
              }}
              row={true}
              value={value ? 'yes' : 'no'}
            >
              <FormControlLabel
                control={<Radio value="yes" color="primary" />}
                label="Internal"
                disabled={isArchived}
              />
              <FormControlLabel
                control={<Radio value="no" color="primary" />}
                label="External"
                disabled={isArchived}
              />
            </RadioGroup>
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sx={{ ...styles.fieldRow, display: 'flex' }}>
        <Asterisk />{' '}
        <Typography sx={styles.bodyText} variant="body1">
          Set channel as default?
        </Typography>
        <InfoTooltip
          title="Changes to default channels are public and are promoted to mobile app end users."
          sx={{ marginLeft: '8px' }}
        />
      </Grid>
      {existingChannel?.isDefault && (
        <Grid item xs={12}>
          <InfoPanel
            title="This channel is currently set as the default. To remove its default status, you need to edit another channel and set it as the default."
            sx={{ marginTop: '8px' }}
          />
        </Grid>
      )}
      {workspace === DRAFT_WORKSPACE && (
        <Grid item xs={12}>
          <InfoPanel
            title="Default Channels cannot exist within the Draft Workspace."
            maxLines={null}
            sx={{ marginTop: '8px' }}
          />
        </Grid>
      )}
      {existingDefaultChannelNameMap[
          form.getFieldState('channelType')?.value
          ]
        && existingDefaultChannelNameMap[form.getFieldState('channelType')?.value]
          .id !== existingChannel?.id
        && form.getFieldState('isDefault')?.value && (
          <Grid item xs={12}>
            <InfoPanel
              title={`${
                existingDefaultChannelNameMap[
                  form.getFieldState('channelType')?.value
                  ].name
              } is currently set to default. Selecting the current channel as the new default will remove ${
                existingDefaultChannelNameMap[
                  form.getFieldState('channelType')?.value
                  ].name
              }'s default status.`}
              maxLines={null}
              sx={{ marginTop: '8px' }}
            />
          </Grid>
        )}
      <Grid item xs={12}>
        <Field name="isDefault">
          {({ input: { value, onChange } }) => (
            <RadioGroup
              onChange={(e) => {
                onChange(e.target.value === 'yes');
              }}
              row={true}
              value={value ? 'yes' : 'no'}
            >
              <FormControlLabel
                control={<Radio value="no" color="primary" />}
                label="No"
                disabled={
                  workspace === DRAFT_WORKSPACE
                  || isArchived
                  || existingChannel?.isDefault
                  || (!existingChannel
                    && !form.getFieldState('channelType')?.value)
                }
              />
              <FormControlLabel
                control={<Radio value="yes" color="primary" />}
                label="Yes"
                disabled={
                  workspace === DRAFT_WORKSPACE
                  || isArchived
                  || existingChannel?.isDefault
                  || (!existingChannel
                    && !form.getFieldState('channelType')?.value)
                }
              />
            </RadioGroup>
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <Typography variant="subtitle1">Channel Info</Typography>
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <FinalTextField
          label={getFormLabel('Workspace', true)}
          name="workspace"
          value={workspace === 'live' ? 'Live Workspace' : 'Draft Workspace'}
          variant="outlined"
          fullWidth
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <FinalTextField
          label={getFormLabel('Channel Name', true)}
          name="name"
          variant="outlined"
          autoComplete="off"
          fullWidth
          disabled={isArchived}
        />
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <FinalTextField
          label={getFormLabel('Channel Purpose', true)}
          name="purpose"
          variant="outlined"
          autoComplete="off"
          multiline={true}
          minRows={5}
          maxRows={5}
          fullWidth
          disabled={isArchived}
        />
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <Field name="channelType" subscription={{ value: true }}>
          {({ input: { value, onChange }, meta }) => (
            <GozioSelect
              label={getFormLabel('Channel Type', true)}
              input={{
                value,
                onChange: (channelType) => {
                  if (!existingChannel) {
                    if (workspace === LIVE_WORKSPACE) {
                      form.change(
                        'isDefault',
                        !existingDefaultChannelNameMap[channelType],
                      );
                    }

                    form.change('dataVersions', []);
                  }
                  onChange(channelType);
                },
              }}
              options={channelTypes}
              disabled={!!existingChannel}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sx={styles.fieldRow}>
        <Field name="dataVersions" subscription={{ value: true }}>
          {({ input }) => (
            <GozioSelect
              label={getFormLabel('Data Versions', true)}
              disabled={!form.getFieldState('channelType')?.value || isArchived}
              input={input}
              multiple={true}
              options={
                channelDataMap[form.getFieldState('channelType')?.value]?.map(
                  (dataVersion) => ({
                    id: dataVersion,
                    label: `v${dataVersion}`,
                  }),
                ) || []
              }
              renderValue={(selected) => [...selected ?? []]
                  .sort((a, b) => b - a)
                  .map((v) => `v${v}`)
                  ?.join(', ')
              }
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );

  const archiveModalContent = useMemo(
    () => (
      <Grid sx={{ padding: '80px 88px' }} container>
        <Grid item xs={12}>
          <Typography variant="h1" sx={styles.heading}>
            Archive Channel
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: '8px', marginTop: '8px', textAlign: 'center' }}
          >
            Below, tell us why you’re archiving this channel.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={styles.fieldRow}>
          <FinalTextField
            label={getFormLabel('Archival Reason', true)}
            name="archivedReason"
            variant="outlined"
            autoComplete="off"
            multiline={true}
            minRows={5}
            maxRows={5}
            fullWidth
          />
        </Grid>
      </Grid>
    ),
    [styles],
  );

  const modalProps = {
    networkName: activeNetwork?.humanName,
    logo: activeNetwork?.logo?.url,
    header: activeNetwork?.humanName,
    contentSx: styles.modalContent,
    modalPaperSx: { ...styles.modalPaper, maxHeight: `${size.height - 230}px` },
  };

  if (showArchiveModal) {
    return (
      <TakeOverModal
        formParams={{
          onSubmit: onArchive,
          initialValues: {
            archivedReason: null,
          },
          validate: validateArchive,
        }}
        {...modalProps}
        content={() => archiveModalContent}
        confirmText="Save"
        confirmType="submit"
        handleClose={() => {
          setShowArchiveModal(false);
          handleClose();
        }}
      />
    );
  }

  if (isArchived) {
    return (
      <TakeOverModal
        formParams={{
          onSubmit: onRestore,
          initialValues,
        }}
        {...modalProps}
        actionSx={styles.restoreAction}
        content={({ form }) => renderContent(form)}
        cancelText="Restore Channel"
        customButtonText="Close"
        customButtonVariant="contained"
        disableConfirm={true}
        disableCustomButton={false}
        handleClose={onRestore}
        handleCustomButton={handleClose}
      />
    );
  }

  return (
    <TakeOverModal
      formParams={{
        onSubmit,
        initialValues,
        validate,
      }}
      {...modalProps}
      actionSx={styles.modalAction}
      content={({ form }) => renderContent(form)}
      customButtonText={existingChannel ? 'Archive' : null}
      confirmText={existingChannel ? 'Save' : 'Save & Add'}
      confirmType="submit"
      disableCustomButton={!existingChannel}
      // Users cannot archive a default channel. They will need to select a new default/create a new channel first.
      greyOutCustomButton={(formVals) => !existingChannel || formVals.isDefault}
      handleCustomButton={() => setShowArchiveModal(true)}
      handleClose={handleClose}
    />
  );
};
AddOrEditDataChannelModal.propTypes = {
  existingChannel: PropTypes.object,
  channels: PropTypes.object.isRequired,
  networkDataVersions: PropTypes.shape({
    kioskEnabled: PropTypes.bool,
    mobileEnabled: PropTypes.bool,
    webEnabled: PropTypes.bool,
    sdkEnabled: PropTypes.bool,
    kioskDataVersions: PropTypes.arrayOf(PropTypes.number),
    mobileDataVersions: PropTypes.arrayOf(PropTypes.number),
    sdkDataVersions: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  workspaceData: PropTypes.shape({
    live: PropTypes.string.isRequired,
    draft: PropTypes.string.isRequired,
  }).isRequired,
};

AddOrEditDataChannelModal.defaultProps = {
  existingChannel: null,
};

export default React.memo(AddOrEditDataChannelModal);
