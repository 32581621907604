import { Box } from '@mui/material';
import { FlamingoContext } from 'contexts/flamingo';
import dayjs from 'dayjs';
import { DATE_FORMAT, retrieveAnalyticsFilter } from 'helpers/analytics-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useAnalytics } from 'hooks/dataHooks/useAnalytics';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AnalyticsFilters from './containers/analyticsFilters';
import AnalyticsOverviewCards from './containers/analyticsOverviewCards';

export const AnalyticsPage = () => {
  const { activeNetwork } = useContext(FlamingoContext);
  const networkId = activeNetwork.id;
  const { filter, rangeStart, rangeEnd } = retrieveAnalyticsFilter();
  const [dateRangeKey, setDateRangeKey] = useState(null);
  const [dateRangeText, setDateRangeText] = useState(null);

  const { data: newAppUsersData, fetch: fetchNewAppUsersData } = useAnalytics({
    networkId,
    endpoint: 'newusers',
  });
  const { data: sessionsData, fetch: fetchSessionsData } = useAnalytics({
    networkId,
    endpoint: 'sessions',
  });
  const { data: navigationEventsData, fetch: fetchNavigationEventsData }
    = useAnalytics({
      networkId,
      endpoint: 'navigationevents',
    });
  const { data: activeUsersData, fetch: fetchActiveUsersData } = useAnalytics({
    networkId,
    endpoint: 'activeusers',
  });
  const { data: deviceTypesData, fetch: fetchDeviceTypesData } = useAnalytics({
    networkId,
    endpoint: 'devicetypes',
  });
  const { data: appUsageData, fetch: fetchAppUsageData } = useAnalytics({
    networkId,
    endpoint: 'usage',
  });

  const userRole = useCheckRole();
  const hasPermission = [
    USER_ROLES.UBER_ADMIN,
    USER_ROLES.NETWORK_ADMIN,
  ].includes(userRole);

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  return (
    <LoggedinLayout supportedWorkspace={LIVE_WORKSPACE}>
      <FlamingoPage
        pageName={
          <Box>
            Analytics Overview{' '}
          </Box>
        }
        headerButtons={
          <AnalyticsFilters
            value={filter}
            start={rangeStart}
            end={rangeEnd}
            onChange={async ({ start, end, points, value }) => {
              if (start === null) {
                start = dayjs(activeNetwork.goLiveMobile).format('YYYY-MM-DD');
              }
              if (dateRangeKey !== value || value === 'custom') {
                const dateRangeParams = { start, end };
                if (points) {
                  dateRangeParams.points = points;
                }
                setDateRangeKey(value);
                if (value === 'custom') {
                  setDateRangeText(
                    `${dayjs(start).format(DATE_FORMAT)} - ${dayjs(end).format(
                      DATE_FORMAT,
                    )}`,
                  );
                } else {
                  setDateRangeText(null);
                }
                fetchNewAppUsersData(dateRangeParams);
                fetchActiveUsersData(dateRangeParams);
                fetchSessionsData(dateRangeParams);
                fetchNavigationEventsData(dateRangeParams);
                fetchDeviceTypesData(dateRangeParams);
                fetchAppUsageData(dateRangeParams);
              }
            }}
          />
        }
      >
        <AnalyticsOverviewCards
          data={{
            activeUsersData,
            appUsageData,
            deviceTypesData,
            navigationEventsData,
            newAppUsersData,
            sessionsData,
          }}
          dateRangeKey={dateRangeKey}
          dateRangeText={dateRangeText}
          dateRange={{
            end: rangeEnd,
            start: rangeStart,
          }}
          networkId={networkId}
        />
      </FlamingoPage>
    </LoggedinLayout>
  );
};

export default React.memo(AnalyticsPage);
