import React, { useCallback, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import TextField from 'components/textField/textField';
import GenericStepperModal from 'components/genericModal/genericStepperModal';
import Asterisk from 'components/asterisk/asterisk';
import ImageUploader from 'components/imageUploader/imageUploader';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from '../../../../../helpers/lang-util';
import LinkDestination from 'components/forms/linkDestination';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { useTheme } from '@mui/material/styles';
import { getExternalAppFormValidationSchema, getWebsiteValidationSchema } from '../../../../../helpers/form-util';
import { SUPPORTED_LINK_TYPES } from './editCardHelper';
import InfoTooltip from 'components/infoTooltip/infoTooltip';

const dashboardCardScheme = (languages) => Yup.object().shape(
  buildLanguageSchema(
    {
      langEs: Yup.boolean(),
      navlinkDisplay: Yup.string(),
      titleEn: Yup.string()
        .required('Card Title is required')
        .max(24, 'Card Title must be 24 characters or less'),
      titleEs: Yup.string()
        .max(24, 'Card Title (Spanish) must be 24 characters or less'),
      icon: Yup.mixed()
        .when('navlinkDisplay', {
          is: 'icon',
          then: (schema) => schema.required('Icon is required'),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      iconUrl: Yup.mixed()
        .when('navlinkDisplay', {
          is: 'image',
          then: (schema) => schema.required('Image is required'),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      accessibilityTextEn: Yup.string()
        .max(250, 'Accessibility Description must be 250 characters or less'),
      accessibilityTextEs: Yup.string()
        .max(250, 'Accessibility Description (Spanish) must be 250 characters or less'),
      linkType: Yup.string().required('Type is required'),
      categories: Yup.array().when('linkType', {
        is: 'category',
        then: (schema) => schema.min(1).required('Selection is required'),
      }),
      // in_app
      inAppDest: Yup.string().when('linkType', {
        is: 'in_app',
        then: (schema) => schema.required('Selection is required'),
      }),
      // List
      listDest: Yup.string().when('linkType', {
        is: 'list',
        then: (schema) => schema.required('Selection is required'),
      }),
      ...getExternalAppFormValidationSchema,
      ...getWebsiteValidationSchema,
      custom: Yup.string().when('linkType', {
        is: 'custom',
        then: (schema) => schema.required('Custom value is required'),
      }),
    },
    languages,
    {
      titleEn: LANGUAGE_CODES.ENGLISH,
      titleEs: LANGUAGE_CODES.SPANISH,
      accessibilityTextEn: LANGUAGE_CODES.ENGLISH,
      accessibilityTextEs: LANGUAGE_CODES.SPANISH,
      webUrlEn: LANGUAGE_CODES.ENGLISH,
      webUrlEs: LANGUAGE_CODES.SPANISH,
    },
  ),
);

const validate = (languages) => makeValidate(dashboardCardScheme(languages));

const buildStyles = ({ theme }) => ({
  modal: {
    maxHeight: '472px',
    '& .MuiDialogContent-root': {
      height: '100%',
    },
  },
  modalContent: {
    marginTop: '16px',
    marginBottom: '16px',
    height: '400px',
    textAlign: 'left',
  },
  subtitle: {
    marginBottom: '32px',
    textAlign: 'center',
  },
  charCount: {
    color: theme.palette.grey[400],
  },
  charCountError: {
    color: theme.palette.error.main,
  },
});

const NavLinkCardStepperModal = ({
                                   open,
                                   onClose,
                                   onSave,
                                   currentValues,
                                   navlinkDisplay,
                                   languages = [],
                                 }) => {
  const [formValues, setFormValues] = useState(null);
  const hasSpanishLang = hasSpanish(languages);
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const getRequiredFields = useCallback((step) => {
    const requiredFields = [];
    if (step === 1) {
      requiredFields.push('titleEn');
      if (navlinkDisplay === 'image') {
        requiredFields.push('iconUrl');
      } else {
        requiredFields.push('icon');
      }
    }
    return requiredFields;
  }, [navlinkDisplay]);

  const steps = useMemo(
    () => [
      {
        title: 'Navlink Details',
        requiredFields: getRequiredFields(1),
        content: (
          <Box sx={styles.modalContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name="titleEn">
                  {({ input, meta }) => (
                    <TextField
                      label={<span><Asterisk/>Navlink Title</span>}
                      charLimit={24}
                      helperText={meta.touched ? meta.error : ''}
                      error={meta.touched && !!meta.error}
                      placeholder="Card Title"
                      value={input.value}
                      onChange={input.onChange}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              {hasSpanishLang && (
                <Grid item xs={12}>
                  <Field name="titleEs">
                    {({ input, meta }) => (
                      <TextField
                        label={<span>Navlink Title (Spanish)</span>}
                        charLimit={24}
                        helperText={meta.touched ? meta.error : ''}
                        error={meta.touched && !!meta.error}
                        placeholder="Card Title"
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={12}>
                {navlinkDisplay === 'icon'
                  && <Field
                    name={'icon'}
                    subscription={{ value: true, active: true, error: true, touched: true }}
                  >
                    {({ input }) => (
                      <ImageUploader
                        fileInfo="Icon must be at least 320x320 and less than 3 megabytes"
                        image={input.value ? input.value : null}
                        minWidth={320}
                        minHeight={320}
                        maxFileSize={3000000}
                        onImageUpdated={(imageData) => {
                          input.onChange(imageData);
                        }}
                        activeDragPadding={12}
                        allowDelete={false}
                        display="stacked"
                        showImage={true}
                        isIcon={true}
                        showImageName={false}
                      />
                    )}
                  </Field>
                }
                {navlinkDisplay === 'image'
                  && <Field
                    name={'iconUrl'}
                    subscription={{ value: true, active: true, error: true, touched: true }}
                  >
                    {({ input }) => (
                      <ImageUploader
                        fileInfo="Image must be at least 320x320 and less than 3 megabytes"
                        image={input.value ? input.value : null}
                        minWidth={320}
                        minHeight={320}
                        maxFileSize={3000000}
                        onImageUpdated={(imageData) => {
                          input.onChange(imageData);
                        }}
                        activeDragPadding={12}
                        allowDelete={false}
                        display="stacked"
                        showImage={true}
                        showImageName={false}
                      />
                    )}
                  </Field>
                }
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1">
                    Image Accessibility Options
                  </Typography>
                  <Box sx={{ marginLeft: 1 }}>
                    <InfoTooltip title="Describe the image for accessibility purposes."/>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Field name="accessibilityTextEn">
                  {({ input, meta }) => (
                    <TextField
                      label={<span>Accessibility Description</span>}
                      charLimit={250}
                      helperText={meta.touched ? meta.error : ''}
                      error={meta.touched && !!meta.error}
                      placeholder="Accessibility Description"
                      value={input.value}
                      onChange={input.onChange}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              {hasSpanishLang && (
                <Grid item xs={12}>
                  <Field name="accessibilityTextEs">
                    {({ input, meta }) => (
                      <TextField
                        label={<span>Accessibility Description (Spanish)</span>}
                        charLimit={250}
                        helperText={meta.touched ? meta.error : ''}
                        error={meta.touched && !!meta.error}
                        placeholder="Accessibility Description"
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Grid>
          </Box>
        ),
      },
      {
        title: 'Select QuickLink Type',
        requiredFields: getRequiredFields(2),
        content: (
          <Box sx={styles.modalContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LinkDestination
                  formValues={formValues ?? currentValues}
                  hasCustomLink={!!(formValues ?? currentValues)?.custom}
                  supportedLinkTypes={SUPPORTED_LINK_TYPES}
                />
              </Grid>
            </Grid>
          </Box>
        ),
      },
    ],
    [currentValues, formValues, getRequiredFields, hasSpanishLang, navlinkDisplay, styles.modalContent],
  );

  const formParams = useMemo(
    () => ({
      initialValues: {
        ...currentValues,
        langES: hasSpanishLang,
        navlinkDisplay,
      },
      onSubmit: onSave,
      validate: validate(languages),
    }),
    [currentValues, hasSpanishLang, navlinkDisplay, onSave, languages],
  );

  return (
    <GenericStepperModal
      open={open}
      onClose={onClose}
      steps={steps}
      formParams={formParams}
      onChange={(values) => setFormValues(values)}
      canGoBack={() => true}
      canProceed={() => true}
      confirmText="Save"
    />
  );
};

NavLinkCardStepperModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  currentValues: PropTypes.object.isRequired,
  navlinkDisplay: PropTypes.string.isRequired,
};

export default NavLinkCardStepperModal;
