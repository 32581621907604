import GozioRadioGroup from './radioGroup';
import { Field } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';

const yesNoOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

const YesNoRadioGroup = ({
                           fieldName,
                           alignment,
                         }) => (
  <Field name={fieldName}>
    {({ input }) => (
      <GozioRadioGroup
        value={input.value}
        onChange={
          (selectedOption) => {
            if (selectedOption === 'true') {
              input.onChange(true);
            } else {
              input.onChange(false);
            }
          }
        }
        alignment={alignment}
        options={yesNoOptions}
      />
    )}
  </Field>
);

YesNoRadioGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  alignment: PropTypes.string,
};

YesNoRadioGroup.defaultProps = {
  alignment: 'horizontal',
};

export default YesNoRadioGroup;
