import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_TEXT_COLOR,
  dndHover,
  getLanguageLabel,
  isGroupIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Card from '../cardComponent/card';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dragging: {
    paddingBottom: '0 !important',
  },
});

const CycleCardLayout = ({
                           id,
                           index,
                           groupTitle,
                           showGroupTitle,
                           groupTitleTextColor,
                           showButtons,
                           textColor,
                           backgroundColor,
                           visibilityRule,
                           validation,
                           cards,
                           preview,
                           disabled,
                           selected,
                           hovered,
                           moveCard,
                           onCardGroupClick,
                           onDrag,
                           onHover,
                           canDragAndDrop,
                         }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        groupTitle,
        showGroupTitle,
        groupTitleTextColor,
        showButtons,
        textColor,
        backgroundColor,
      },
      cardType: CycleCardLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'Cycle Card' : ''}>
      <Grid
        container
        direction="row"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...preview && styles.preview,
          ...isDragging && styles.dragging,
          opacity,
        }}
      >
        <Grid item xs sx={styles.card}>
          <Card
            size="medium"
            title={groupTitle ? getLanguageLabel(groupTitle) : 'Cycle Card'}
            backgroundColor={DEFAULT_BACKGROUND_COLOR}
            textColor={DEFAULT_TEXT_COLOR}
            clickHandler={() => onCardGroupClick({ cardGroupId: id })}
            incompleteForPackaging={!!preview && isGroupIncompleteForPackaging({
              groupTitle,
              showButtons,
              textColor,
              backgroundColor,
              cards,
              visibilityRule,
              validation,
            })}
            disabled={disabled}
            selected={selected}
            standalone={true}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

CycleCardLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  groupTitle: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      lang: PropTypes.string,
    }),
  ),
  showGroupTitle: PropTypes.bool,
  groupTitleTextColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  cards: PropTypes.array,
  visibilityRule: PropTypes.any,
  validation: PropTypes.object,
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardGroupClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

CycleCardLayout.defaultProps = {
  groupTitle: [
    {
      lang: 'en',
      label: 'Cycle Card Group',
    },
  ],
  showGroupTitle: true,
  groupTitleTextColor: DEFAULT_BACKGROUND_COLOR,
  textColor: DEFAULT_TEXT_COLOR,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  cards: [],
  visibilityRule: null,
  validation: null,
  preview: false,
  disabled: false,
  selected: false,
  hovered: false,
  moveCard: () => {
  },
  onDrag: () => {
  },
  onCardGroupClick: () => {
  },
  canDragAndDrop: true,
};

export default CycleCardLayout;
