import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColorOptions from 'components/colorOptions/colorOptions';
import TabPanel from 'components/tabPanel/tabPanel';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import React from 'react';
import { Field } from 'react-final-form';
import GozioSelect from 'components/selector/selector';
import { getFormLabel } from '../../../../../helpers/form-util';
import InfoTooltip from 'components/infoTooltip/infoTooltip';

const HeaderUnderlayTabPanel = (props) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });
  const cardGroupOptions = Array.from({ length: props.cardGroups.length + 1 }, (_, index) => (
    {
      id: index.toString(),
      label: index.toString(),
    }
  ));

  return (
    <TabPanel sx={{ ...styles.tabPanel, padding: '24px 24px 0' }} value={0} index={0}>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12} marginTop={'16px'}>
          <Grid item xs={12}>
            <Typography sx={styles.displayHeading} variant="subtitle2">
              Header Underlay Color
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Field name="underlayHeaderColor">
                {({ input: { value, onChange } }) => (
                  <ColorOptions
                    initialColor={value.toString()}
                    formControl={true}
                    getColor={(color) => {
                      onChange(color);
                    }}
                    label="Underlay Header Color"
                    required={true}
                    inputProps={{
                      'data-test': 'editCardTextColor',
                    }}
                  />
                )}
              </Field>
            </Box>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Typography sx={styles.displayHeading} variant="subtitle2">
              Text Color
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Field name="underlayTextColor">
                {({ input: { value, onChange } }) => (
                  <ColorOptions
                    initialColor={value.toString()}
                    formControl={true}
                    getColor={(color) => {
                      onChange(color);
                    }}
                    label="Text Color"
                    required={true}
                    inputProps={{
                      'data-test': 'editCardBackgroundColor',
                    }}
                  />
                )}
              </Field>
              <Box sx={{ marginLeft: 1 }}>
                <InfoTooltip
                  title="This is the color of the text when text falls directly on top of the header background."/>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Typography sx={styles.displayHeading} variant="subtitle2">
              How many dashboard cards would you like to include?
            </Typography>
          </Grid>
          <Grid item xs={6} marginTop={'16px'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Field name="numberOfCards">
                {({ input }) => (
                  <GozioSelect
                    input={{
                      value: input.value.toString(), onChange: (value) => {
                        input.onChange(Number(value));
                      },
                    }}
                    label={getFormLabel('Number of Cards', true)}
                    options={cardGroupOptions}
                    renderValue={(selected) => (
                      <Box sx={styles.selectMenuValue}>
                        {selected}
                      </Box>
                    )}
                    fullWidth={true}
                  />
                )}
              </Field>
              <Box sx={{ marginLeft: 1 }}>
                <InfoTooltip
                  title="This sets the number of cards in the header. If set to 0, the header will not include any cards, only the banner if present."/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </TabPanel>
  );
};

export default HeaderUnderlayTabPanel;
