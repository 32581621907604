import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Asterisk from 'components/asterisk/asterisk';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import VisibilitySelector from 'components/selector/VisibilitySelector';
import { getFormLabel } from 'helpers/form-util';
import { TextField as FinalTextField } from 'mui-rff';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import React from 'react';
import { Field } from 'react-final-form';

const OpenPlatformTabPanel = () => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  return (
    <TabPanel sx={styles.tabPanel} value={0} index={0}>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <Typography sx={styles.displayHeading} variant="subtitle1">
                General
              </Typography>
            </Grid>
            <Grid item>
              <InfoTooltip
                sx={{ marginLeft: '16px', marginTop: '2px' }}
                placement="top"
                title="This is a placeholder card for your mobile application. Custom cards utilize client-specific logic that controls the look and feel of this card. Card title is not used in your mobile application."
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Field name="cardName">
            {({ input, meta }) => (
              <TextField
                label={
                  <span>
                    <Asterisk /> Title
                  </span>
                }
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                placeholder="Title"
                value={input.value}
                onChange={input.onChange}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Box>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Typography sx={styles.displayHeading} variant="subtitle1">
            Module Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FinalTextField
            label={getFormLabel('Module ID', true)}
            name="moduleId"
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <FinalTextField
            label={getFormLabel('Input for Module Data')}
            multiline
            name="moduleData"
            variant="outlined"
            minRows={5}
            maxRows={10}
            sx={{ fontFamily: 'monospace' }}
          />
        </Grid>
      </Box>
      <VisibilitySelector />
    </TabPanel>
  );
};

export default OpenPlatformTabPanel;
