import { useMutation, useQuery } from '@apollo/client';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import {
  CREATE_NETWORK_DASHBOARD_CARD,
  CREATE_NETWORK_DASHBOARD_CARD_GROUP,
  DELETE_NETWORK_DASHBOARD_CARD,
  DELETE_NETWORK_DASHBOARD_CARD_GROUP,
  FIND_NETWORK_DASHBOARD_CARDS,
  UPDATE_NETWORK_DASHBOARD,
  UPDATE_NETWORK_DASHBOARD_CARD,
  UPDATE_NETWORK_DASHBOARD_CARD_GROUP,
  UPDATE_NETWORK_DASHBOARD_CARD_MY_FAVORITES,
  UPDATE_NETWORK_DASHBOARD_CARD_MYCHART,
} from 'graphql/queries';

export const useFindNetworkDashboards = (networkId) => {
  const { handleError } = useHandleError('Dashboard');
  return useQuery(FIND_NETWORK_DASHBOARD_CARDS, {
    variables: { networkId },
    errorPolicy: 'all',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateNetworkDashboardCardGroup = (networkId) => {
  const { handleError } = useHandleError('Dashboard');
  return useMutation(CREATE_NETWORK_DASHBOARD_CARD_GROUP, {
    context: { headers: { network: networkId } },
    update: (cache, { data: { createNetworkDashboardCardGroup } }) => {
      try {
        cache.writeQuery({
          query: CREATE_NETWORK_DASHBOARD_CARD_GROUP,
          variables: { networkId },
          data: { createNetworkDashboardCardGroup },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [
      {
        query: FIND_NETWORK_DASHBOARD_CARDS,
        variables: { networkId },
      },
    ],
  });
};

export const useDeleteNetworkDashboardCardGroup = (networkId) => {
  const { handleError } = useHandleError('Dashboard');
  return useMutation(DELETE_NETWORK_DASHBOARD_CARD_GROUP, {
    context: { headers: { network: networkId } },
    update: (cache, { data: { deleteNetworkDashboardCardGroup } }) => {
      try {
        cache.writeQuery({
          query: DELETE_NETWORK_DASHBOARD_CARD_GROUP,
          variables: { input: deleteNetworkDashboardCardGroup },
          data: { deleteNetworkDashboardCardGroup },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [
      {
        query: FIND_NETWORK_DASHBOARD_CARDS,
        variables: { networkId },
      },
    ],
  });
};

export const useUpdateDashboardCardGroup = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(UPDATE_NETWORK_DASHBOARD_CARD_GROUP, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Your progress on the Dashboard has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => {
      handleError(graphQLErrors, networkError);
    },
  });
};

export const useUpdateNetworkDashboard = (networkId) => {
  const { handleError } = useHandleError('Dashboard');
  return useMutation(UPDATE_NETWORK_DASHBOARD, {
    context: { headers: { network: networkId } },
    update: (cache, { data: { updateNetworkDashboard } }) => {
      try {
        cache.writeQuery({
          query: UPDATE_NETWORK_DASHBOARD,
          variables: { input: updateNetworkDashboard },
          data: { updateNetworkDashboard },
        });
      } catch (error) {
        console.error(`Error writing to cache: ${error}`);
      }
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
    refetchQueries: [
      {
        query: FIND_NETWORK_DASHBOARD_CARDS,
        variables: { networkId },
      },
    ],
  });
};

export const useUpdateDashboardCard = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(UPDATE_NETWORK_DASHBOARD_CARD, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Your progress on the Dashboard has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useCreateDashboardCard = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(CREATE_NETWORK_DASHBOARD_CARD, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Dashboard card created successfully.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useDeleteDashboardCard = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(DELETE_NETWORK_DASHBOARD_CARD, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Dashboard card deleted successfully.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateDashboardMyChartCard = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(UPDATE_NETWORK_DASHBOARD_CARD_MYCHART, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Your progress on the Dashboard has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};

export const useUpdateDashboardMyFavoritesCard = () => {
  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('Dashboard');
  return useMutation(UPDATE_NETWORK_DASHBOARD_CARD_MY_FAVORITES, {
    onCompleted: () => {
      toastNotificationSuccessHook(
        'Your progress on the Dashboard has been saved.',
      );
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });
};
