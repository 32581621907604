import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Asterisk from 'components/asterisk/asterisk';
import { SCOPES } from 'components/authorization/authorize';
import ColorOptions from 'components/colorOptions/colorOptions';
import EmptySection from 'components/emptySection/emptySection';
import GenericFormModal from 'components/genericModal/genericFormModal';
import ImageUpLoader from 'components/imageUploader/imageUploader';
import GozioListItem from 'components/listItem/gozioListItem';
import TabPanel from 'components/tabPanel/tabPanel';
import VisibilitySelector from 'components/selector/VisibilitySelector';
import { FlamingoContext } from 'contexts/flamingo';
import { getFormLabel, parseByLang } from 'helpers/form-util';
import { buildLabelByLang, buildLanguageSchema, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import { makeValidate, TextField as FinalTextField } from 'mui-rff';
import ColorPalette from 'pages/gozio_colors';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { Field, useField } from 'react-final-form';
import * as Yup from 'yup';

const QUICK_LINKS_FIELDS = [
  {
    key: 'title',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'qlTitleEn',
    label: 'Title',
    required: true,
    charLimit: 40,
  },
  {
    key: 'title',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'qlTitleEs',
    label: 'Title (Spanish)',
    charLimit: 40,
  },
];

export const MyChartQuickLinksTabPanel = ({
  qlBackgroundColor,
  sx,
  formValues,
  form,
  index,
  languages = [],
  onChange: onFormChange,
  size,
  tabValue,
  qlTextColor,
}) => {
  useField('qlQuickLinks');
  const { authorize } = useContext(FlamingoContext);

  const validate = makeValidate(
    Yup.object().shape(
      buildLanguageSchema(
        {
          name: Yup.string().required('Name is required'),
          nameES: Yup.string(),
          iconUrl: Yup.string(),
          url: Yup.string().required('EHR URL is required'),
        },
        languages,
        { name: LANGUAGE_CODES.ENGLISH, nameES: LANGUAGE_CODES.SPANISH },
      ),
    ),
  );

  const quickLinksLength = formValues?.qlQuickLinks?.length || 0;

  const quickLinksEditPermission = authorize(SCOPES.MYCHART.UPDATE);

  const isUberAdmin = useCheckGozioAdmin();

  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const [uploadedIconData, setUploadedIconData] = useState(null);
  const [selectedQuickLink, setSelectedQuickLink] = useState(null);

  const handleShowQuickLinkModal = (link = null) => {
    setUploadedIconData(null);
    setSelectedQuickLink(link);
    setShowQuickLinkModal(true);
  };

  const handleEditQuickLinkClicked = useCallback(
    (id) => {
      handleShowQuickLinkModal({
        ...formValues.qlQuickLinks.find((link) => link.id === id),
      });
    },
    [formValues.qlQuickLinks],
  );

  const handleDeleteQuickLinkClicked = (index) => {
    let updatedQuickLinksData = [...formValues.qlQuickLinks];
    const found = updatedQuickLinksData.findIndex(
      (link) => link.index === index,
    );
    updatedQuickLinksData.splice(found, 1);
    updatedQuickLinksData = updatedQuickLinksData.map(
      ({ id, index, ...rest }, idx) => ({
        id: idx,
        index: idx,
        ...rest,
      }),
    );
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
  };

  const handleQuicklinkAddedOrEdited = (data) => {
    const updatedQuickLinksData = formValues?.qlQuickLinks
      ? [...formValues.qlQuickLinks]
      : [];
    const updatedData = {
      name: buildLabelByLang(data, 'name', 'nameES'),
      iconFile: uploadedIconData?.file,
      iconUrl: uploadedIconData?.iconUrl || data.iconUrl,
      url: data.url,
    };
    if (selectedQuickLink) {
      updatedData.id = selectedQuickLink.id;
      updatedData.index = selectedQuickLink.id;
      const index = updatedQuickLinksData.findIndex(
        (link) => link.id === selectedQuickLink.id,
      );
      updatedQuickLinksData[index] = updatedData;
    } else {
      updatedData.id = updatedQuickLinksData.length;
      updatedData.index = updatedQuickLinksData.length;
      updatedQuickLinksData.push(updatedData);
    }
    form.change('qlQuickLinks', updatedQuickLinksData);
    onFormChange();
    setShowQuickLinkModal(false);
  };

  const handleUpdateOptions = (
    updatedListOptions,
    sourceIndex,
    targetIndex,
  ) => {
    const updatedQuicklinks = [...formValues.qlQuickLinks];
    updatedQuicklinks[sourceIndex].index = targetIndex;
    updatedQuicklinks[targetIndex].index = sourceIndex;
    [updatedQuicklinks[sourceIndex], updatedQuicklinks[targetIndex]] = [
      updatedQuicklinks[targetIndex],
      updatedQuicklinks[sourceIndex],
    ];
    form.change('qlQuickLinks', updatedQuicklinks);
    onFormChange();
  };

  const buildListOptions = (isUberAdmin) => {
    let options = [];
    if (quickLinksLength > 0) {
      const actions = [
        {
          Icon: Delete,
          hover: 'Delete',
          onClick: (id) => handleDeleteQuickLinkClicked(id),
        },
      ];
      if (isUberAdmin) {
        actions.unshift({
          Icon: Edit,
          hover: 'Edit',
          onClick: (id) => handleEditQuickLinkClicked(id),
        });
      }
      options = formValues.qlQuickLinks.map((link, index) => ({
        id: link.id,
        order: index,
        iconUrl: link.iconUrl,
        label: getLabelByLang(link.name),
        labelSx: sx.quickLinkLabel,
        actions,
      }));
    }
    return options;
  };

  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            General
          </Typography>
        </Grid>
        <Grid item xs sx={sx.formFields}>
          {renderFields({
            sx,
            fields: QUICK_LINKS_FIELDS,
            languages,
            size,
            values: formValues,
          })}
        </Grid>
      </Box>
      <Box sx={sx.fieldSection}>
        <Grid item xs sx={sx.colorOptionsTitle}>
          <Typography variant="subtitle1">Card Fill Options</Typography>
        </Grid>
      </Box>
      <Box sx={sx.fieldSection}>
        <Grid item xs>
          <Field name="qlTextColor">
            {({ input: { onChange } }) => (
              <ColorOptions
                initialColor={qlTextColor}
                formControl={true}
                getColor={(color) => {
                  onChange(color);
                }}
                label="Text color"
                required={true}
                inputProps={{
                  'data-test': 'editCardTextColor',
                }}
              />
            )}
          </Field>
        </Grid>
      </Box>
      <Box sx={sx.fieldSection}>
        <Grid item xs>
          <Field name="qlBackgroundColor">
            {({ input: { onChange } }) => (
              <ColorOptions
                initialColor={qlBackgroundColor}
                formControl={true}
                getColor={(color) => {
                  onChange(color);
                }}
                label="Background color"
                required={true}
                inputProps={{
                  'data-test': 'editCardBackgroundColor',
                }}
              />
            )}
          </Field>
        </Grid>
      </Box>
      <Box sx={sx.fieldSection}>
        <Grid item xs>
          <VisibilitySelector
            customFieldName="qlVisibilityRuleCustom"
            selectorFieldName="qlVisibilityRuleSelect"
          />
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '14px',
        }}
      >
        <Typography variant="subtitle1" className="required">
          {quickLinksLength} EHR Quicklinks
        </Typography>
        {isUberAdmin && quickLinksLength > 0 && (
          <IconButton
            onClick={() => handleShowQuickLinkModal()}
            aria-label="add-tile"
            sx={{ padding: 0 }}
            size="large"
          >
            <AddCircle
              sx={{
                color: ColorPalette.primary.main,
                fontSize: '40px',
              }}
            />
          </IconButton>
        )}
      </Box>
      {quickLinksLength === 0 ? (
        <EmptySection
          title="There Are No EHR Quicklinks Set Up"
          buttonLabel="Add an EHR Quicklink"
          hasPermission={quickLinksEditPermission}
          ButtonIcon={Add}
          buttonClicked={() => handleShowQuickLinkModal()}
        />
      ) : (
        <GozioListItem
          listHeaderName="QuickLink Name"
          enableReorder={true}
          options={buildListOptions(isUberAdmin)}
          updateOptions={handleUpdateOptions}
        />
      )}
      {showQuickLinkModal && (
        <GenericFormModal
          title={`${selectedQuickLink ? 'Edit' : 'Add'} EHR Quicklinks`}
          open={true}
          formParams={{
            onSubmit: handleQuicklinkAddedOrEdited,
            validate,
            initialValues: {
              name: parseByLang(selectedQuickLink?.name),
              ...!!parseByLang(
                selectedQuickLink?.name,
                LANGUAGE_CODES.SPANISH,
              ) && {
                nameES: parseByLang(
                  selectedQuickLink?.name,
                  LANGUAGE_CODES.SPANISH,
                ),
              },
              iconUrl: selectedQuickLink?.iconUrl || '',
              url: selectedQuickLink?.url || '',
            },
          }}
          body={
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FinalTextField
                  label={getFormLabel('Quicklink Name', true)}
                  name="name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {hasSpanish(languages) && (
                <Grid item>
                  <FinalTextField
                    label={getFormLabel('Quicklink Name', false, true)}
                    name="nameES"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item>
                <FinalTextField
                  label={getFormLabel('EHR URL', true)}
                  name="url"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field name="iconUrl">
                  {({ input }) => (
                    <ImageUpLoader
                      title={
                        <span>
                          <Asterisk /> Supporting Icon
                        </span>
                      }
                      image={input.value ? { url: input.value } : null}
                      fileInfo="Supported files includes jpeg and png. Image dimensions must be 26px by 26px."
                      onImageUpdated={({ file, url }) => {
                        setUploadedIconData({ file, iconUrl: url });
                        input.onChange(url);
                      }}
                      maxFileSize={50000}
                      minWidth={26}
                      minHeight={26}
                      maxWidth={26}
                      maxHeight={26}
                      allowDelete={false}
                      showImage={true}
                      previewWidth={64}
                      previewHeight={64}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          }
          greyOutConfirm={!uploadedIconData && !selectedQuickLink?.iconUrl}
          confirmText={`${selectedQuickLink ? 'SAVE' : 'ADD'} QUICKLINK`}
          handleClose={() => setShowQuickLinkModal(false)}
        />
      )}
    </TabPanel>
  );
};

MyChartQuickLinksTabPanel.propTypes = {
  sx: PropTypes.object,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  qlBackgroundColor: PropTypes.string,
  size: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
  ]).isRequired,
  tabValue: PropTypes.number,
  qlTextColor: PropTypes.string,
};

MyChartQuickLinksTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
