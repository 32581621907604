import { Lock } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import { A2C_DISPLAY_TYPES, CARD_GROUP_TYPES } from 'components/dashboard/cardLayouts/cardhelper';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import GozioRadioGroup from 'components/radioGroup/radioGroup';
import GozioSelect from 'components/selector/selector';
import TabPanel from 'components/tabPanel/tabPanel';
import VisibilitySelector from 'components/selector/VisibilitySelector';
import { FlamingoContext } from 'contexts/flamingo';
import { getFormLabel } from 'helpers/form-util';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import ColorPalette from 'pages/gozio_colors';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';

export const AccessToCareGeneralTabPanel = ({
  backgroundColor,
  sx,
  cardGroupType,
  formValues,
  index,
  languages = [],
  tabValue,
  textColor,
}) => {
  const { activeNetwork } = useContext(FlamingoContext);
  const networkFeatures = activeNetwork?.configuration?.features || {};
  const appointmentAvailabilityEnabled
    = networkFeatures.appointmentAvailability === 'on';
  return (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      {cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME && (
        <Box sx={sx.fieldArea}>
          <Grid item xs>
            <Typography sx={sx.displayHeading} variant="subtitle1">
              Template Options
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" sx={{ color: '#454e58' }}>
              Card Display
            </Typography>
            <Field name="displayType">
              {({ input: { value, onChange } }) => (
                <GozioRadioGroup
                  groupName="displayType"
                  onChange={onChange}
                  options={[
                    { label: 'Map', value: A2C_DISPLAY_TYPES.MAP },
                    { label: 'Image', value: A2C_DISPLAY_TYPES.IMAGE },
                    {
                      label: 'Map & Image',
                      value: A2C_DISPLAY_TYPES.MAP_AND_IMAGE,
                    },
                  ]}
                  row={true}
                  value={value}
                />
              )}
            </Field>
          </Grid>
        </Box>
      )}
      {[CARD_GROUP_TYPES.CARE_NEAR_ME].includes(cardGroupType) && (
        <Box sx={sx.fieldArea}>
          <Grid item xs>
            <Grid container>
              <Grid item>
                <Typography sx={sx.displayHeading} variant="subtitle1">
                  Sorting Options
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: '16px' }}>
                <InfoTooltip
                  title="This setting controls the default sort option applied to the results list. Both options will be available for selection within your mobile application. We recommend selecting a sorting preference that fits your patient’s needs." />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs sx={{ marginBottom: '24px' }}>
            <Field name="sortingOptionsType">
              {({ input }) => (
                <GozioSelect
                  input={{ value: input.value, onChange: input.onChange }}
                  label={getFormLabel('Make Selection', true)}
                  renderValue={(selected) => (
                    <Box sx={sx.selectMenuValue}>
                      {selected === 'distance' ? 'Distance' : 'Custom Logic'}
                    </Box>
                  )}
                  menuItemSx={{
                    borderBottom: `1px solid ${ColorPalette.grey[200]}`,
                    marginBottom: 0,
                    paddingBottom: '16px',
                    paddingTop: '16px',
                  }}
                  options={[
                    {
                      id: 'distance',
                      label: (
                        <Box sx={sx.selectMenuOptionWrapper}>
                          <Box>Distance</Box>
                          <Box sx={sx.selectMenuOptionSubtitle}>
                            Results optimized for distance.
                          </Box>
                        </Box>
                      ),
                      disabled: false,
                    },
                    {
                      id: 'rank',
                      label: (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {!appointmentAvailabilityEnabled && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '16px',
                              }}
                            >
                              <Lock />
                            </Box>
                          )}
                          <Box sx={sx.selectMenuOptionWrapper}>
                            <Box>Custom Logic</Box>
                            <Box sx={sx.selectMenuOptionSubtitle}>
                              Results optimized by custom logic.
                            </Box>
                          </Box>
                        </Box>
                      ),
                      disabled: !appointmentAvailabilityEnabled,
                    },
                  ]}
                />
              )}
            </Field>
          </Grid>
          <Tooltip
            arrow
            placement="top"
            title={
              networkFeatures.appointmentAvailability === 'off'
                ? 'This functionality is currently unavailable. Please contact Gozio support to enable this feature.'
                : ''
            }
          >
            <Grid item xs>
              {renderFields({
                sx,
                fields: [
                  {
                    key: 'customLogicTitle',
                    lang: LANGUAGE_CODES.ENGLISH,
                    langKey: 'customLogicTitleEn',
                    label: 'Custom Logic Title',
                    required: formValues?.sortingOptionsType === 'rank',
                    disabled: formValues?.sortingOptionsType === 'distance',
                  },
                  {
                    key: 'customLogicTitle',
                    lang: LANGUAGE_CODES.SPANISH,
                    langKey: 'customLogicTitleEs',
                    label: 'Custom Logic Title (Spanish)',
                    disabled: formValues?.sortingOptionsType === 'distance',
                  },
                ],
                languages,
                size: null,
                values: formValues,
              })}
            </Grid>
          </Tooltip>
        </Box>
      )}
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            General
          </Typography>
        </Grid>
        <Grid
          item
          xs
          sx={
            formValues.displayType === A2C_DISPLAY_TYPES.IMAGE
              ? sx.formFields
              : sx.fieldSection
          }
        >
          {renderFields({
            sx,
            fields: [
              {
                key: 'title',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'titleEn',
                label: 'Title',
                required: true,
                charLimit: 40,
              },
              {
                key: 'title',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'titleEs',
                label: 'Title (Spanish)',
                charLimit: 40,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
        {formValues.displayType === A2C_DISPLAY_TYPES.IMAGE && (
          <>
            <Grid item xs sx={sx.formFields}>
              {renderFields({
                sx,
                fields: [
                  {
                    key: 'subtitle2',
                    lang: LANGUAGE_CODES.ENGLISH,
                    langKey: 'subtitle2En',
                    label: 'Subtitle',
                    required: true,
                    charLimit: 40,
                  },
                  {
                    key: 'subtitle2',
                    lang: LANGUAGE_CODES.SPANISH,
                    langKey: 'subtitle2Es',
                    label: 'Subtitle (Spanish)',
                    charLimit: 40,
                  },
                ],
                languages,
                size: null,
                values: formValues,
              })}
            </Grid>
            <Grid item xs sx={sx.fieldSection}>
              {renderFields({
                sx,
                fields: [
                  {
                    key: 'buttonText',
                    lang: LANGUAGE_CODES.ENGLISH,
                    langKey: 'buttonTextEn',
                    label: 'Button',
                    required: true,
                    charLimit: 15,
                  },
                  {
                    key: 'buttonText',
                    lang: LANGUAGE_CODES.SPANISH,
                    langKey: 'buttonTextEs',
                    label: 'Button (Spanish)',
                    charLimit: 15,
                  },
                ],
                languages,
                size: null,
                values: formValues,
              })}
            </Grid>
          </>
        )}
        <Box sx={{ marginBottom: '24px' }}>
          <Grid item xs>
            <Typography variant="subtitle1">Color Options</Typography>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="backgroundColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={backgroundColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Background Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardBackgroundColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="textColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={textColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Text Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <VisibilitySelector />
      </Box>
    </TabPanel>
  );
};

AccessToCareGeneralTabPanel.propTypes = {
  sx: PropTypes.object,
  backgroundColor: PropTypes.string,
  cardGroupType: PropTypes.string.isRequired,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  tabValue: PropTypes.number,
  textColor: PropTypes.string,
};

AccessToCareGeneralTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
