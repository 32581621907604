import { Box, Grid, Typography } from '@mui/material';
import ColorOptions from 'components/colorOptions/colorOptions';
import TabPanel from 'components/tabPanel/tabPanel';
import VisibilitySelector from 'components/selector/VisibilitySelector';
import { LANGUAGE_CODES } from 'helpers/lang-util';
import { renderFields } from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

export const QuicklinkListGeneralTabPanel = ({
  backgroundColor,
  sx,
  formValues,
  index,
  languages = [],
  tabValue,
  textColor,
}) => (
    <TabPanel sx={sx.tabPanel} value={tabValue} index={index}>
      <Box sx={sx.fieldArea}>
        <Grid item xs>
          <Typography sx={sx.displayHeading} variant="subtitle1">
            General
          </Typography>
        </Grid>
        <Grid item xs sx={{ ...sx.formFields, ...sx.fieldSection }}>
          {renderFields({
            sx,
            fields: [
              {
                key: 'title',
                lang: LANGUAGE_CODES.ENGLISH,
                langKey: 'titleEn',
                label: 'Title',
                required: true,
                charLimit: 90,
              },
              {
                key: 'title',
                lang: LANGUAGE_CODES.SPANISH,
                langKey: 'titleEs',
                label: 'Title (Spanish)',
                charLimit: 90,
              },
            ],
            languages,
            size: null,
            values: formValues,
          })}
        </Grid>
        <Box sx={{ marginBottom: '24px' }}>
          <Grid item xs>
            <Typography variant="subtitle1">Color Options</Typography>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="backgroundColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={backgroundColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Background Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardBackgroundColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <Box sx={sx.fieldSection}>
          <Grid item xs>
            <Field name="textColor">
              {({ input: { onChange } }) => (
                <ColorOptions
                  initialColor={textColor}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Card Text Color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Box>
        <VisibilitySelector />
      </Box>
    </TabPanel>
  );

QuicklinkListGeneralTabPanel.propTypes = {
  sx: PropTypes.object,
  backgroundColor: PropTypes.string,
  formValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  tabValue: PropTypes.number,
  textColor: PropTypes.string,
};

QuicklinkListGeneralTabPanel.defaultProps = {
  sx: {},
  formValues: {},
  index: 0,
  tabValue: 0,
};
