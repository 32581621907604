import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AVAILABLE_CARD_GROUPS,
  CARD_GROUP_TYPES,
  convertCardGroup,
  extractCardPropertiesForRendering,
  generateNewCardGroupInput,
  isGroupIncompleteForPackaging,
  PLACEHOLDER_BUTTON_TEXT_MAP,
  PLACEHOLDER_CUSTOM_LOGIC_TITLE_MAP,
  PLACEHOLDER_TITLE_MAP,
} from 'components/dashboard/cardLayouts/cardhelper';
import MyFavoritesCardLayout from 'components/dashboard/cardLayouts/myFavoritesCardLayout';
import { mapMyFavoritesEditCardToPreview, MY_FAVORITES_BUTTON_TEXT } from 'components/dashboard/myFavoritesHelper';
import { mapOpenPlatformEditCardToPreview } from 'components/dashboard/openPlatformHelper';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import WorkspaceSwitcher from 'components/workspaceSwitcher/workspaceSwitcher';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useConfigColors } from 'hooks/dataHooks/useConfigColors';
import {
  useCreateNetworkDashboardCardGroup,
  useDeleteNetworkDashboardCardGroup,
  useFindNetworkDashboards,
  useUpdateNetworkDashboard,
} from 'hooks/dataHooks/useNetworkDashboard';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useToast from 'hooks/useToast';
import useWindowSize from 'hooks/useWindowSize';
import update from 'immutability-helper';
import _ from 'lodash';
import ColorPalette from 'pages/gozio_colors';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import DashboardPreview from 'pages/mobileSettings/dashboard/containers/editCard/dashboardPreview';
import {
  mapEditCardToPreview,
  mapMyChartEditCardToPreview,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import EditCardPanel from 'pages/mobileSettings/dashboard/containers/editCard/EditCardPanel';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditCardGroupPanel from './containers/editCard/EditCardGroupPanel';
import { mapEditCardGroupToPreview } from './containers/editCard/editCardGroupHelper';
import { AVAILABLE_BACKGROUND_OPTIONS } from '../../../components/dashboard/dashboardHelper';
import HeaderUnderlayLayout from '../../../components/dashboard/headerUnderlayLayout';
import EditBackgroundOptionPanel from './containers/editCard/EditBackgroundOptionPanel';

const checkForIncompleteDashboard = (cardGroups = []) => {
  for (const group of cardGroups) {
    if (isGroupIncompleteForPackaging(group)) {
      return true;
    }
  }

  return false;
};

const buildStyles = ({ theme }) => ({
  layout: {
    overflow: 'hidden',
  },
  root: { minWidth: '960px' },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    fontSize: '1.75rem',
    letterSpacing: '0.12px',
    lineHeight: '2.4375rem',
  },
  subtitle: {
    marginBottom: '24px',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    flexBasis: 'content',
    flexGrow: 1,
    maxWidth: '380px',
    minHeight: '100%',
    overflowY: 'auto',
    paddingRight: '24px',
    width: '380px',
  },
  rightContainer: {
    flexBasis: 'content',
    flexGrow: 1,
    maxWidth: 'unset',
    minHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  previewOnEdit: {
    '& > div > div': {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  previewOnRead: {
    left: 'calc((100% - 350px) / 2)',
  },
  cardRow: {
    minWidth: '344px',
    maxWidth: '368px',
    textAlign: 'center',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  cardRowTitle: {
    marginTop: '4px',
    color: colorWithAlpha(theme.palette.black, 0.6),
    fontSize: '16px',
  },
});

export const MobileDashboardPageContent = ({ styles, networkId }) => {
  const { toastNotificationErrorHook } = useToast();
  const [cardGroups, setCardGroups] = useState([]);
  const [headerUnderlay, setHeaderUnderlay] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const [isDashboardIncomplete, setIsDashboardIncomplete] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardGroup, setSelectedCardGroup] = useState(null);
  const [selectedBackgroundOption, setSelectedBackgroundOption] = useState(null);
  const [modifiedSelectedCardProps, setModifiedSelectedCardProps] = useState(null);
  const [modifiedSelectedCardGroupProps, setModifiedSelectedCardGroupProps] = useState(null);
  const [modifiedSelectedBackgroundOptionProps, setModifiedSelectedBackgroundOptionProps] = useState(null);
  const [editPanelTabValue, setEditPanelTabValue] = useState(0);
  const [configColors, setConfigColors] = useState({});
  const [isCardGroupCreating, setIsCardGroupCreating] = useState(false);

  const {
    activeNetwork,
    layout: { openDrawer },
  } = useContext(FlamingoContext);
  const featuresMap = {
    [CARD_GROUP_TYPES.MYCHART]:
    activeNetwork?.configuration?.features?.myChart === 'on',
    [CARD_GROUP_TYPES.MY_FAVORITES]:
    activeNetwork?.configuration?.features?.myFavorites === 'on',
    [CARD_GROUP_TYPES.QUICKLINK_LIST]:
    activeNetwork?.configuration?.features?.quicklinkList === 'on',
    [CARD_GROUP_TYPES.OPEN_PLATFORM]:
    activeNetwork?.configuration?.features?.openPlatform === 'on',
    [CARD_GROUP_TYPES.CYCLE]:
    activeNetwork?.configuration?.features?.cycleCard === 'on',
    [CARD_GROUP_TYPES.NAV_LINKS]:
    activeNetwork?.configuration?.features?.networkDashboardNavlinks === 'on',
    'networkDashboardUnderlay':
    activeNetwork?.configuration?.features?.networkDashboardUnderlay === 'on',
  };
  const languages = useActiveNetworkLanguages(['en']);

  const { data: configColorsData } = useConfigColors(networkId);
  const {
    error: dashboardError,
    data: dashboardData,
    refetch: refetchCardGroups,
  } = useFindNetworkDashboards(networkId);
  const [createCardGroup] = useCreateNetworkDashboardCardGroup(networkId);
  const [deleteCardGroup] = useDeleteNetworkDashboardCardGroup(networkId);
  const [updateDashboard] = useUpdateNetworkDashboard(networkId);
  const size = useWindowSize();

  useEffect(() => {
    if (configColorsData?.length !== Object.keys(configColors).length) {
      const colors = {};
      configColorsData.forEach(({ key, value }) => (colors[key] = value));
      setConfigColors(colors);
    }
  }, [configColors, configColorsData]);

  const deleteCardGroupFromPreview = useCallback(
    async (card) => {
      await deleteCardGroup({
        variables: {
          input: {
            dashboardId,
            cardGroupId: card.id,
          },
        },
      });
      setCardGroups(cardGroups.filter((item) => item.id !== card.id));
    },
    [cardGroups, dashboardId, deleteCardGroup],
  );

  const deleteBackgroundOptionFromPreview = useCallback(
    async () => {
      await updateDashboard({
        variables: {
          input: {
            dashboardId,
            headerUnderlay: null,
          },
        },
      }).catch((err) => {
        toastNotificationErrorHook(err.message);
      });
      setHeaderUnderlay(null);
    }, [dashboardId, toastNotificationErrorHook, updateDashboard],
  );

  const updateCardGroups = useCallback(
    async (updatedCardGroups = cardGroups) => {
      await updateDashboard({
        variables: {
          input: {
            dashboardId,
            cardGroups: updatedCardGroups.map((cardGroup) => cardGroup.id),
          },
        },
      }).catch((err) => {
        toastNotificationErrorHook(err.message);
      });
    },
    [cardGroups, dashboardId, toastNotificationErrorHook, updateDashboard],
  );

  const moveCardGroupInPreview = useCallback(
    async (dragIndex, hoverIndex) => {
      const updatedDragIndex = headerUnderlay ? dragIndex - 1 : dragIndex;
      const updatedHoverIndex = headerUnderlay ? hoverIndex - 1 : hoverIndex;
      const newCard = cardGroups[updatedDragIndex];
      if (newCard) {
        const updatedCardGroups = update(cardGroups, {
          $splice: [
            [updatedDragIndex, 1],
            [updatedHoverIndex, 0, newCard],
          ],
        });

        setCardGroups(updatedCardGroups);
        await updateCardGroups(updatedCardGroups);
      }
    },
    [cardGroups, updateCardGroups, headerUnderlay],
  );

  const onBackgroundOptionClick = () => {
    if (headerUnderlay) {
      const backgroundOption = _.cloneDeep(headerUnderlay);
      backgroundOption.type = 'headerUnderlay';
      setSelectedCard(null);
      setSelectedCardGroup(null);
      setSelectedBackgroundOption(backgroundOption);
    }
  };

  const onCardGroupClick = (input) => {
    const { cardGroupId } = input;
    const cardGroup = _.cloneDeep(cardGroups.find((g) => g.id === cardGroupId));

    if (cardGroup) {
      const cardGroupData = {};
      cardGroupData.cardGroupId = cardGroup.id;
      cardGroupData.cardGroupType = cardGroup.type;
      cardGroupData.groupTitle = cardGroup.groupTitle;
      cardGroupData.showButtons = cardGroup.showButtons;
      cardGroupData.showGroupTitle = cardGroup.showGroupTitle;
      cardGroupData.groupTitleTextColor = cardGroup.groupTitleTextColor;
      cardGroupData.navlinkDisplay = cardGroup.navlinkDisplay;
      cardGroupData.navlinkActiveCards = cardGroup.navlinkActiveCards;
      cardGroupData.textColor = cardGroup.textColor;
      cardGroupData.backgroundColor = cardGroup.backgroundColor;
      cardGroupData.visibilityRule = cardGroup.visibilityRule;
      cardGroupData.visibilityRuleCustom = cardGroup.visibilityRuleCustom;
      cardGroupData.cards = cardGroup.cards;
      setSelectedCard(null);
      setSelectedBackgroundOption(null);
      setSelectedCardGroup(cardGroupData);
    }
  };

  const onCardClick = (input) => {
    const { cardGroupId, key } = input;
    const cardGroup = _.cloneDeep(cardGroups.find((g) => g.id === cardGroupId));
    if (cardGroup) {
      const card = cardGroup.cardProps[key];
      const additionalProps = {};
      if (cardGroup.type === 'myChart') {
        additionalProps.quickLinks = cardGroup.cardProps.card2;
      } else if (
        cardGroup.type === CARD_GROUP_TYPES.QUICKLINK_LIST
        || cardGroup.type === CARD_GROUP_TYPES.FINDING_CARE
        || cardGroup.type === CARD_GROUP_TYPES.CARE_NEAR_ME
      ) {
        additionalProps.quickLinks
          = cardGroup.cardProps.card1.quickLinksData || [];

        if (cardGroup.type === 'findingCare') {
          additionalProps.linkBackgroundColor
            = cardGroup.cardProps.card1.quickLinksData?.length > 0
              ? cardGroup.cardProps.card1.quickLinksData[0].iconTint
              : ColorPalette.grey[500];
        }
      }

      const {
        backgroundColor,
        backgroundImageUrl,
        id: cardId,
        buttonText,
        cardName,
        dismissable,
        displayType,
        expires,
        iconUrl,
        iconUrl2,
        validation,
        linkData,
        locations = {},
        measurements,
        module,
        myChartUrl,
        overline,
        subtitle,
        subtitle2,
        textAlignment,
        textColor,
        title,
        waitTimes,
        visibilityRule,
        visibilityRuleCustom,
        sortingOptions,
      } = card;

      const data = {
        cardGroupId: cardGroupId,
        cardGroupType: cardGroup.type,
        cardId,
        backgroundColor,
        backgroundImageUrl,
        buttonText:
          buttonText
          || languages
            .map((lang) => PLACEHOLDER_BUTTON_TEXT_MAP[lang])
            .filter((t) => t),
        cardName,
        dismissable,
        displayType,
        expires,
        iconUrl,
        iconUrl2,
        validation,
        linkData,
        locations,
        measurementHeight: measurements?.height || 'minimum',
        module,
        myChartUrl,
        overline,
        size: input.size,
        subtitle,
        subtitle2,
        textAlignment: textAlignment || 'left',
        textColor,
        title:
          title
          || languages.map((lang) => PLACEHOLDER_TITLE_MAP[lang]).filter((t) => t),
        waitTimes,
        visibilityRule,
        visibilityRuleCustom,
        customLogicTitle: sortingOptions?.title
          ? sortingOptions?.title
          : languages
            .map((lang) => PLACEHOLDER_CUSTOM_LOGIC_TITLE_MAP[lang])
            .filter((t) => t),
        sortingOptionsType: sortingOptions?.type,
        ...additionalProps,
      };
      if (
        data.quickLinks
        && [
          CARD_GROUP_TYPES.QUICKLINK_LIST,
          CARD_GROUP_TYPES.FINDING_CARE,
          CARD_GROUP_TYPES.CARE_NEAR_ME,
        ].includes(cardGroup?.type)
      ) {
        data.quickLinks = data.quickLinks.map((link, i) => {
          const { errors } = data.validation;
          if (errors?.length) {
            const quickLinkErrors = errors.filter(
              (err) => err.path[0] === 'quickLinksData' && parseInt(err.path[1]) === i,
            );
            if (quickLinkErrors.length) {
              return {
                ...link,
                validation: {
                  status: 'Incomplete',
                  message: quickLinkErrors
                    .map((err) => `${err.__typename}: ${err.message}`)
                    .join('; '),
                },
              };
            }
            return {
              ...link,
              validation: {
                status: 'Complete',
              },
            };
          }
          return {
            ...link,
            validation: {
              status: 'Complete',
            },
          };
        });
      }

      if (cardGroup.type === CARD_GROUP_TYPES.MY_FAVORITES) {
        data.card1 = cardGroup?.cardProps?.card1;
        data.card2 = cardGroup?.cardProps?.card2;
      }

      data.key = key;
      setSelectedCardGroup(null);
      setSelectedBackgroundOption(null);
      setSelectedCard(data);
    }
  };

  const moveBackgroundOptionToPreview = useCallback(
    async (headerUnderlayData) => {
      if (!headerUnderlay) {
        const cloneHeaderUnderlay = _.cloneDeep(headerUnderlayData);

        const headerUnderlayInput = {
          underlayHeaderColor: cloneHeaderUnderlay.cardProps.underlayHeaderColor,
          underlayTextColor: cloneHeaderUnderlay.cardProps.underlayTextColor,
          numberOfCards: cloneHeaderUnderlay.cardProps.numberOfCards,
        };

        await updateDashboard({
          variables: {
            input: {
              dashboardId,
              headerUnderlay: headerUnderlayInput,
            },
          },
        }).catch((err) => {
          toastNotificationErrorHook(err.message);
        });
      }
    }, [dashboardId, headerUnderlay, toastNotificationErrorHook, updateDashboard],
  );

  const moveCardGroupToPreview = useCallback(
    async (cardGroup, hoverIndex) => {
      const updatedHoverIndex = headerUnderlay ? hoverIndex - 1 : hoverIndex;
      if (cardGroup.cardType === MyFavoritesCardLayout) {
        cardGroup.cardProps.card1.buttonText
          = MY_FAVORITES_BUTTON_TEXT.LOCATIONS;
      }

      const cloneCardGroup = _.cloneDeep(cardGroup);
      const index
        = typeof updatedHoverIndex === 'undefined' ? cardGroups.length : updatedHoverIndex;

      const input = generateNewCardGroupInput(
        dashboardId,
        cloneCardGroup,
        index,
        languages,
      );

      setIsCardGroupCreating(true);
      const {
        data: {
          createNetworkDashboardCardGroup: { id, cards },
        },
      } = await createCardGroup({
        variables: {
          input,
        },
      });

      // eslint-disable-next-line require-atomic-updates
      cloneCardGroup.type = input.type;
      cards.forEach((card, i) => {
        const cardKey = `card${i + 1}`;
        if (!cloneCardGroup.cardProps[cardKey]) {
          cloneCardGroup.cardProps[cardKey] = {};
        }
        cloneCardGroup.cardProps[cardKey].id = card.id;
        cloneCardGroup.cardProps[cardKey].title = card.title;
        cloneCardGroup.cardProps[cardKey].validation = card.validation;
      });
      setCardGroups(
        update(cardGroups, {
          $splice: [
            [
              index,
              0,
              Object.assign(cloneCardGroup, {
                id,
                preview: true,
                index,
              }),
            ],
          ],
        }),
      );
      setIsCardGroupCreating(false);
    },
    [cardGroups, dashboardId, createCardGroup, languages, setCardGroups, headerUnderlay],
  );

  const renderAvailableCardGroups = () => AVAILABLE_CARD_GROUPS.map((cardGroup, i) => {
    const { cardProps, feature, label, type } = cardGroup;

    // If the feature is not enabled, we don't want to show as an available card option
    if (feature && featuresMap.hasOwnProperty(feature) && !featuresMap[feature]) {
      return null;
    }

    if (type === CARD_GROUP_TYPES.CYCLE || type === CARD_GROUP_TYPES.NAV_LINKS) {
      const { cardType: CardGroup } = cardGroup;
      return (
        <Box
          key={`ac_${i}`}
          sx={styles.cardRow}
          data-test={`avail-${label.replaceAll(' ', '_').toLowerCase()}`}
          onDoubleClick={() => moveCardGroupToPreview(cardGroup)}
        >
          <CardGroup
            preview={false}
            onCardGroupClick={() => {}}
            {...cardProps}
            configColors={configColors}
          />
          <Typography variant="body1">{label}</Typography>
        </Box>
      );
    }

    const props = extractCardPropertiesForRendering(cardProps);

    const { cardType: CardType } = cardGroup;
    return (
      <Box
        key={`ac_${i}`}
        sx={styles.cardRow}
        data-test={`avail-${label.replaceAll(' ', '_').toLowerCase()}`}
        onDoubleClick={() => moveCardGroupToPreview(cardGroup)}
      >
        <CardType
          preview={false}
          onCardClick={() => {
          }}
          {...props}
          configColors={configColors}
        />
          <Typography variant="body1">{cardGroup.label}</Typography>
      </Box>
    );
    }).filter((c) => !!c);

  const renderAvailableBackgroundOptions = (headerUnderlay) => AVAILABLE_BACKGROUND_OPTIONS.map((option, i) => {
    const { layout: DashboardOption, feature } = option;
    const currentHeaderUnderlay = headerUnderlay;

    // If the feature is not enabled, we don't want to show as an available option
    if (feature && featuresMap.hasOwnProperty(feature) && !featuresMap[feature]) {
      return null;
    }

    if (DashboardOption === HeaderUnderlayLayout && currentHeaderUnderlay !== null) {
      return null;
    }

    const { label } = option;
    return (
      <Box
        key={`ac_${i}`}
        sx={styles.cardRow}
        data-test={`avail-${label.replaceAll(' ', '_').toLowerCase()}`}
        onDoubleClick={() => moveCardGroupToPreview(option)}
      >
        <DashboardOption
          preview={false}
          onCardClick={() => {
          }}
        />
      </Box>
    );
  }).filter((option) => !!option);

  const onEditCardGroupChanged = ({ cardGroupId, values, languages = [] }) => {
    const previewValues = mapEditCardGroupToPreview({
      cardGroupId,
      languages,
      selectedCardGroup,
      values,
    });
    setModifiedSelectedCardGroupProps(previewValues);
  };

  const onEditDashboardItemSaved = () => {
    refetchCardGroups();
    clearSelections();
  };

  const onEditBackgroundOptionChanged = (values) => {
    setModifiedSelectedBackgroundOptionProps(values);
  };

  const onEditDashboardItemCancelled = (refresh) => {
    // In the event a card has changed within a card group, but the card group wasn't saved afterward we should go
    // ahead and refresh those values.
    if (refresh === true) {
      refetchCardGroups();
    }
    clearSelections();
  };

  const clearSelections = () => {
    setEditPanelTabValue(0);
    setModifiedSelectedCardProps(null);
    setModifiedSelectedCardGroupProps(null);
    setModifiedSelectedBackgroundOptionProps(null);
    setSelectedCard(null);
    setSelectedCardGroup(null);
    setSelectedBackgroundOption(null);
  };

  const onEditCardChanged = ({ cardGroupType, languages = [], values }) => {
    let selectedCardGroup;
    let cardKey;
    switch (cardGroupType) {
      case CARD_GROUP_TYPES.MYCHART:
        setModifiedSelectedCardProps(
          mapMyChartEditCardToPreview({
            languages,
            selectedCard,
            values,
          }),
        );
        break;
      case CARD_GROUP_TYPES.MY_FAVORITES:
        setModifiedSelectedCardProps(
          mapMyFavoritesEditCardToPreview({
            languages,
            selectedCard,
            values,
          }),
        );
        break;
      case CARD_GROUP_TYPES.OPEN_PLATFORM:
        setModifiedSelectedCardProps(
          mapOpenPlatformEditCardToPreview({ selectedCard, values }),
        );
        break;
      default:
        selectedCardGroup = cardGroups.find(
          (group) => group.id === selectedCard.cardGroupId,
        );
        cardKey = 'card1';
        for (const key in selectedCardGroup?.cardProps) {
          if (selectedCardGroup?.cardProps[key].id === selectedCard.cardId) {
            cardKey = key;
            break;
          }
        }
        setModifiedSelectedCardProps(
          mapEditCardToPreview({
            cardKey,
            languages,
            selectedCard,
            values,
          }),
        );
        break;
    }
  };

  const onTabValueChanged = (tabValue) => {
    setEditPanelTabValue(tabValue);
  };

  useEffect(() => {
    if (dashboardError) {
      toastNotificationErrorHook(dashboardError.message);
      return;
    }

    if (dashboardData?.findNetworkDashboardsByNetworkId?.length) {
      const networkData = dashboardData.findNetworkDashboardsByNetworkId[0];
      setDashboardId(networkData.id);
      setCardGroups(
        networkData.cardGroups.map((cardGroup) => convertCardGroup(cardGroup)),
      );

      if (networkData.headerUnderlay) {
        setHeaderUnderlay(networkData.headerUnderlay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardData,
    dashboardError,
    networkId,
    refetchCardGroups,
    toastNotificationErrorHook,
  ]);

  useEffect(() => {
    setIsDashboardIncomplete(checkForIncompleteDashboard(cardGroups));
  }, [cardGroups]);

  const leftNavWidth = openDrawer ? 250 : 0;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography
          variant="h1"
          sx={styles.title}
          id="up-dashboard-instructs"
          data-test="FlamingoPage-title-Dashboard"
        >
          Dashboard
        </Typography>
        <InfoTooltip
          title={
            'Simply drag and drop the card sections from the left to create the dashboard. Need to remove a card, just drag it to the top of the screen to delete the section. Click into the individual cards to edit details like name, icon, link, etc.'
          }
        />
      </Box>
      <Grid container sx={styles.content}>
        {!selectedCard && !selectedCardGroup && !selectedBackgroundOption && (
          <Box sx={{ ...styles.leftContainer, height: `${size.height - 175}px` }}>
            {renderAvailableBackgroundOptions(headerUnderlay).length > 0 && (
              <>
                <Typography variant="subtitle1" sx={styles.subtitle}>
                  Background Options
                </Typography>
                <Box sx={{ ...styles.cardArea, flex: 1 }}>
                  {renderAvailableBackgroundOptions(headerUnderlay)}
                </Box>
              </>
            )}
            <Typography variant="subtitle1" sx={styles.subtitle}>
              Card Options
            </Typography>
            <Box sx={{ ...styles.cardArea, flex: 1 }}>
              {renderAvailableCardGroups()}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            ...styles.rightContainer,
            ...selectedCard && styles.previewOnEdit,
            width: size.width - 404 - (openDrawer ? 250 : 0),
            height: `${size.height - 175}px`,
          }}
          data-test="preview-container"
        >
          <DashboardPreview
            sx={selectedCard ? {} : styles.previewOnRead}
            cardGroups={_.cloneDeep(cardGroups)}
            headerUnderlay={_.cloneDeep(headerUnderlay)}
            configColors={configColors}
            editPanelTabValue={editPanelTabValue}
            incompleteForPackaging={isDashboardIncomplete}
            isCardGroupCreating={isCardGroupCreating}
            onCardClick={onCardClick}
            onCardGroupClick={onCardGroupClick}
            onBackgroundOptionClick={onBackgroundOptionClick}
            onCardGroupDrop={moveCardGroupToPreview}
            onBackgroundOptionDrop={moveBackgroundOptionToPreview}
            onCardGroupDeleted={deleteCardGroupFromPreview}
            onBackgroundOptionDeleted={deleteBackgroundOptionFromPreview}
            onCardGroupMoved={moveCardGroupInPreview}
            modifiedSelectedCardProps={modifiedSelectedCardProps}
            modifiedSelectedCardGroupProps={modifiedSelectedCardGroupProps}
            modifiedSelectedBackgroundOptionProps={modifiedSelectedBackgroundOptionProps}
            selectedCard={selectedCard}
            selectedCardGroup={selectedCardGroup}
            selectedBackgroundOption={selectedBackgroundOption}
            updateCardGroups={updateCardGroups}
          />
        </Box>
        {!!selectedCard && (
          <EditCardPanel
            languages={languages}
            {...selectedCard}
            onEditCardCancelled={onEditDashboardItemCancelled}
            onEditCardChanged={onEditCardChanged}
            onEditCardSaved={onEditDashboardItemSaved}
            onTabValueChanged={onTabValueChanged}
          />
        )}
        {!!selectedCardGroup && (
          <EditCardGroupPanel
            languages={languages}
            {...selectedCardGroup}
            onEditCardGroupCancelled={onEditDashboardItemCancelled}
            onEditCardGroupChanged={onEditCardGroupChanged}
            onEditCardGroupSaved={onEditDashboardItemSaved}
          />
        )}
        {!!selectedBackgroundOption && (
          <EditBackgroundOptionPanel
            networkId={networkId}
            dashboardId={dashboardId}
            cardGroups={cardGroups}
            {...selectedBackgroundOption}
            onEditBackgroundOptionCancelled={onEditDashboardItemCancelled}
            onEditBackgroundOptionChanged={onEditBackgroundOptionChanged}
            onEditBackgroundOptionSaved={onEditDashboardItemSaved}
          />
        )}
      </Grid>
      <WorkspaceSwitcher
        contentWidth={
          selectedCard
            ? size.width - 581 - leftNavWidth
            : size.width - 380 - leftNavWidth - 24
        }
        leftOffset={selectedCard ? 250 : 654}
      />
    </>
  );
};

MobileDashboardPageContent.propTypes = {
  styles: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
};

const MobileDashboardPage = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { networkId } = useParams();

  return (
    <LoggedinLayout
      sx={styles.layout}
      enableSuspense={true}
      showWorkspaceSwitcher={false}
      supportedWorkspace={LIVE_WORKSPACE}
    >
      <MobileDashboardPageContent styles={styles} networkId={networkId} />
    </LoggedinLayout>
  );
};

export default memo(MobileDashboardPage);
