import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { colorWithAlpha } from 'helpers/color-util';
import useWindowSize from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';

const useStyles = makeStyles(({ palette, spacing }) => ({
  actions: {
    background: palette.grey[100],
    borderRadius: '0 0 8px 8px',
    bottom: 0,
    margin: '-74px 0 0 0',
    padding: spacing(2),
    position: 'absolute',
    width: '768px',
  },
  closeBtn: {
    padding: spacing(2),
    position: 'absolute',
    right: 0,
    top: 0,
    '& svg': {
      fontSize: '22px',
    },
  },
  dialogContentCenteredStepper: {
    flex: 1,
    overflowX: 'hidden',
    marginBottom: 0,
    maxHeight: '876px',
    paddingTop: '0 !important',
    textAlign: 'center',
  },
  formContent: {
    minHeight: '140px',
    overflowY: 'auto',
  },
  label: {
    color: palette.grey[500],
    fontFamily: 'Roboto',
    fontSize: spacing(1.5),
    fontWeight: 400,
    '&.MuiStepLabel-active': {
      color: palette.black,
      fontWeight: 600,
    },
    '&.MuiStepLabel-completed': {
      color: palette.black,
      fontWeight: 600,
    },
  },
  iconContainer: {
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  paper: {
    minHeight: '300px',
    paddingBottom: '74px',
  },
  stepperModalHeader: {
    backgroundColor: palette.grey[100],
    display: 'flex',
    height: spacing(10.75),
    padding: `0 ${spacing(9)}`,
    width: '100%',
  },
  stepperModalStepper: {
    backgroundColor: palette.grey[100],
    width: '100%',
  },
  stepperModalStepButton: {
    cursor: 'pointer',
  },
  titleCentered: {
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
    padding: '20px 0 20px 0',
    '& h2': {
      color: palette.grey[500],
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '39px',
    },
    '& svg': {
      color: colorWithAlpha(palette.black, 0.54),
    },
    textAlign: 'center',
  },
}));

const GenericStepperModal = ({
                               className,
                               sx,
                               disableClose,
                               fixedHeight,
                               formParams,
                               initialStepIndex,
                               onChange,
                               onClose: handleClose,
                               canGoBack,
                               canProceed,
                               confirmText,
                               open,
                               steps,
                             }) => {
  const size = useWindowSize();
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(initialStepIndex || 0);
  const contentRef = useRef();

  useEffect(() => {
    contentRef?.current?.scrollTo(0, 0);
  }, [stepIndex]);

  const step = useMemo(
    () => ({
      index: stepIndex,
      data: steps[stepIndex],
      back: () => setStepIndex((current) => Math.max(current - 1, 0)),
      forward: () => setStepIndex((current) => Math.min(current + 1, steps.length - 1)),
      reset: () => setStepIndex(0),
      canProceed: steps[stepIndex].canProceed,
    }),
    [stepIndex, steps],
  );

  const handleStepClick = (i) => {
    setStepIndex(i);
  };

  const isButtonDisabled = (formVals, isSubmitButton = false) => {
    const { requiredFields } = step.data;
    if (isSubmitButton) {
      return !formVals.valid;
    }
    const hasErrors = requiredFields
      ? requiredFields
        .map((field) => formVals.errors[field] !== undefined)
        .includes(true)
      : false;
    return (
      hasErrors
      || (step.canProceed && !step.canProceed())
      || (canProceed && !canProceed(step))
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (handleClose) handleClose(step);
      }}
      fullWidth
      classes={{
        paper: classes.paper,
      }}
      sx={{
        '&.MuiDialog-paper': {
          maxHeight: `calc(${size.height}px - 66px)`,
        },
      }}
    >
      <Box className={classes.stepperModalHeader}>
        <Stepper activeStep={stepIndex} className={classes.stepperModalStepper}>
          {steps.map((step, i) => {
            const completed = i < stepIndex;
            return (
              <Step
                key={step.title}
                className={
                  completed ? classes.stepperModalStepButton : undefined
                }
                completed={completed}
                onClick={() => {
                  if (completed) handleStepClick(i);
                }}
              >
                <StepLabel>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      {!disableClose && (
        <IconButton
          className={classes.closeBtn}
          onClick={() => {
            if (handleClose) handleClose(step);
          }}
          size="large"
        >
          <Close />
        </IconButton>
      )}
      <Form
        {...formParams}
        render={(formVals) => (
          <form
            className={clsx(classes.formContent, className ?? '')}
            {...(sx && { sx })}
            onChange={() => {
              onChange(formVals.form.getState().values);
            }}
            onSubmit={formVals.handleSubmit}
            style={{
              height: fixedHeight ? `calc(${size.height}px - 276px)` : `auto`,
            }}
          >
            <DialogTitle className={classes.titleCentered}>
              {step?.data?.title}
            </DialogTitle>
            <DialogContent
              ref={contentRef}
              className={classes.dialogContentCenteredStepper}
              sx={{
                ...step?.data?.height && {
                  height: `${step?.data?.height}px`,
                },
                ...fixedHeight && {
                  height: `calc(${size.height}px - 576px)`,
                },
              }}
            >
              {step?.data?.content}
            </DialogContent>
            <DialogActions className={classes.actions} disableSpacing>
              <Box sx={{ flex: 1 }}>
                <Button
                  onClick={() => {
                    if (handleClose) handleClose(step);
                  }}
                  color="primary"
                  variant="outlined"
                  sx={{ minWidth: '119px', marginRight: '12px' }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    if (canGoBack(step, formVals)) {
                      step.back();
                    }
                  }}
                  color="primary"
                  variant="outlined"
                  disabled={step.index === 0}
                  sx={{ minWidth: '119px', marginRight: '12px' }}
                >
                  Back
                </Button>
              </Box>
              <Box>
                {step.index + 1 < steps.length ? (
                  <Button
                    onClick={() => {
                      if (
                        step.canProceed?.()
                        || (typeof canProceed === 'function'
                          && canProceed(step, formVals))
                        || (!step.canProceed && !canProceed)
                      ) {
                        step.forward();
                      }
                    }}
                    color="primary"
                    variant="contained"
                    disabled={isButtonDisabled(formVals, false)}
                    sx={{ minWidth: '120px' }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    onClick={async () => {
                      await formVals.form.submit();
                      if (handleClose) handleClose(step);
                    }}
                    color="primary"
                    variant="contained"
                    disabled={isButtonDisabled(formVals, true)}
                    sx={{ minWidth: '119px', marginRight: '12px' }}
                  >
                    {confirmText}
                  </Button>
                )}
              </Box>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

GenericStepperModal.propTypes = {
  canGoBack: PropTypes.func,
  canProceed: PropTypes.func,
  className: PropTypes.string,
  sx: PropTypes.object,
  confirmText: PropTypes.string,
  disableClose: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  formParams: PropTypes.object,
  initialStepIndex: PropTypes.number,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
};

GenericStepperModal.defaultProps = {
  className: null,
  sx: null,
  confirmText: 'Save',
  fixedHeight: false,
  formParams: {},
  onChange: () => {},
  onClose: () => {},
  canGoBack: () => true,
};

export default GenericStepperModal;
