import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FormWatcher from 'components/forms/formWatcher';
import InfoPanel from 'components/infoPanel/infoPanel';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import dayjs from 'dayjs';
import { colorWithAlpha } from 'helpers/color-util';
import { convertDateToString, STANDARD_DATE_FORMAT } from 'helpers/date-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { featureFlagCheck } from 'helpers/permissions-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useNetworkConfig, useUpdateNetworkConfig } from 'hooks/dataHooks/useNetworkConfiguration';
import { makeValidate } from 'mui-rff';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import AnalyticsConfig, {
  ANALYTICS_CONFIG_ERROR_MAP,
  buildAnalyticsConfigSchema,
} from 'pages/network/configuration/containers/analyticsConfig';
import Colors from 'pages/network/configuration/containers/colors';
import Environment from 'pages/network/configuration/containers/environment';
import GeneralConfig, {
  GENERAL_CONFIG_ERROR_MAP,
  GENERAL_CONFIG_SCHEMA,
} from 'pages/network/configuration/containers/generalConfig';
import MobileAppTokens from 'pages/network/configuration/containers/mobileAppTokens';
import Version from 'pages/network/configuration/containers/version';
import WaitTimeFeed from 'pages/network/configuration/containers/waitTimeFeed';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { Suspense, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const ERROR_FORM_FIELD_MAP = new Map([
  ...GENERAL_CONFIG_ERROR_MAP,
  ...ANALYTICS_CONFIG_ERROR_MAP,
]);

const buildStyles = ({ theme }) => ({
  root: {
    width: '100%',
  },
  content: {
    height: 'calc(100vh - 186px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    width: '100%',
  },
});

const ConfigurationContent = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const { networkId } = useParams();

  const { data: configData } = useNetworkConfig(networkId);
  const [updateConfig] = useUpdateNetworkConfig(networkId);

  const [currentTab, setCurrentTab] = useState(0);
  const [dirtyTabList, setDirtyTabList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const ANALYTICS_CONFIG_SCHEMA = !featureFlagCheck('configAnalyticsOff') ? buildAnalyticsConfigSchema({
    mobileEnabled: configData.mobile?.analyticsEnabled,
    kioskEnabled: configData.kiosk?.analyticsEnabled,
    webEnabled: configData.webNav?.analyticsEnabled,
  }) : null;

  const validate = makeValidate(
    Yup.object().shape({
      ...!featureFlagCheck('configGeneralOff') && GENERAL_CONFIG_SCHEMA,
      ...!featureFlagCheck('configAnalyticsOff') && ANALYTICS_CONFIG_SCHEMA,
    }),
  );

  const tabList = useMemo(
    () => [
      ...featureFlagCheck('configGeneralOff')
        ? []
        : [
          {
            label: 'General',
            component: GeneralConfig,
            schema: GENERAL_CONFIG_SCHEMA,
            buildUpdateInput: (values) => ({
              map: {
                latitude: values.latitude,
                longitude: values.longitude,
                zoom: values.zoom,
              },
              timezone: values.timezone,
            }),
          },
        ],
      {
        label: 'Mobile App Tokens',
        component: MobileAppTokens,
      },
      { label: 'Version Control', component: Version },
      { label: 'Wait Time Feed', component: WaitTimeFeed },
      ...featureFlagCheck('configAnalyticsOff')
        ? []
        : [
          {
            label: 'Analytics',
            component: AnalyticsConfig,
            schema: ANALYTICS_CONFIG_SCHEMA,
            buildUpdateInput: (values) => ({
              analyticsProjectId: values.analytics_mobile_project_id,
              goLiveMobile: values.go_live_date_mobile
                ? dayjs(
                  values.go_live_date_mobile,
                  STANDARD_DATE_FORMAT,
                  true,
                ).toISOString()
                : null,
              mobile: {
                analyticsEnabled: values.analytics_mobile_enabled,
              },
              kiosk: {
                analyticsProjectId: values.analytics_kiosk_project_id,
                analyticsEnabled: values.analytics_kiosk_enabled,
              },
              webNav: {
                analyticsProjectId: values.analytics_web_project_id,
                analyticsEnabled: values.analytics_web_enabled,
              },
              webGoLiveDate: values.web_go_live_date
                ? dayjs(
                  values.web_go_live_date,
                  STANDARD_DATE_FORMAT,
                  true,
                ).toISOString()
                : null,
              goLiveKiosk: values.go_live_date_kiosk
                ? dayjs(
                  values.go_live_date_kiosk,
                  STANDARD_DATE_FORMAT,
                  true,
                ).toISOString()
                : null,
            }),
          },
        ],
      { label: 'App Colors', component: Colors },
    ],
    [ANALYTICS_CONFIG_SCHEMA],
  );

  const isDisabled = ({ schema, submitting, pristine, errors }) => {
    if (submitting || pristine) {
      return true;
    }
    return !!Object.keys(errors).find((field) => schema.hasOwnProperty(field));
  };

  const isComplete = useMemo(() => {
    if (!featureFlagCheck('configAnalyticsOff')) {
      if (
        (configData.mobile?.analyticsEnabled
          && !configData.analyticsProjectId)
        || (configData.mobile?.analyticsEnabled && !configData.goLiveMobile)
      ) {
        return false;
      }

      if (
        (configData.kiosk?.analyticsEnabled
          && !configData.kiosk?.analyticsProjectId)
        || (configData.kiosk?.analyticsEnabled && !configData.goLiveKiosk)
      ) {
        return false;
      }

      if (
        (configData.webNav?.analyticsEnabled
          && !configData.webNav?.analyticsProjectId)
        || (configData.webNav?.analyticsEnabled && !configData.webGoLiveDate)
      ) {
        return false;
      }
    }

    if (configData.validation?.status === 'incomplete') {
      return false;
    }

    if (
      !featureFlagCheck('configGeneralOff')
      && configData.validation?.errors?.length > 0
    ) {
      return !configData.validation.errors.find(
        ({ context: { value } }) => value === 'general',
      );
    }

    return true;
  }, [configData]);

  const onClose = ({ errors }) => {
    const errorKeys = Object.keys(errors ?? {});
    if (errorKeys.length > 0) {
      if (GENERAL_CONFIG_SCHEMA.hasOwnProperty(errorKeys[0])) {
        setCurrentTab(tabList.findIndex(({ label }) => label === 'General'));
      } else {
        setCurrentTab(tabList.findIndex(({ label }) => label === 'Analytics'));
      }
    }
    setIsClosing(false);
    setIsSubmitting(false);
  };

  const onSubmit = async (values) => {
    let currentTabValue;
    try {
      if (dirtyTabList.length > 0) {
        let input = {};
        dirtyTabList.forEach((tabValue) => {
          currentTabValue = tabValue;
          if (tabList[tabValue] && typeof tabList[tabValue].buildUpdateInput === 'function') {
            input = { ...input, ...tabList[tabValue].buildUpdateInput(values) };
          }
        });
        await updateConfig({ variables: { input } });
        setDirtyTabList([]);
      }
    } catch (e) {
      console.error(`Error updating network configuration on ${tabList[currentTabValue]?.label} tab:`, e);
    }
  };

  return (
    <FlamingoPage
      pageName="Network Configuration"
      headerButtons={
        !isComplete && (
          <InfoPanel
            title="Network Configuration is incomplete. Please complete the required fields before publishing."
            type="warning"
            boxShadow={true}
            fullWidth={false}
            sx={{ marginTop: '-20px' }}
          />
        )
      }
    >
      <Box sx={styles.content}>
        <Suspense fallback={<Loading />}>
          {isGlobalTemplate(networkId) ? (
            <>
              <Grid sx={styles.takeoverTabs}>
                <Tabs
                  value={currentTab}
                  onChange={(e, idx) => setCurrentTab(idx)}
                  aria-label="system environment"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="System Environment"
                    {...tabA11yProps('configuration', 0)}
                  />
                </Tabs>
              </Grid>
              <TabPanel
                value={currentTab}
                index={0}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 0 && { height: 'calc(100% - 69px)' },
                }}
              >
                <Box sx={styles.tabBody}>
                  <Environment />
                </Box>
              </TabPanel>
            </>
          ) : (
            <Form
              subscription={{
                submitting: true,
                pristine: true,
                validating: true,
                errors: true,
                values: true,
              }}
              initialValues={{
                ...!featureFlagCheck('configGeneralOff') && {
                  latitude: configData.map?.latitude,
                  longitude: configData.map?.longitude,
                  zoom: configData.map?.zoom ?? 9,
                  timezone: configData.timezone,
                },
                ...!featureFlagCheck('configAnalyticsOff') && {
                  analytics_mobile_project_id:
                    typeof configData.analyticsProjectId === 'string'
                      ? configData.analyticsProjectId
                      : null,
                  analytics_mobile_enabled: configData.mobile?.analyticsEnabled,
                  go_live_date_mobile: convertDateToString(
                    configData.goLiveMobile,
                    STANDARD_DATE_FORMAT,
                  ),

                  analytics_web_project_id:
                    typeof configData.webNav?.analyticsProjectId === 'string'
                      ? configData.webNav?.analyticsProjectId
                      : null,
                  analytics_web_enabled: configData.webNav?.analyticsEnabled,
                  web_go_live_date: convertDateToString(
                      configData.webGoLiveDate,
                      STANDARD_DATE_FORMAT,
                  ),

                  analytics_kiosk_project_id:
                    typeof configData.kiosk?.analyticsProjectId === 'string'
                      ? configData.kiosk.analyticsProjectId
                      : null,
                  analytics_kiosk_enabled: configData.kiosk?.analyticsEnabled,
                  go_live_date_kiosk: convertDateToString(
                    configData.goLiveKiosk,
                    STANDARD_DATE_FORMAT,
                  ),
                },
              }}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({
                  form,
                  handleSubmit,
                  submitting,
                  pristine,
                  dirty,
                  errors,
                  values,
                }) => (
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                  <FormWatcher
                    allowContinueOnError
                    formRenderProps={{ form }}
                    errorFormFieldMap={ERROR_FORM_FIELD_MAP}
                    isClosing={isClosing}
                    isSubmitting={isSubmitting}
                    onClose={() => onClose({ errors })}
                    onSave={() => {
                      setIsClosing(false);
                      setIsSubmitting(false);
                      return true;
                    }}
                    onFormChanged={() => {
                      if (!dirtyTabList.includes(currentTab)) {
                        const tempTabList = [...dirtyTabList, currentTab];
                        tempTabList.sort();
                        setDirtyTabList([...dirtyTabList, currentTab]);
                      }
                    }}
                  />
                  <Grid sx={styles.takeoverTabs}>
                    <Tabs
                      value={currentTab}
                      onChange={(e, idx) => {
                        setCurrentTab(idx);
                      }}
                      aria-label="simple tabs"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {tabList.map(({ label }, index) => (
                        <Tab
                          key={`tab-${index}`}
                          label={label}
                          {...tabA11yProps('configuration', index)}
                        />
                      ))}
                    </Tabs>
                  </Grid>
                  {tabList.map(
                    (
                      { component: TabContentComponent, label, schema },
                      index,
                    ) => (
                      <TabPanel
                        key={`tabPanel-${index}`}
                        value={currentTab}
                        index={index}
                        sx={{
                          ...styles.tabPanel,
                          ...currentTab === index && {
                            height: 'calc(100% - 69px)',
                          },
                        }}
                      >
                        <Box sx={styles.tabBody}>
                          <Suspense fallback={<Loading />}>
                            <TabContentComponent
                              data={label === 'Analytics' ? configData : null}
                              networkId={networkId}
                              disabled={
                                schema
                                  ? isDisabled({
                                    submitting,
                                    pristine,
                                    errors,
                                    schema,
                                  })
                                  : false
                              }
                              onSaved={async () => {
                                setIsSubmitting(true);
                                await form.submit();
                                setIsSubmitting(false);
                              }}
                            />
                          </Suspense>
                        </Box>
                      </TabPanel>
                    ),
                  )}
                </form>
              )}
            </Form>
          )}
        </Suspense>
      </Box>
    </FlamingoPage>
  );
};

const ConfigurationPage = () => (
  <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
    <ConfigurationContent />
  </LoggedinLayout>
);

export default ConfigurationPage;
