import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DEFAULT_BACKGROUND_COLOR,
  dndHover,
  getLanguageLabel,
  isGroupIncompleteForPackaging,
} from 'components/dashboard/cardLayouts/cardhelper';
import { buildUseDragParams, ItemTypes } from 'helpers/dnd-util';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PanelPreviewLink from '../../../pages/mobileSettings/explorePanel/containers/PanelPreviewLink';
import Card from '../cardComponent/card';

const buildStyles = ({ theme }) => ({
  cardGroup: {
    marginBottom: theme.spacing(0.5),
    transform: 'translate3d(0, 0, 0)',
  },
  card: {
    marginBottom: theme.spacing(0.5),
  },
  preview: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dragging: {
    paddingBottom: '0 !important',
  },
});

const NavlinksLayout = ({
                          id,
                          index,
                          groupTitle,
                          showGroupTitle,
                          groupTitleTextColor,
                          navlinkDisplay,
                          navlinkActiveCards,
                          textColor,
                          backgroundColor,
                          visibilityRule,
                          validation,
                          cards,
                          preview,
                          disabled,
                          selected,
                          hovered,
                          moveCard,
                          onCardGroupClick,
                          onDrag,
                          onHover,
                          canDragAndDrop,
                        }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_CARD,
    canDrop: () => canDragAndDrop,
    hover(item, monitor) {
      if (onHover) {
        onHover(item, index);
      }
      return dndHover(item, monitor, ref, index, moveCard);
    },
  });

  const [{ isDragging }, drag] = useDrag(
    buildUseDragParams({
      id,
      canDragAndDrop,
      cardProps: {
        groupTitle,
        showGroupTitle,
        groupTitleTextColor,
        navlinkDisplay,
        navlinkActiveCards,
        textColor,
        backgroundColor,
        cards,
      },
      cardType: NavlinksLayout,
      index,
      onDrag,
      preview,
    }),
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Tooltip title={preview && !hovered ? 'NavLinks Card' : ''}>
      <Grid
        container
        direction="column"
        spacing={0}
        ref={ref}
        sx={{
          ...styles.cardGroup,
          ...preview && styles.preview,
          ...isDragging && styles.dragging,
          opacity,
        }}
      >
        <Grid item sx={styles.card}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}>
              <Card
                size={'small'}
                backgroundColor={'00000000'}
                clickHandler={() => onCardGroupClick({ cardGroupId: id })}
                incompleteForPackaging={!!preview && isGroupIncompleteForPackaging({
                  validation,
                  cards,
                })}
                iconUrl={'nothing here'}
                disabled={disabled}
                selected={selected}
                standalone={true}
                textColor={'00000000'}
                title={''}
              />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 0, padding: '8px', width: '100%', height: '100%' }}>
              {showGroupTitle && <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    color: groupTitleTextColor,
                  }}><span>{getLanguageLabel(groupTitle)}</span></Typography>
              </Grid>
              }
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-evenly"
                alignItems="top"
                marginTop={showGroupTitle ? '-4px' : '18px'}
                marginBottom={showGroupTitle ? '-2px' : '18px'}
                sx={styles.card}
              >
                {cards
                  .slice(0, navlinkActiveCards)
                  .map((card, index) => (
                    <Grid key={`link_${index}_${index}`} item xs={3} height={'100%'}>
                      <PanelPreviewLink
                        backgroundColor={backgroundColor}
                        name={card.title}
                        wrapLines={false}
                        icon={navlinkDisplay === 'icon' ? card.icon : { url: card.iconUrl }}
                        textColor={textColor}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

NavlinksLayout.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  groupTitle: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      lang: PropTypes.string,
    }),
  ),
  showGroupTitle: PropTypes.bool,
  groupTitleTextColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  navlinkDisplay: PropTypes.string,
  navlinkActiveCards: PropTypes.number,
  cards: PropTypes.array,
  visibilityRule: PropTypes.any,
  validation: PropTypes.object,
  hovered: PropTypes.bool,
  moveCard: PropTypes.func,
  onCardGroupClick: PropTypes.func,
  onDrag: PropTypes.func,
  onHover: PropTypes.func,
  canDragAndDrop: PropTypes.bool,
};

NavlinksLayout.defaultProps = {
  groupTitle: [
    {
      lang: 'en',
      label: 'Navlink Group Title',
    },
  ],
  showGroupTitle: true,
  groupTitleTextColor: DEFAULT_BACKGROUND_COLOR,
  textColor: DEFAULT_BACKGROUND_COLOR,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  navlinkActiveCards: 3,
  cards: [],
  visibilityRule: null,
  validation: null,
  preview: false,
  disabled: false,
  selected: false,
  hovered: false,
  moveCard: () => {
  },
  onDrag: () => {
  },
  onCardGroupClick: () => {
  },
  canDragAndDrop: true,
};

export default NavlinksLayout;
