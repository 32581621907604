import GenericFormModal from 'components/genericModal/genericFormModal';
import { useCreateNetwork } from 'hooks/dataHooks/useNetworkConfiguration';
import useToast from 'hooks/useToast';
import { makeValidate } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import NetworkModalContent from './detail/networkModalContent';

const buildStyles = () => ({
  modal: {
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '20px',
    },
    '& .MuiPaper-root': {
      minWidth: '584px',
    },
  },
});

const AddNetworkModal = ({ onSave, onClose }) => {
  const styles = buildStyles();
  const { toastNotificationErrorHook } = useToast();
  const [createNetwork] = useCreateNetwork();
  const validate = useMemo(
    () => makeValidate(
        Yup.object().shape({
          name: Yup.string()
            .matches(/^[A-Z a-z]+$/, {
              message: 'Use uppercase and lowercase letters and spaces only.',
            })
            .required('Network Name is required'),
          textIdentifier: Yup.string()
            .matches(/^[A-Za-z]+$/, {
              message: 'Use uppercase and lowercase letters only.',
            })
            .required('Text Identifier is required'),
          sdkEnabled: Yup.boolean().when(['mobileEnabled', 'kioskEnabled'], {
            is: false,
            then: (schema) => schema.oneOf([true]),
          }),
          mobileEnabled: Yup.boolean().required(),
          kioskEnabled: Yup.boolean().required(),
          webEnabled: Yup.boolean().required(),
        }),
      ),
    [],
  );

  return (
    <GenericFormModal
      open
      title="Add New Network"
      sx={styles.modal}
      formParams={{
        onSubmit: async ({
                           kioskEnabled,
                           sdkEnabled,
                           mobileEnabled,
                           webEnabled,
                           name,
                           textIdentifier,
                         }) => {
          const networkDetails = {
            kioskEnabled,
            sdkEnabled,
            mobileEnabled,
            webEnabled,
            name: textIdentifier,
            humanName: name,
            goLiveKiosk: null,
            goLiveMobile: null,
          };
          try {
            const response = await createNetwork({
              variables: {
                input: networkDetails,
              },
            });
            if (!response.errors) {
              onSave({ ...networkDetails, id: response.data.createNetwork.id });
            } else {
              const { errors } = response;
              const errorText = errors
                .map((e) => e.extensions?.debug?.message)
                .join('; ');
              toastNotificationErrorHook(
                `Error creating new network: ${errorText}`,
              );
            }
          } catch (e) {
            toastNotificationErrorHook(`Error creating new network: ${e}`);
          }
        },
        validate,
        initialValues: {
          networkName: '',
          textIdentifier: '',
          sdkEnabled: false,
          mobileEnabled: false,
          kioskEnabled: false,
          webEnabled: false,
        },
      }}
      body={<NetworkModalContent />}
      confirmText="Add Network"
      handleClose={onClose}
    />
  );
};

AddNetworkModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default React.memo(AddNetworkModal);
