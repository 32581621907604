import { AddCircle, ArrowRightAlt, Cancel } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Autocomplete from 'components/autocomplete/autoComplete';
import EmptySection from 'components/emptySection/emptySection';
import Condition from 'components/forms/condition';
import GenericFormModal from 'components/genericModal/genericFormModal';
import Loading from 'components/loading/loading';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { colorWithAlpha } from 'helpers/color-util';
import { isPastDate, normalizeDate, STANDARD_DATE_FORMAT, STANDARD_DATE_REGEX } from 'helpers/date-util';
import { getFormLabel } from 'helpers/form-util';
import { buildLabelByLang, buildLanguageSchema, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useWindowSize from 'hooks/useWindowSize';
import { makeValidate, TextField } from 'mui-rff';
import {
  DEFAULT_CUSTOM_HOURS,
  DEFAULT_START_AND_END_TIMES,
  EXCEPTION_HOURS_OF_OPERATIONS,
  HOUR_STATUSES,
} from 'pages/locations/containers/locationsFormHelper';
import CustomHours from 'pages/locations/containers/sections/customHours';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);

const HOUR_STRING_REGEX = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

const SINGLE_DAY_COLUMNS = [
  {
    Header: <span>Hours&nbsp;of&nbsp;Operation</span>,
    accessor: 'hoursOfOperation',
    cellStyle: {
      alignItems: 'start',
    },
    disableSortBy: true,
    minWidth: 648,
    width: 648,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    cellStyle: {
      alignItems: 'start',
    },
    minWidth: 120,
    width: 120,
    disableSortBy: true,
    justifyRight: true,
  },
];

const buildStyles = ({ theme }) => ({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '1024px',
    },
  },
  content: {
    alignContent: 'start',
  },
  heading: {
    fontSize: '28px',
    lineHeight: '40px',
    textAlign: 'center',
  },
  sectionHeading: {
    marginBottom: '26px',

    '&:not(:first-of-type)': {
      marginTop: '40px',
    },
  },
  dateRangeContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  dateFieldContainer: {
    width: '192px',
    height: '77px',
    marginLeft: '12px',

    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  dateRangeTextContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '-20px',

    '& svg': {
      fill: theme.palette.blue[400],
      fontSize: '24px',
      marginLeft: '20px',
    },
  },
  dateRangeBtn: {
    backgroundColor: 'transparent !important',
    fontFamily: ['Roboto', 'Helvetica', 'sans-serif'],
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: 0,
    textTransform: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  rightArrowIcon: {
    marginLeft: '12px',
    marginTop: '-20px',
    width: '24px',
    height: '24px',

    '& svg': {
      fill: colorWithAlpha(theme.palette.black, 0.54),
    },
  },
  deleteIconContainer: {
    paddingLeft: '12px',
  },
  deleteIcon: {
    marginLeft: '8px',
    marginTop: '-20px',
    width: '24px',
    height: '24px',

    '& .MuiButton-root': {
      minWidth: '24px',
      width: '24px',
      height: '24px',
    },

    '& svg': {
      fill: theme.palette.blue[400],
    },
  },
  emptySection: {
    height: '114px',
  },
});

Yup.addMethod(
  Yup.string,
  'validDateFormat',
  function(errorMessage, list, index) {
    return this.test('valid-date-format', errorMessage, function(str) {
      const { path, createError } = this;

      const date = dayjs(str, STANDARD_DATE_FORMAT, true);
      if (!(date.isValid() && str.match(STANDARD_DATE_REGEX))) {
        if (isPastDate(date.toDate())) {
          return createError({
            path,
            message: 'Date is in the past',
          });
        }

        return createError({
          path,
          message: date ? errorMessage : 'Date is required',
        });
      }

      if (isPastDate(date.toDate())) {
        return createError({
          path,
          message: 'Date is in the past',
        });
      }

      for (let i = 0; i < list.length; i++) {
        if (index === i) {
          continue;
        }
        const startDate = dayjs(list[i].startDate);
        const endDate = list[i].endDate ? dayjs(list[i].endDate) : null;
        if (startDate && endDate) {
          if (date.isBetween(startDate, endDate, 'days', '[]')) {
            return createError({ path, message: 'The date is already in use' });
          }
        } else if (date.isSame(startDate)) {
          return createError({ path, message: 'The date is already in use' });
        }
      }

      return true;
    });
  },
);

const stringToDate = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const date = dayjs(dateStr, STANDARD_DATE_FORMAT, true);
  if (date.isValid()) {
    return date.toDate();
  }
  return '';
};

const dateToString = (date) => {
  if (!date) {
    return '';
  }

  return dayjs(date).format(STANDARD_DATE_FORMAT);
};

const AddOrEditHoursExceptionModal = ({
                                        existingExceptions,
                                        handleClose,
                                        handleExceptionSaved,
                                        index,
                                      }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const { height } = useWindowSize();

  const exception = index === null ? {} : existingExceptions[index];

  let formStartDate
    = index === null ? '' : dateToString(existingExceptions[index].startDate);
  let formEndDate
    = index === null ? '' : dateToString(existingExceptions[index].endDate);

  const languages = useActiveNetworkLanguages();

  // noinspection JSUnresolvedFunction
  const exceptionSchema = useMemo(
    () => languages
        ? Yup.object().shape(
          buildLanguageSchema(
            {
              langES: Yup.boolean(),
              isSingleDay: Yup.boolean(),
              name: Yup.string().required('Name is required'),
              nameES: Yup.string(),
              type: Yup.string().oneOf(
                EXCEPTION_HOURS_OF_OPERATIONS.map(({ id }) => id),
              ),
              startDate: Yup.string().validDateFormat(
                'Format: MM/DD/YYYY',
                existingExceptions,
                index,
              ),
              endDate: Yup.string().when('isSingleDay', {
                is: (isSingleDay) => !isSingleDay,
                then: (schema) => schema.validDateFormat(
                    'Format: MM/DD/YYYY',
                    existingExceptions,
                    index,
                  ),
              }),
              exceptionHours: Yup.array().when('type', {
                is: (hoursType) => hoursType === 'custom',
                then: (schema) => schema
                    .of(
                      Yup.object()
                        .shape({
                          day: Yup.string()
                            .oneOf([
                              'monday',
                              'tuesday',
                              'wednesday',
                              'thursday',
                              'friday',
                              'saturday',
                              'sunday',
                            ])
                            .required('Day is required'),
                          status: Yup.string()
                            .oneOf([HOUR_STATUSES.OPEN, HOUR_STATUSES.CLOSED])
                            .required('Status is required'),
                          hours: Yup.array()
                            .of(
                              Yup.object().shape({
                                startTime: Yup.string()
                                  .matches(
                                    HOUR_STRING_REGEX,
                                  )
                                  .required('Start Time is required'),
                                endTime: Yup.string()
                                  .matches(
                                    HOUR_STRING_REGEX,
                                  )
                                  .required('End Time is required'),
                              }),
                            )
                            .required('Hours is required.'),
                        })
                        .required('Custom hours is required.'),
                    )
                    .required('Custom hours is required.'),
              }),
            },
            languages,
            {
              name: LANGUAGE_CODES.ENGLISH,
              nameES: LANGUAGE_CODES.SPANISH,
            },
          ),
        )
        : null,
    [existingExceptions, index, languages],
  );

  // noinspection JSCheckFunctionSignatures
  const validate = languages ? makeValidate(exceptionSchema) : null;

  const handleSubmit = async (values) => {
    handleExceptionSaved(
      {
        name: buildLabelByLang(values, 'name', 'nameES'),
        type: values.type,
        custom: values.exceptionHours.map(({ __typename, hours, ...rest }) => ({
          hours: hours.map(({ __typname, ...hoursRest }) => hoursRest),
          ...rest,
        })),
        startDate: stringToDate(values.startDate),
        endDate: stringToDate(values.endDate),
      },
      index,
    );
  };

  const validateDates = (
    form,
    checkSameOrAfterOnly = false,
    validatePastDate = true,
  ) => {
    const { isSingleDay, startDate, endDate } = form.getState().values;

    if (startDate && !startDate.match(STANDARD_DATE_REGEX)) {
      return false;
    }
    const start = dayjs(startDate, STANDARD_DATE_FORMAT, true);
    if (!start.isValid() || isPastDate(start.toDate())) {
      return false;
    }

    if (isPastDate(start.toDate())) {
      return !validatePastDate;
    }

    if (isSingleDay) {
      return true;
    }

    if (endDate && !endDate.match(STANDARD_DATE_REGEX)) {
      return false;
    }

    const end = dayjs(endDate, STANDARD_DATE_FORMAT, true);
    if (!end.isValid()) {
      return false;
    }

    if (isPastDate(end.toDate())) {
      return !validatePastDate;
    }

    if (checkSameOrAfterOnly) {
      if (isPastDate(end.toDate())) {
        return true;
      }
    }

    if (end.isSameOrAfter(start)) {
      return true;
    }

    return checkSameOrAfterOnly;
  };

  const handleCustomHoursChanges = (form, values) => {
    const { isSingleDay, startDate, endDate, exceptionHours } = values;

    if (!validateDates(form, true)) {
      return;
    }

    if (
      startDate === formStartDate
      && endDate === formEndDate
      && exceptionHours?.length > 0
    ) {
      return false;
    }

    if (startDate && !startDate.match(STANDARD_DATE_REGEX)) {
      return false;
    }

    const start = dayjs(startDate, STANDARD_DATE_FORMAT, true);
    if (!start.isValid()) {
      return false;
    }

    if (isSingleDay) {
      const startDay = start.format('dddd').toLowerCase();
      const found = exceptionHours.find(
        (exception) => exception.day === startDay,
      );

      form.change(
        'exceptionHours',
        found
          ? [found]
          : [
            {
              day: startDay,
              status: HOUR_STATUSES.OPEN,
              hours: [DEFAULT_START_AND_END_TIMES],
            },
          ],
      );

      return true;
    }

    if (endDate && !endDate.match(STANDARD_DATE_REGEX)) {
      return false;
    }

    const end = dayjs(endDate, STANDARD_DATE_FORMAT, true);
    if (end.isValid()) {
      const startDay = start.format('dddd').toLowerCase();
      const numOfDays = Math.min(end.diff(start, 'days') + 1, 7);
      let customHours = [];
      const disabledDaysMap = {};
      DEFAULT_CUSTOM_HOURS.forEach(({ day }) => {
        disabledDaysMap[day] = true;
      });
      if (numOfDays > 0) {
        const map = {};
        exceptionHours?.forEach((exception) => {
          map[exception.day] = exception;
        });
        const indices = [];
        const startIndex = DEFAULT_CUSTOM_HOURS.findIndex(
          (item) => item.day === startDay,
        );
        for (
          let i = startIndex, max = Math.min(startIndex + numOfDays, 7);
          i < max;
          i++
        ) {
          disabledDaysMap[DEFAULT_CUSTOM_HOURS[i].day] = false;
          indices.push(i);
        }
        if (indices.length < numOfDays) {
          for (let i = 0, max = numOfDays - indices.length; i < max; i++) {
            disabledDaysMap[DEFAULT_CUSTOM_HOURS[i].day] = false;
            indices.splice(i, 0, i);
          }
        }
        customHours = DEFAULT_CUSTOM_HOURS.map((item, index) => indices.includes(index) ? map[item.day] ?? item : null,
        ).filter((i) => i);
      }
      form.change('exceptionHours', customHours);
      form.change('showRecurringInfo', numOfDays >= 7);
      return true;
    }

    return false;
  };

  const hasSpanishLang = hasSpanish(languages);

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <GenericFormModal
      title={index === null ? 'Add Exception' : 'Edit Exception'}
      open={true}
      sx={styles.root}
      contentSx={styles.content}
      disableConfirmCheck={(formVals) => {
        const { invalid, submitting } = formVals;
        return invalid || submitting;
      }}
      scrollable={true}
      formParams={{
        onSubmit: handleSubmit,
        validate,
        initialValues: {
          langES: hasSpanishLang,
          isSingleDay: !exception?.endDate,
          name: getLabelByLang(exception?.name),
          ...hasSpanishLang && {
            nameES: getLabelByLang(exception?.name, LANGUAGE_CODES.SPANISH),
          },
          type: exception?.type ?? 'none',
          startDate: dateToString(exception?.startDate),
          endDate: dateToString(exception?.endDate),
          exceptionHours: exception?.custom ?? [],
          showRecurringInfo: false,
        },
      }}
      body={({ form }) => (
        <Grid
          container
          sx={{ maxHeight: `calc(${height}px - 284px)`, overflowY: 'auto' }}
        >
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              if (handleCustomHoursChanges(form, values)) {
                formStartDate = values.startDate;
                formEndDate = values.endDate;
              }
            }}
          />
          <Grid item xs={12} sx={styles.sectionHeading}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingRight: '12px' }}>
            <TextField
              label={getFormLabel('Name', true)}
              name="name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Condition when="langES" is={true}>
            <Grid item xs={6} sx={{ paddingLeft: '12px' }}>
              <TextField
                label={getFormLabel('Name (Spanish)', false)}
                name="nameES"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Condition>
          <Grid item xs={12} sx={styles.sectionHeading}>
            <Typography variant="subtitle1">Operating Schedule</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingRight: '12px' }}>
            <Field
              component={Autocomplete}
              name="type"
              label="Hours of Operation"
              required={true}
              disableClearable={true}
              sorted={false}
              options={EXCEPTION_HOURS_OF_OPERATIONS}
            />
          </Grid>
          <Grid item xs={6} sx={styles.dateRangeContainer}>
            <Box sx={styles.dateFieldContainer}>
              <Field
                name="startDate"
                parse={normalizeDate}
                format={normalizeDate}
              >
                {({ input: { value, ...rest } }) => (
                  <TextField
                    label={getFormLabel(
                      form.getState().values.isSingleDay
                        ? 'Select a Day'
                        : 'Start Day',
                      true,
                    )}
                    variant="outlined"
                    fullWidth
                    placeholder="MM/DD/YYYY"
                    {...rest}
                    value={value || ''}
                  />
                )}
              </Field>
            </Box>
            <Condition when="isSingleDay" is={true}>
              <Box sx={styles.dateRangeTextContainer}>
                <AddCircle sx={{ marginRight: '8px' }} />
                <Button
                  sx={styles.dateRangeBtn}
                  color="primary"
                  onClick={() => form.change('isSingleDay', false)}
                >
                  Make This a Date Range
                </Button>
              </Box>
            </Condition>
            <Condition when="isSingleDay" is={false}>
              <Box sx={styles.rightArrowIcon}>
                <ArrowRightAlt />
              </Box>
              <Box sx={styles.dateFieldContainer}>
                <Field
                  name="endDate"
                  parse={normalizeDate}
                  format={normalizeDate}
                >
                  {({ input: { value, ...rest } }) => (
                    <TextField
                      label={getFormLabel('End Day', true)}
                      variant="outlined"
                      fullWidth
                      placeholder="MM/DD/YYYY"
                      {...rest}
                      value={value || ''}
                    />
                  )}
                </Field>
                {!validateDates(form, true, false)
                  && form.getState().values.startDate
                  && form.getState().values.endDate && (
                    <p
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
                      Invalid date range
                    </p>
                  )}
              </Box>
              <Box sx={styles.deleteIcon}>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    form.change('isSingleDay', true);
                    form.change('showRecurringInfo', false);
                    form.change('endDate', '');
                  }}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  <Cancel />
                </Button>
              </Box>
            </Condition>
          </Grid>
          <Condition when="type" is="custom">
            {validateDates(form, false) && (
              <Grid item xs={12}>
                <CustomHours
                  columns={
                    form.getState().values.isSingleDay
                      ? SINGLE_DAY_COLUMNS
                      : undefined
                  }
                  data={form.getState().values.exceptionHours}
                  description={
                    form.getState().values.showRecurringInfo
                      ? 'The hours selected below will reoccur throughout the date range selected.'
                      : undefined
                  }
                  form={form}
                  onChange={(updatedData) => {
                    // The updatedData parameter will have time strings in
                    // hh:mm:ss format, and so we need to truncate them here
                    const truncatedHourData = updatedData.map((d) => ({
                      ...d,
                      hours: d.hours.map((h) => ({
                        ...h,
                        startTime: h.startTime.substring(0, 5),
                        endTime: h.endTime.substring(0, 5),
                      })),
                    }));
                    form.change('exceptionHours', truncatedHourData);
                  }}
                  canCopyHours={!form.getState().values.isSingleDay}
                  showOpenSwitch={!form.getState().values.isSingleDay}
                  title="Custom Exception Hours"
                />
              </Grid>
            )}
            {!validateDates(form, false) && (
              <>
                <Grid item xs={12} sx={styles.sectionHeading}>
                  <Typography variant="subtitle1">
                    Custom Exception Hours
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <EmptySection
                    title="Select a Date Above"
                    sx={styles.emptySection}
                  />
                </Grid>
              </>
            )}
          </Condition>
        </Grid>
      )}
      confirmText={index === null ? 'SAVE & ADD' : 'SAVE'}
      handleClose={handleClose}
    />
  );
};

AddOrEditHoursExceptionModal.propTypes = {
  existingExceptions: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  handleExceptionSaved: PropTypes.func.isRequired,
  index: PropTypes.number,
};

AddOrEditHoursExceptionModal.defaultProps = {
  existingExceptions: [],
  index: null,
};

export default AddOrEditHoursExceptionModal;
